import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAccount, useNetwork } from "wagmi";
import p2pContract from "./contracts.json";
import { showToast } from "../Main/common";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import p2pContract2 from "./contract2.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEdit,
  faLink,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { API_URL } from "../../Config/Auth";
import { Card } from "@mui/material";
import { Alert, Spin, Switch, Table } from "antd";
import { EditOutlined } from "@mui/icons-material";
import axiosClient from "../../service/axios";

const CustomToastWithLink = ({ explorer, transactionHash, element }) => {
  // <div className="flex justify-content-center text-center">
  //   {/* <div className="text-center px-2 fw-bold">View on {explorer}</div> */}
  //   <div className="items-center">
  return (
    <a
      href={`https://${explorer}.com/tx/${transactionHash}`}
      target="_blank"
      rel="noopener noreferrer"
      // className="text-decoration-none"
    >
      {/* {element} */}
      View on {explorer}
    </a>
  );
  //   </div>
  // </div>
};

function UpCommingIcoList(props) {
  const [ads, setAds] = useState([]);
  const [totalAds, setTotalAds] = useState(0);
  let { address, isConnected } = useAccount();
  const [contractAddr, setContractAddr] = useState("");
  const [contractAddr2, setContractAddr2] = useState("");

  const network = useNetwork();
  let navigation = useNavigate();
  const [explorer, setExplorer] = useState(null);
  const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
  const successMessage = (explorer, transactionHash) => {
    const element = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />;
    const explorerLink = `https://${explorer}.com/tx/${transactionHash}`;
    console.log(explorerLink);
    toast.success(
      <div>
        Transaction success!
        <br />
        <a href={explorerLink} target="_blank" rel="noopener noreferrer">
          View on {explorer} {element}
        </a>
      </div>,
      {
        duration: 10000,
        position: "top-right",
        reverseOrder: false,
      }
    );
  };

  useEffect(() => {
    const chain = network?.chain?.id.toString();
    if (chain === "1") {
      setExplorer("etherscan");
    } else if (chain === "56") {
      setExplorer("bscscan");
    } else if (chain === "137") {
      setExplorer("polygonscan");
    }
    if (chain === "1") {
      setContractAddr(p2pContract.EthereumMainnetAddress);
      setContractAddr2(p2pContract2.EthereumMainnetAddress);
    } else if (chain === "56") {
      setContractAddr(p2pContract.BSCMainnetAddress);
      setContractAddr2(p2pContract2.BSCMainnetAddress);
    } else if (chain === "137") {
      setContractAddr(p2pContract.PolygonMainnetAddress);
      setContractAddr2(p2pContract2.PolygonMainnetAddress);
    }
    console.log("Contrac-1:", contractAddr, "contract-2:", contractAddr2);
  }, [network]);
  const fetchAds = async () => {
    if (!isConnected) {
      return;
    }
    const res = await axiosClient.get(`${API_URL}/ads/${address}`);
    if ((res.data.success = true)) {
      setAds(res.data.data);
      setTotalAds(res.data.totalAds);
    }
  };

  useEffect(() => {
    if (address) {
      fetchAds();
    }
  }, [address]);

  const cancelSellAd = async (id, adId, row) => {
    console.log(row);
    try {
      const Web3 = require("web3");
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      }
      const web3 = new Web3(window.ethereum);

      const chain = network?.chain?.id.toString();
      let explorer;
      if (chain === "1") {
        explorer = "etherscan";
      } else if (chain === "56") {
        explorer = "bscscan";
      } else if (chain === "137") {
        explorer = "polygonscan";
      }

      const contractWrite = new web3.eth.Contract(
        p2pContract.ABI,
        contractAddr
      );

      console.log(await contractWrite.methods.ads(adId).call());
      const adInfo = await contractWrite.methods.ads(adId).call();

      const block = await web3.eth.getBlock("latest");
      const gasPrice = (await web3.eth.getGasPrice()) * 2;
      const gasLimit = await contractWrite.methods
        .cancelAd(adId)
        .estimateGas({ from: address });
      let size = 0;
      size =
        block.size < 400000
          ? block.size
          : Math.ceil(block.size / parseInt(block.difficulty));
      const gasLimitAdjusted = Math.max(size, gasLimit);

      if (adInfo?.seller === address) {
        const tx = await contractWrite.methods.cancelAd(adId).send({
          from: address,
          gasPrice,
          gas: gasLimitAdjusted,
        });
        // showToast("Transaction successful", "success");
        // setTimeout(() => {
        //   showToast(
        //     <CustomToastWithLink
        //       explorer={explorer}
        //       transactionHash={tx.transactionHash}
        //       element={element}
        //     />,
        //     "success"
        //   );
        // }, 3000); // 3000 milliseconds = 5 seconds
        successMessage(explorer, tx.transactionHash);
        console.log(tx, "Transaction successful");
        deleteAds(id);
      } else {
        showToast("You are not the owner of this AD!", "error");
      }
    } catch (error) {
      console.error("Transaction failed:", error);
      showToast("Transaction failed!", "error");
    }
  };

  const cancelBuyAd = async (id, adId, row) => {
    console.log("cancelBuyAd", row);
    try {
      const Web3 = require("web3");
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      }
      const web3 = new Web3(window.ethereum);

      const contractWrite = new web3.eth.Contract(
        p2pContract2.ABI,
        contractAddr2
      );
      const adInfo = await contractWrite.methods.ads(adId).call();
      console.log(adInfo);

      const chain = network?.chain?.id.toString();
      let explorer;
      if (chain === "1") {
        explorer = "etherscan";
      } else if (chain === "56") {
        explorer = "bscscan";
      } else if (chain === "137") {
        explorer = "polygonscan";
      }

      if (adInfo?.isProcessing) {
        showToast(
          "Ad can't be deleted because one of your orders is in progress!",
          "error"
        );
        return;
      }

      if (adInfo?.buyer === address) {
        const block = await web3.eth.getBlock("latest");
        const gasLimitMultiplier = 2; // Adjust the multiplier as needed
        const gasPrice = (await web3.eth.getGasPrice()) * gasLimitMultiplier;
        const gasLimit = await contractWrite.methods
          .cancelAd(adId)
          .estimateGas({ from: address, gasPrice: gasPrice });
        let size = 0;
        size =
          block.size < 400000
            ? block.size
            : Math.ceil(block.size / parseInt(block.difficulty));
        const gasLimitAdjusted = Math.max(size, gasLimit);
        const tx = await contractWrite.methods.cancelAd(adId).send({
          from: address,
          gasPrice,
          gas: gasLimitAdjusted, // Rounded up gas limit
        });

        // showToast("Transaction successful", "success");

        // setTimeout(() => {
        //   showToast(
        //     <CustomToastWithLink
        //       explorer={explorer}
        //       transactionHash={tx.transactionHash}
        //       element={element}
        //     />,
        //     "success"
        //   );
        // }, 3000); // 3000 milliseconds = 5 seconds
        successMessage(explorer, tx.transactionHash);

        console.log("Transaction successful");
        deleteAds(id);
      } else {
        showToast("You are not the owner of this AD!", "error");
      }
    } catch (error) {
      console.error("Transaction failed:", error);
      showToast(error.message, "error");
    }
  };

  const deleteAds = async (id) => {
    if (!isConnected) {
      return;
    }
    const res = await axiosClient.delete(`${API_URL}/ads/${id}`);
    if ((res.data.success = true)) {
      fetchAds();
    }
  };

  const handleSwitchChange = async (value, id) => {
    const res = await axiosClient.put(
      `${API_URL}/ads/${id}`,{status: `${value === true ? "active" : "inactive"}`});
    if ((res.data.success = true)) {
      fetchAds();
    }
  };
  // const updateAds = async (e, id) => {
  //   if (!isConnected) {
  //     return;
  //   }
  // };

  const columns = [
    {
      title: "Currency",
      align: "center",
      render: (row) => (
        <div className="flex justify-center gap-1">
          <img
            src={
              row.currency && row.currency.length > 0 && row.currency[0].iconUrl
            }
            alt={
              row.currency && row.currency.length > 0 && row.currency[0].iconUrl
            }
            height={25}
            width={25}
          />
          {row.currency && row.currency.length > 0 && row.currency[0].symbol}
          {/* <span className="">{row.coinortoken}</span> */}
        </div>
      ),
    },
    {
      title: "Ad Type",
      align: "center",
      // //selector: (row) => row.adtype,
      render: (row) => (
        <span
          className={`${
            row.adtype === "sell" ? " bg-green-500" : " bg-red-500"
          } px-2 py-1 rounded-md text-xs font-bold text-white`}
        >
          {row.adtype.toUpperCase()}
        </span>
      ),
    },
    {
      title: "Amount",
      align: "center",
      //selector: (row) => row.amount,
      render: (row) => row.amount,
    },
    {
      title: "Price",
      align: "center",
      render: (row) => row.price,
    },
    {
      title: "Minimum Limit",
      align: "center",
      render: (row) => row.minimum,
    },
    {
      title: "Maximun Limit",
      align: "center",
      render: (row) => row.maximun,
    },
    {
      title: "Transactions",
      align: "center",
      render: (row) => (
        <div className="flex flex-col justify-center">
          <p className="text-green-500 p-0 m-0">Success</p>
          <p className="">
            <a target="_blank" href={`https://${explorer}.com/tx/${row.url}`}>
              
              {explorer} <FontAwesomeIcon icon={faLink} />
            </a>
          </p>
        </div>
      ),
    },
    {
      title: "Status",
      align: "center",
      //selector: (row) => {},

      render: (row) => (
        <div>
          <div>
            <Switch
              checked={row.status === "active" ? true : false}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              className={`${
                row.status === "active" ? " bg-green-500" : " bg-red-500"
              }`}
              onChange={(e) => handleSwitchChange(e, row._id)}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      //selector: (row) => {},

      render: (row) => (
        <div className="flex gap-1">
          <Link to={`/updateAds/${row._id}`}>
            <button
              type="button"
              className=" bg-green-500 text-white font-bold p-1 rounded-md"
              // onClick={() => {
              //   navigation(`/myads/${row._id}/edit`);
              // }}
            >
              <EditOutlined />
            </button>
          </Link>

          <button
            type="button"
            className=" bg-red-500 p-1 text-white font-bold rounded-md"
            onClick={(e) => {
              row.adtype === "Sell" || row.adtype === "sell"
                ? cancelSellAd(row._id, row.adId, row)
                : cancelBuyAd(row._id, row.adId, row);
            }}
          >
            <DeleteRoundedIcon />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-2">
      <Card className="p-2">
        <div className="card-header py-3 flex justify-between bg-transparent border-bottom-0 items-center">
          <p className="text-lg font-bold">My Ads</p>
        </div>

        <div className="flex justify-end mx-2">
          <button
            type="submit"
            className=" rounded-lg p-2 text-green-600  bg-green-100"
            onClick={(e) => navigation("/myadsCreate")}
          >
            Create New Ad
          </button>
        </div>
      </Card>
      <div className=" max-sm:hidden mt-3 max-md:hidden">
        <Table
          columns={columns}
          dataSource={ads}
          defaultSortField="title"
          selectableRows={false}
          highlightOnHover={true}
          pagination={false}
        />
      </div>
      <div className="flex lg:hidden flex-col space-y-3 mt-3 w-full">
        {ads.map((row, index) => (
          <>
            <Card className="p-2 w-full" key={index}>
              <div className="flex flex-row justify-between ">
                <div className="flex justify-between">
                  <span className="v2-name-circular bg-primary text-white">
                    <img
                      src={
                        row.currency &&
                        row.currency.length > 0 &&
                        row.currency[0].iconUrl
                      }
                      alt=""
                      height={25}
                      width={25}
                    />
                  </span>
                  <div className="v2-name flex flex-col">
                    <div className="flex items-center">
                      <span className="">
                        
                        {row.currency &&
                          row.currency.length > 0 &&
                          row.currency[0].name}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="">
                        
                        <div>
                          <div
                            className="v2-price"
                            style={{
                              fontSize: "1.2rem",
                            }}
                          >
                            <span>&#8377;</span> {row.price}
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col space-y-1">
                  <div className="flex justify-end">
                    <Switch
                      checked={row.status === "active" ? true : false}
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      className={`${
                        row.status === "active"
                          ? " bg-green-500"
                          : " bg-red-500"
                      }`}
                      onChange={(e) => handleSwitchChange(e, row._id)}
                    />
                  </div>
                  <p className="flex justify-end text-black">
                    {row.network.toUpperCase()}
                  </p>
                </div>
              </div>
              <div className="flex mt-2 justify-between">
                <div className="flex flex-col">
                  <div className="flex ">
                    <span
                      className=""
                      style={{
                        fontSize: "1.2rem",
                      }}
                    >
                      Available
                    </span>
                    <span
                      className="flex text-black ms-2"
                      style={{
                        fontSize: "1.2rem",
                      }}
                    >
                      
                      {row.amount}
                    </span>
                  </div>
                  <div className="font-bold items-center justify-center mb-3 mt-1">
                    <div>
                      <a
                        target="_blank"
                        href={`https://${explorer}.com/tx/${row.url}`}
                      >
                        
                        Check on {explorer} <FontAwesomeIcon icon={faLink} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex ms-2">
                  <div
                    className="flex "
                    style={{
                      fontSize: "1.2rem",
                    }}
                  >
                    <span
                      className=""
                      style={{
                        fontSize: "1.2rem",
                      }}
                    >
                      Limit:
                    </span>
                    <div className="flex ms-2">
                      <span className="text-black">
                        {row.minimum} - {row.maximun}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex mt-2 px-2 gap-2">
                  <div className="w-1/2">
                  <Link to={`/updateAds/${row._id}`}>
                  <button
                    type="button"
                    className="w-full text-white   font-bold bg-green-500 p-1 rounded-md"
                    // onClick={() => {
                    //   navigation(`/myads/${row._id}/edit`);
                    // }}
                  >
                    <EditOutlined /> Edit
                  </button>
                  </Link>
                  </div>
                  <div className="w-1/2">
                  <button
                    type="button"
                    className="w-full text-white font-bold bg-red-500 p-1 rounded-md"
                    onClick={(e) => {
                      row.adtype === "Sell" || row.adtype === "sell"
                        ? cancelSellAd(row._id, row.adId, row)
                        : cancelBuyAd(row._id, row.adId, row);
                    }}
                  >
                    <DeleteRoundedIcon /> Delete
                  </button>
                  </div>
                </div>
              </div>
            </Card>
          </>
        ))}
      </div>
    </div>
  );
}
export default UpCommingIcoList;
