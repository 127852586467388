import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HelpIcon from "@mui/icons-material/Help";
import React, { useEffect, useState } from "react";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import GroupIcon from "@mui/icons-material/Group";
import Avatar from "@mui/material/Avatar";
import { deepOrange, yellow } from "@mui/material/colors";
import PaymentIcon from "@mui/icons-material/Payment";
import { userDetails } from "../Auth/AuthAction";
import { Link, useNavigate } from "react-router-dom";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { useAccount } from "wagmi";
import "react-circular-progressbar/dist/styles.css";
import JumpStartWrapper from "./JumpStartWrapper";
import DemoRingProgress from "./DemoRingProgress";
import backImage from "../../assets/ModernBanner.gif";
import Loader from "../../Components/Loader";
import { API_URL } from "../../Config/Auth";
import axiosClient from "../../service/axios";
import { showToast } from "../Main/common";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const twoColors = {
  "0%": "#108ee9",
  "100%": "#87d068",
};
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
function Profile(props) {
  const { isConnected, address, isDisconnected } = useAccount();
  const [userDetails , setUserDetails] = useState("");
  const [loading, setLoading] = useState(false);
  let navigation = useNavigate();
  const getUserDetils = async () => {
    try {
      let res = await axiosClient.get(`${API_URL}/user/other/${address}`);
      console.log("userSA1",res.data);
      if (res && res.data && res.data.success === true) {
        setUserDetails(res.data.userDetail);
        console.log("userSA",res.data.userDetail);
        setLoading(false);
      } else {
        showToast(res.data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      showToast(error.message, "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    getUserDetils();
  }, []);
  const userDetail = userDetails ;
const adsTotal = parseInt(userDetails && userDetails.ads.totalOrders);
const adRate = parseInt(userDetails && userDetails.ads.orderCompletionRate);
 const totalOrder = adsTotal/100;
  const orderCompletionRates = adRate/100;
  // console.log("orderCompletionRates",orderCompletionRates);
  if (loading) {
    return <Loader />;
  }
  // if (props.fetchingUserDetails) {
  //   return <Loader />;
  // }

  return (
    <div className="">
      <div className="m-2 lg:hidden" >
      <button
           className="flex justify-start sticky left-0 right-0 p-1 top-0 m-2"
             onClick={(e) => navigation("/myads")}
            > <ArrowCircleLeftIcon color="warning" />
            </button>
      </div>
      <div
        className="flex items-end w-full rounded-t-md h-40"
        style={{ backgroundImage: "url(" + backImage + ")" }}
      >
        <div className=" -mb-4">
          <Link to="/profileEdit">
            <Button
              // variant="contained"
              className=" bg-transparent p-0"
              startIcon={
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    sx={{ bgcolor: "#eab308", width: 56, height: 56 }}
                    alt="Remy Sharp"
                    src={`${userDetail && userDetail.url}`}
                  />
                </StyledBadge>
              }
            >
              <ModeEditIcon
                fontSize="small"
                className="-ml-4 -mt-2 z-50  text-black"
              />
            </Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col  w-full ">
        <div className="flex flex-col border shadow-sm  shadow-yellow-500  space-y-2">
          {/* <div className="w-full ">
            <img src={userDetail.url}  />
          </div> */}
          <div className="flex flex-col gap-2 p-2">
            {/* <div className="flex justify-start">
             
               <Link to="/profileEdit">
                <div className=" flex"> 
                <p className="mt-3 font-bold text-lg">{userDetail.name}</p>
               
                  <Button
                    // variant="contained"
                    className=" bg-transparent p-0"
                    startIcon={
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          sx={{ bgcolor: deepOrange[500], fontSize: "large" }}
                          alt="Remy Sharp"
                          src={`${userDetail && userDetail.url}`}
                        />
                      </StyledBadge>
                    }
                  >
                    <ModeEditIcon
                      fontSize="small"
                      className="-ml-4 -mt-2 z-50  text-black"
                    />
                  </Button>
                
                </div></Link>
              
             
            </div> */}
            {/* <div className="mt-8"> */}
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              {userDetail.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Online
            </Typography>
            <Typography variant="body2">
              <DoneOutlinedIcon sx={{ color: "#eab308" }} />
              ID verified
              <DoneOutlinedIcon sx={{ color: "#eab308" }} />
              Mobile
              <DoneOutlinedIcon sx={{ color: "#eab308" }} />
              Email
            </Typography>
            {/* </div> */}
            {/* <div className="flex gap-2">
              <ContactMailIcon />
              <p className="text-sm">{userDetail.email}</p>
              <GppGoodIcon sx={{ color: "#eab308" }} />
            </div>
            <div className="flex gap-2">
              <CakeIcon />
              <p className="text-sm">{userDetail.dob}</p>
            </div>
            <div className="flex gap-2">
              <ContactPhoneIcon />
              <p className="text-sm">{userDetail.mobile}</p>
            </div>
            <div className="flex gap-2">
              <CurrencyExchangeIcon />
              <p className="text-sm">{userDetail.currency}</p>
            </div> */}
          </div>
        </div>

        <div className=" border shadow-sm shadow-yellow-500 rounded-md p-2 mt-3 space-y-2">
          <p className="flex justify-start text-lg">Account Type</p>

          <div className="flex justify-center gap-2">
            {userDetail && userDetail.accounttype && (
              <>
                <LocalActivityIcon sx={{ color: "#eab308" }} />
                <p className="flex justify-center font-bold text-xl -mt-1">
                  {userDetail &&
                    userDetail.accounttype &&
                    userDetail.accounttype.split("_").join(" ").toUpperCase()}
                </p>
              </>
            )}
          </div>
          <p>
            {userDetail && userDetail.kyc === true ? (
              <>
                <em>
                  {planDescription(
                    userDetail &&
                      userDetail.accounttype &&
                      userDetail.accounttype
                  )}
                </em>
              </>
            ) : (
              <>
                <em>
                  {planDescriptionKyc(
                    userDetail &&
                      userDetail.accounttype &&
                      userDetail.accounttype
                  )}
                </em>
              </>
            )}
          </p>

          <Link to={"/upgrade"}>
            <div className="flex p-2 w-full">
              <button className="w-full  uppercase font-bold bg-yellow-500  p-2 rounded-md">
                Upgrade
              </button>
            </div>
          </Link>
        </div>
      </div>
      {/* <div className="p-2 h-fit">
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

        <div className="mt-8">
          <Typography
            variant="h5"
            component="div"
            className="flex flex-row justify-between"
          >
            Ashok Sahoo
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Online
          </Typography>
          <Typography variant="body2">
            <DoneOutlinedIcon sx={{ color:"#eab308"}} />
            ID verified
            <DoneOutlinedIcon sx={{ color:"#eab308"}} />
            Mobile
            <DoneOutlinedIcon sx={{ color:"#eab308"}} />
            Email
          </Typography>
        </div>
      </div> */}
      <div className="p-2 border shadow-sm shadow-yellow-500 rounded-md my-5 h-fit">
        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
        <Typography
          variant="h5"
          component="div"
          className="flex flex-row justify-between"
        >
          <div>Info</div>

          <div className=" z-50">
            <Link to={`/feedback/${userDetail && userDetail._id}`}>
              <button className="bg-yellow-500 p-2 text-lg text-white rounded-md font-bold">
                Reviews <ArrowForwardIcon />
              </button>
            </Link>
          </div>
        </Typography>
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-1">
            <Typography variant="body2" color="text.secondary">
              Total completed orders
            </Typography>

            <div className="m-2 rounded-full p-2 border w-fit shadow-md shadow-yellow-500  hover:shadow-green-500">
              <DemoRingProgress value={totalOrder} />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <Typography variant="body2" color="text.secondary">
              Completion rate(%)
            </Typography>

            <div className="m-2 rounded-full p-2 border w-fit shadow-md shadow-yellow-500  hover:shadow-green-500">
              <DemoRingProgress value={orderCompletionRates} />
            </div>
          </div>
          <div className="grid grid-cols-1">
            <Typography variant="body2" color="text.secondary">
              Avg. payment time
            </Typography>
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              0m 53s
            </Typography>
          </div>
          <div className="grid grid-cols-1">
            <Typography variant="body2" color="text.secondary">
              Positive reviews(%)
            </Typography>
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              NA
            </Typography>
          </div>
        </div>
      </div>
      <div className="px-2 py-6 border shadow-sm shadow-yellow-500 rounded-md my-5 space-y-3 h-fit">
        {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
        <div>
          <Link to="/payment">
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              <div>
                <PaymentIcon
                  fontSize="large"
                  className="mr-2  bg-yellow-500 text-white rounded-md p-2"
                />
                Payment
              </div>

              <ArrowForwardIcon />
            </Typography>
          </Link>
        </div>
        <div>
          <Link to="/upgrade">
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              <div>
                <UpgradeIcon
                  fontSize="large"
                  className="mr-2  bg-yellow-500 text-white rounded-md p-2"
                />
                Upgrade
              </div>

              <ArrowForwardIcon />
            </Typography>
          </Link>
        </div>
        <div>
          <Link to="/help">
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              <div>
                <HelpIcon
                  fontSize="large"
                  className="mr-2  bg-yellow-500 text-white rounded-md p-2"
                />
                Help & Support
              </div>

              <ArrowForwardIcon />
            </Typography>
          </Link>
        </div>
        <div>
          <Link to="/refer">
            <Typography
              variant="h5"
              component="div"
              className="flex flex-row justify-between"
            >
              <div>
                <GroupIcon
                  fontSize="large"
                  className="mr-2  bg-yellow-500 text-white rounded-md p-2"
                />
                Refer
              </div>

              <ArrowForwardIcon />
            </Typography>
          </Link>
        </div>
      </div>
      <div className="p-2 flex justify-center">
        <p className=" text-lg font-mono italic">@Handcrafted By DP2p</p>
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth }) => ({
  user: auth.userDetails,
  fetchingUserDetails: auth.fetchingUserDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userDetails }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
const planDescription = (plan) => {
  switch (plan) {
    case "early_bird":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              100 $ (USDT) Daily
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Can't ad post
            </li>
          </ul>
        </>
      );
    case "trader":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Buy upto 1000$
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Ad post upto 1000 $
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              25000 graphite stacking
            </li>
          </ul>
        </>
      );
    case "merchant":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Post ad up to 10000$ monthly
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Post ad up to 10000$ monthly
            </li>
          </ul>
        </>
      );
    case "diamond":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Unlimited buy sell
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              ad post
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              *100000 graphite stacking premium support
            </li>
          </ul>
        </>
      );
    default:
      return "No Plan found";
  }
};
const planDescriptionKyc = (plan) => {
  switch (plan) {
    case "early_bird":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              100 $ (USDT) Daily
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Can't ad post
            </li>
          </ul>
        </>
      );
    case "trader":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Buy upto 1000$
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Ad post upto 1000 $
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              25000 graphite stacking
            </li>
          </ul>
        </>
      );
    case "merchant":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Post ad up to 10000$ monthly
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Post ad up to 10000$ monthly
            </li>
          </ul>
        </>
      );
    case "diamond":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Unlimited buy sell
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              ad post
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              *100000 graphite stacking premium support
            </li>
          </ul>
        </>
      );
    default:
      return "No Plan found";
  }
};
