import React, { lazy } from "react";
import "./App.css";
import { useAccount, useBalance } from "wagmi";
import { useEffect, useState } from "react";
import { API_URL } from "./Config/Auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userDetails } from "./Container/Auth/AuthAction";
import MainApp from "./Container/Main/MainApp";
import JoinSocket from "./Screen/JoinSocket";
import { Alert, Spin } from 'antd';
import { Toaster } from "react-hot-toast";
import AppErrorBoundary from "./Container/Main/AppErrorBoundary";
import { useNavigate } from "react-router-dom";

function App(props) {
  const { isConnected, address, isDisconnected } = useAccount();
  const navigate = useNavigate();
  useEffect(() => {
    if(isConnected){
    props.userDetails(address);}
  }, [address]);
  useEffect(()=>{
    if (isDisconnected) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
      // window.location.reload();
    }
  },[isDisconnected]);
  // if (isDisconnected) {
  //   sessionStorage.clear();
  //   localStorage.clear();
  //  // window.location.reload();
  // }
  
  const userDetail = sessionStorage.getItem("userDetails");
  JoinSocket();
  return (
    <>
      <div>
      <AppErrorBoundary>  
        {/* {isDisconnected ? (
          <SignIn />
        ) : isConnected && props.fetchingUserDetails ? (
          <Spin tip="Loading..." size="large">
      <Alert
        message="Please Wait"
        description="Please wait few seconds."
        type="info"
      />
    </Spin>
        ) : isConnected && userDetail ? (
          <MainApp />
        ) : isConnected && !userDetail ? (
          <SignUp />
        ) : null} */}
        <MainApp />
        <Toaster />
        </AppErrorBoundary>  
      </div>
    </>
  );
}
const mapStateToProps = ({ auth }) => ({
  fetchingUserDetails: auth.fetchingUserDetails,
  userDetails: auth.userDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userDetails }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);
