import * as types from "./OrderActionTypes";
import axios from "axios";
import { message, notification } from "antd";
import { createBrowserHistory } from "history";
import { API_URL } from "../../Config/Auth";
import axiosClient from "../../service/axios";

const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
export const getOrderListByAddress =
  (address, coinType, orderType, orderStatus, paymentStatus) => (dispatch) => {
    dispatch({
      type: types.GET_ALL_ORDER_REQUEST,
    });
    axiosClient
      .post(`${API_URL}/orders/${address}`, {
        coinType,
        orderType,
        orderStatus,
        paymentStatus,
      })

      .then((res) => {
        console.log(res);

        dispatch({
          type: types.GET_ALL_ORDER_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.GET_ALL_ORDER_FAILURE,
          payload: err,
        });
      });
  };

export const getOrderDetailsById = (id) => (dispatch) => {
  dispatch({
    type: types.GET_ORDER_DETAILS_BY_ID_REQUEST,
  });
  axiosClient
    .get(`${API_URL}/orders/details/${id}`)

    .then((res) => {
      console.log(res);

      dispatch({
        type: types.GET_ORDER_DETAILS_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ORDER_DETAILS_BY_ID_FAILURE,
        payload: err,
      });
    });
};

// export const fetchcurrencyList = async () => {
//   try {
//       let response=await axiosClient .get(`${API_URL}/crypto`);
//       return response.data.crypto;
//   }
//   catch (error) {
//       throw error;
//   }
// };
