import React from "react";
import step1 from "../../assets/list-project/misc-2.svg";
import step2 from "../../assets/list-project/misc-1.svg";
import step3 from "../../assets/list-project/first-fold.png";
import step4 from "../../assets/list-project/global.png";
import step5 from "../../assets/list-project/benefit-1.svg";
import step6 from "../../assets/list-project/experience.svg";
import { Link } from "react-router-dom";

const benefits = [
  {
    title: "Popular Payment Methods",
    subtitle:
      "Make it easy for your community to trade, whatever their preferred way to trade.",
    image: "",
  },
  {
    title: "Non-Custodial Trading",
    subtitle:
      "Keep your community safe by providing them with non-custodial trading options.",
    image: "",
  },
  {
    title: "First Class Support",
    subtitle:
      "Keep your community safe by providing them with non-custodial trading options.",
    image: "",
  },
  {
    title: "Fast and Easy Trades",
    subtitle:
      "Traders can sign up and start trading immediately, with no roadblocks or excessive limitations.",
    image: "",
  },
];

const ProjectList = () => {
  return (
    <main className=" text-white bg-[#020d18] overflow-hidden px-6">
      <div className="grid md:grid-cols-2 h-[100dvh] items-center py-10 relative">
        <img
          src={step1}
          alt="Experience"
          width={214}
          height={12}
          className="md:pl-20 md:mt-6 absolute bottom-20 md:bottom-[25%]"
        />
        <div className=" w-[280px] h-[310px] rounded-full bg-gradient-purple blur-[150px] z-0 top-0  absolute left-[-25%]" />
        <div className=" w-[280px] h-[310px] rounded-full bg-gradient-blue-2 blur-[150px] z-0 bottom-0  absolute right-[-25%]" />
        <img
          src={step2}
          alt="Experience"
          width={32}
          height={53}
          className="  absolute top-[5%] right-0"
        />
        <div className="flex flex-col md:pl-20 z-50">
          <h2 className=" text-[clamp(2.25rem,8vw,4rem)] font-bold ">
            Project listing
          </h2>
          <p className=" text-xl max-w-md">
            Give your cryptocurrency project more than just an exchange listing
          </p>
          <div className="my-3">
            <Link to={"/contact"}>
              <button className="bg-yellow-500 text-lg font-bold text-white p-2 rounded-md shadow-sm shadow-yellow-500">
                CONTACT US
              </button>
            </Link>
          </div>
        </div>
        <img
          src={step3}
          width={700}
          height={700}
          alt="Hero Image"
          className="md:pl-20 scale-90 mx-auto"
        />
      </div>
      <div className=" bg-darkBlue">
        <div className=" grid md:grid-cols-2  place-items-center py-10 md:py-20">
          <img
            src={step4}
            width={500}
            height={500}
            alt="Globe Image"
            className=" order-2 md:order-1"
          />
          <div className="grid gap-6 max-w-lg md:order-2">
            <h2 className=" text-2xl md:text-4xl font-bold">
              Expose your Project to a Global Marketplace
            </h2>
            <p className="  leading-loose">
              Finally, DP2P is opening up listing applications enabling your
              team to leverage the most popular non-custodial peer-to-peer (P2P)
              marketplace to bring your project to the world.
              <br />
              <br />
              It doesn’t matter if your project has only just launched or if
              it’s an established and well-known part of the cryptocurrency
              ecosystem already; DP2P provides the ability to take your project
              to the broadest possible audience.
            </p>
            <div className="my-3">
              <Link to={"/contact"}>
                <button className="bg-yellow-500 text-lg font-bold text-white p-2 rounded-md shadow-sm shadow-yellow-500">
                  LETS CONNECT YOUR PROJECT
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" py-20 md:py-40 text-center grid gap-12">
        <h2 className=" text-4xl font-bold">Benefits of Listing on DP2P</h2>
        <div className="grid  md:grid-cols-2 xl:grid-cols-4 gap-8">
          {benefits.map((benefit) => {
            return (
              <div
                key={benefit.title}
                className=" p-12 flex-col bg-[#031629] rounded-lg text-center flex items-center justify-center gap-4"
              >
                <img src={step5} alt={benefit.title} width={60} height={60} />
                <h2 className=" text-xl font-bold">{benefit.title}</h2>
                <p className="  text-sm">{benefit.subtitle}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" bg-gradient-cyan py-40">
        <div className=" grid place-items-center gap-6  text-center">
          <h2 className=" text-4xl font-bold mb-6">
            Experience <span className=" text-darkBlue">Matters</span>
          </h2>
          <p className=" max-w-4xl text-lg leading-8">
            The team at DP2P has been actively pushing the boundaries of P2P
            trading since the project was launched in 2023
          </p>
          <img src={step6} alt="Experience" width={1140} height={240} />
          <p className=" max-w-4xl  text-base leading-8">
            {" "}
            The team at DP2P has been actively pushing the boundaries of P2P
            trading since the project was launched in 2023
          </p>
          <p className=" max-w-4xl  font-bold text-lg leading-8">
            Ethereum, BNB Smart Chain, and Polygon
          </p>
          <p className=" max-w-4xl  text-base leading-8">
            We aren’t scared of a challenge, so whether you’re looking to list a
            token on an existing blockchain or a completely new cryptocurrency,
            <span className=" text-darkBlue font-bold">
              you’ll be surprised by what we can accomplish.
            </span>
          </p>
        </div>
      </div>
      <div className=" grid place-items-center gap-4  text-center py-40">
        <h2 className=" font-bold text-[clamp(2.5rem,8vw,6rem)] ">
          <span className=" text-oceanBlue">Fast</span> &{" "}
          <span className=" text-oceanBlue">Easy</span>
        </h2>
        <p className=" text-4xl font-bold">Implementation</p>
        <p className=" text-base max-w-4xl leading-loose">
          Our extensive experience results in fast turnaround times, rapid
          innovation, and a first mover advantage that can have your community
          on the cusp of the latest in the cryptocurrency markets
        </p>
        <img
          src={step1}
          alt="Experience"
          width={138}
          height={6}
          className=" py-16"
        />
        <p className=" text-lg font-bold max-w-4xl leading-loose">
          Don’t fall behind before you even start; let modern P2P trading take
          your project to the next level by providing your community with the
          ultimate flexibility and convenience
        </p>
      </div>
      {/* <div className=" bg-gradient-blue py-20 md:py-40 px-2">
        <div className=" grid md:grid-cols-2  gap-6 ">
          <div className="flex flex-col  pb-10 ">
            <h2 className=" text-2xl md:text-5xl font-bold mb-6">
              Ready to launch?
            </h2>
            <p className=" max-w-md text-xl leading-8">
              Apply to enlist your token. Submit the form and we&apos;ll take it
              from there.
            </p>
          </div>

          <div className=" w-full">
            <form action="" className=" grid gap-6 w-full">
              <div className=" grid gap-1">
                <label htmlFor="name" className=" text-base font-bold">
                  Name (Point of contact)
                </label>
                <input
                  className=" bg-dark text-white focus:outline-oceanBlue py-2 px-2 rounded-md duration-300 ease-in-out"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                />
              </div>
              <div className=" grid gap-1">
                <label htmlFor="email" className=" text-base font-bold">
                  Email
                </label>
                <input
                  className=" bg-dark text-white focus:outline-oceanBlue py-2 px-2 rounded-md duration-300 ease-in-out"
                  type="email"
                  name="email"
                  id="email"
                />
              </div>
              <div className=" grid gap-1">
                <label htmlFor="projectName" className=" text-base font-bold">
                  Project name
                </label>
                <input
                  className=" bg-dark text-white focus:outline-oceanBlue py-2 px-2 rounded-md duration-300 ease-in-out"
                  type="text"
                  name="projectName"
                  id="projectName"
                />
              </div>
              <div className=" grid gap-1">
                <label htmlFor="website" className=" text-base font-bold">
                  Website
                </label>
                <input
                  className=" bg-dark text-white focus:outline-oceanBlue py-2 px-2 rounded-md duration-300 ease-in-out"
                  type="text"
                  name="website"
                  id="website"
                />
              </div>
              <div className=" grid gap-1">
                <label htmlFor="message" className=" text-base font-bold">
                  Message (optional)
                </label>
                <textarea
                  className=" bg-dark text-white focus:outline-oceanBlue py-2 px-2 rounded-md duration-300 ease-in-out"
                  name="message"
                  rows={3}
                  id="message"
                />
              </div>
              <button className="px-6 mt-4 py-2 rounded-full bg-gradient-to-r font-bold from-[#ec4899] to-[#e25353] md:w-fit  text-white">
                Apply Now
              </button>
            </form>
          </div>
        </div>
      </div> */}
    </main>
  );
};

export default ProjectList;
