import React from "react";
import AnimatedDiv from "./AnimatedDiv";
import icon6 from "../../assets/icons/icon-06.png";
import Illustration from "../../assets/Illustration-01.png";
import compas from "../../assets/icons/compass.png";
import refresh from "../../assets/icons/refresh.png";
import adduser from "../../assets/icons/adduser.png";
import icon4 from "../../assets/icons/icon-04.png";
import icon5 from "../../assets/icons/icon-05.png";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div className="grid text-white justify-between  lg:grid-cols-2 gap-10 py-20">
      <div className="relative m-auto">
        <img
          src={Illustration}
          width={557}
          height={527}
          alt="xyz"
          className="m-auto"
        />
        <div className=" bg-slate-800 flex gap-4 rounded-lg p-4 w-[200px] absolute top-16 left-0 md:left-16">
          <img
            src={compas}
            alt="tile icon"
            width={26}
            height={26}
            className=" object-contain object-top"
          />
          <div>
            <h2 className="text-white font-bold text-2xl">198+</h2>
            <p className="text-[#b1b5c3] text-lg font-normal">Countries</p>
          </div>
        </div>
        <div className=" bg-slate-800 flex gap-4 rounded-lg p-4 w-[200px] absolute top-[50%] right-0 md:right-[-10%]">
          <img
            src={refresh}
            alt="tile icon"
            width={26}
            height={26}
            className=" object-contain object-top"
          />
          <div>
            <h2 className="text-white font-bold text-2xl">350+</h2>
            <p className="text-[#b1b5c3] text-lg font-normal">Trading Pairs</p>
          </div>
        </div>
        <div className=" bg-slate-800 flex gap-4 rounded-lg p-4 w-[210px] absolute top-[60%] left-0 md:left-[10%]">
          <img
            src={adduser}
            alt="tile icon"
            width={26}
            height={26}
            className=" object-contain object-top"
          />
          <div>
            <h2 className="text-white font-bold text-2xl">20 million+</h2>
            <p className="text-[#b1b5c3] text-lg font-normal">Trades</p>
          </div>
        </div>
      </div>
      <AnimatedDiv>
        <div className="flex justify-end flex-col">
          <h3 className=" text-[32px]  md:text-[48px] tracking-tight font-bold leading-[1.2]">
            We Are The Most Trusted P2P Platform.
          </h3>
          <p className=" text-[#b1b5c3] text-xl  capitalize">
          Getting the information you need when you need it enables you to focus on what matters most to you.
          </p>

          <ul className=" py-10 grid gap-7">
            {/* <li className="grid grid-cols-[auto_1fr] gap-8">
              <div className=" flex items-center justify-center p-4 h-[90px] aspect-square  bg-red-500/10 rounded-md">
                <img src={icon4} width={25} height={25} alt="icon-4" />
              </div>
              <div className="grid gap-2">
                <h3 className="font-bold text-2xl text-white">Clarity</h3>
                <p className=" text-[#b1b5c3] text-base  capitalize">
                  We Help You Make Sense Of The Coins, The Terms, The Dense
                  Charts And Market Changes.
                </p>
              </div>
            </li> */}

            <li className="grid grid-cols-[auto_1fr] gap-8">
              <div className=" flex items-center mt-2 justify-center p-4 h-[90px] aspect-square  bg-blue-500/10 rounded-md">
                {/* <img src={icon6} width={25} height={25} alt="icon-6" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 text-blue-500 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>
              </div>
              <div className="grid gap-2">
                <h3 className="font-bold text-2xl text-white">
                  Find Help with 24/7 Customer Support
                </h3>
                <p className=" text-[#b1b5c3] text-base  capitalize">
                  Real support means being able to reach someone when it
                  matters.
                </p>
                <div>
                  <Link to={"/contact"}>
                    <button className="bg-yellow-500 font-bold text-white p-2 rounded-md shadow-sm shadow-yellow-500">
                      CONTACT US
                    </button>
                  </Link>
                </div>
              </div>
            </li>
            <li className="grid grid-cols-[auto_1fr] gap-8">
              <div className=" flex items-center justify-center mt-2 p-4 h-[90px] aspect-square  bg-green-500/10 rounded-md">
                {/* <img src={icon5} width={25} height={25} alt="icon-5" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 text-green-500 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                  />
                </svg>
              </div>
              <div className="grid gap-2">
                <h3 className="font-bold text-2xl text-white">
                  Expand Your Knowledge
                </h3>
                <p className=" text-[#b1b5c3] text-base  capitalize">
                  {
                    "Increasing your cryptocurrency trading knowledge is easy when you have the best free cryptocurrency guides and trading tutorials."
                  }
                </p>
                <div>
                  <Link to={"http://learn.dp2p.cc/"}>
                    <button className="bg-yellow-500 font-bold text-white p-2 rounded-md shadow-sm shadow-yellow-500">
                    LERN MORE
                    </button>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default About;
