import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getPaymentMethodById,
  deletePaymentMethodById,
} from "../SettingAction";
import { useAccount } from "wagmi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Card } from "@mui/material";
import { Alert, Spin } from "antd";
import Loader from "../../../Components/Loader";

function Payment(props) {
  const { isConnected, address, isDisconnected } = useAccount();
  useEffect(() => {
    props.getPaymentMethodById(address);
  }, []);
  
  if (props.fetchingPaymentMethodById) {
    return (
      <Loader />
    );
  }
  const paymentMethodById = props.paymentMethodById;
  return (
    <div className="flex flex-col m-1 p-5 space-y-4">
      <p className=" text-2xl font-bold text-black">Payment Method</p>
      <p className="p-1">
        P2P Payment Method : When you sell cryptocurrency, the payment method
        add will display to buyers as option to accept payment.Please ensure
        that all account owner's name is consistent with your account name on
        DP2P.You can add up to 20 payment methods.
      </p>
      <div className="flex w-60">
        <Link to={"/addPayment"}>
          <button className="border flex  p-2 px-4 rounded">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
            Add a payment method
          </button>
        </Link>
      </div>

      <div class="flex flex-col gap-3">
        {paymentMethodById &&
          paymentMethodById.map((method, index) => {
            return (
              <>
                <Card className=" p-2 shadow-md  shadow-yellow-500" key={index}>
                  <div class="flex justify-between">
                    <div>
                      <p className=" text-lg font-bold">
                        
                        {method.paymentMethod.name}
                      </p>
                    </div>
                    <div>
                      <button
                        className=" bg-red-500 text-yellow-500 border p-1 rounded-lg"
                        onClick={() =>
                          props.deletePaymentMethodById(method._id, address)
                        }
                      >
                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>

                  <div>
                    <div className="grid">
                      {method.details.map((detail, j) => {
                        return (
                          <div key={j}>
                            <div className="flex p-0">
                              <p className="px-2">{detail.details}</p>
                            </div>
                          </div>
                        );
                      })}
                      <div className="flex">
                        <div className="flex flex-col">
                          {method.imps_methods.length > 0 &&
                            method.imps_methods.map((imps, k) => {
                              return (
                                <div key={k}>
                                  <h6>IMPS Details {k + 1}</h6>
                                  <p>{imps.bankName}</p>
                                  <p>
                                    {imps.accountNumber} ({imps.accountType})
                                  </p>
                                  <p>{imps.ifscCode}</p>
                                  <p>{imps.branchDetails}</p>
                                  {k !== method.imps_methods.length - 1 && (
                                    <hr />
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            );
          })}
      </div>
    </div>
  );
}

const mapStateToProps = ({ setting }) => ({
  paymentMethodById: setting.paymentMethodById,
  fetchingPaymentMethodById: setting.fetchingPaymentMethodById,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getPaymentMethodById, deletePaymentMethodById },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
