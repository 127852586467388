import React, { useState } from "react";
import { Steps } from "antd";

const OrderSteps = (props) => {
  const details = props.details;

  return (
    <div>
      <Steps
        current={`${
          details && details.orderStatus === "completed"
            ? 3
            : details && details.orderStatus === "dispute"
            ? 1
            : details && details.paymentStatus === "paid" 
            ? 1
            : null
        }`}
        // current={2}
        status="error"
        labelPlacement="vertical"
        direction="horizontal"
        responsive={false}
        items={[
          {
            //  title: "Payment",
            title: `${
              details && details.paymentStatus === "paid" ? "Paid" : "Payment"
            }`,
          },
          {
            //  title: "Release",
            title: `${
              details &&
              details.paymentStatus === "paid" &&
              details.orderStatus === "completed"
                ? "Released"
                : details.paymentStatus === "paid" &&
                  details.orderStatus === "dispute"
                ? "Dispute"
                : "Release"
            }`,
          },
          {
            // title: "Complete",
            title: "Complete",
            title: `${
              details &&
              details.paymentStatus === "paid" &&
              details.orderStatus === "completed"
                ? "Completed"
                : // : details.paymentStatus === "paid" &&
                  //   details.orderStatus === "dispute"
                  // ? "Dispute"
                  "Complete"
            }`,
          },
        ]}
      />
    </div>
  );
};

export default OrderSteps;
