import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Slider,
  Switch,
  TreeSelect,
  Upload,
  message,
} from "antd";
import { useAccount } from "wagmi";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { registerUser, userDetails } from "../Auth/AuthAction";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { API_URL } from "../../Config/Auth";
import GppGoodIcon from "@mui/icons-material/GppGood";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CakeIcon from "@mui/icons-material/Cake";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../Components/Loader";
import axiosClient from "../../service/axios";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
const { Option } = Select;
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const ProfileEdit = (props) => {
  let navigation = useNavigate();
  const { isConnected, address, isDisconnected } = useAccount();
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [files, setFiles] = useState(null);

  useEffect(() => {
    props.userDetails(address);
    fetchcurrencyList();
    fetchCountryList();
  }, []);
  const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
  console.log("profile", props.user && props.user.userDetail);
  const userDetail = props.user && props.user.userDetail;
  const [email, setEmail] = useState(userDetail && userDetail.email);
  const fetchcurrencyList = async () => {
    try {
      let response = await axios.get(`${API_URL}/currency`);
      let data = response.data;
      setCurrencyList(data.currencies);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCountryList = async () => {
    try {
      let response = await axios.get(`${API_URL}/country`);
      let data = response.data;
      setCountryList(data.countries);
    } catch (error) {
      console.log(error);
    }
  };

  const email_verified =
    props.user && props.user.userDetail && props.user.userDetail.email;

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const normFile = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosClient.post(`${API_URL}/upload/file`, formData);
    let res = response.data;
    if (res.success) {
      setFiles(res.file.filename);
      // props.userDetails(address);
      message.success("Image uploaded successfully");
    }
  };
  console.log("files", files);
  const sendVerificationEmail = async (email) => {
    let data = { email };
    try {
      let res = await axios.post(`${API_URL}/verify-email`, data);
      if (res.data.success) {
        setIsModalOpen(true);
        message.success("Verification email sent successfully");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmitOtp = async (otp, email) => {
    let dataO = { otp, email };
    try {
      let response = await axios.post(`${API_URL}/verify-otp`, dataO);
      let data = response.data;
      if (data.success) {
        message.success(data.message);
        setComponentDisabled(true);
        setIsModalOpen(false);
      } else {
        message.error(data.message);
        setComponentDisabled(false);
        setIsModalOpen(true);
      }
    } catch (error) {
      message.error(error.message);
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEmail("");
    setComponentDisabled(false);
    setOtp("");
  };

  const handleChangeCountry = (selectedItems) => {
    setSelectedCountry(selectedItems);
  };
  const handleChangeCurrency = (selectedItems) => {
    setSelectedCurrency(selectedItems);
  };

  // const initialValues = {
  //   email: email || "",
  //   name: userDetail.name || "",
  //   mobile: userDetail.mobile || "",
  //   country: userDetail.country || "",
  //   currency: userDetail.currency || "",
  //   wallet: userDetail.wallet || "",
  // };
  const prefixSelector = (
    <Form.Item name="+91" noStyle>
      <Select
        defaultValue={"+91"}
        style={{
          width: 70,
        }}
      >
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );

  const onFinish = (values) => {
    values.file= files;
    console.log("files",values);
    props.registerUser(values, address);
  };
  if (!userDetail) {
    return (
      <Loader />
    );
  }
  if (props.fetchingUserDetails) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <div className="flex lg:flex-row flex-col lg:gap-2 ">
      <div className="m-2 lg:hidden" >
      <button
           className="flex justify-start sticky left-0 right-0 p-1 top-0 m-2"
             onClick={(e) => navigation("/myads")}
            > <ArrowCircleLeftIcon color="warning" />
            </button>
      </div>
        <div className="flex flex-col  w-full lg:w-1/3 max-sm:hidden max-md:hidden">
          <div className="flex flex-col border shadow-sm  shadow-yellow-500  space-y-2">
            <div className="w-full ">
              <img src={userDetail.url} height={200} />
            </div>
            <div className="flex flex-col gap-2 p-2">
              <div className="flex gap-2">
                <p className=" font-bold text-sm">Wallet:</p>
                <p className="truncate w-100">{userDetail.wallet}</p>
              </div>
              <div className="flex justify-center">
                <p className=" font-bold text-lg">{userDetail.name}</p>
              </div>

              <div className="flex gap-2">
                <ContactMailIcon />
                <p className="text-sm">{userDetail.email}</p>
                <GppGoodIcon sx={{ color:"#eab308"}} />
              </div>
              <div className="flex gap-2">
                <CakeIcon />
                <p className="text-sm">{userDetail.dob}</p>
              </div>
              <div className="flex gap-2">
                <ContactPhoneIcon />
                <p className="text-sm">{userDetail.mobile}</p>
              </div>
              <div className="flex gap-2">
                <CurrencyExchangeIcon />
                <p className="text-sm">{userDetail.currency}</p>
              </div>
            </div>
          </div>

          <div className=" border shadow-sm  shadow-yellow-500 p-2 mt-3 space-y-2">
            <p className="flex justify-start text-lg">Account Type</p>

            <div className="flex justify-center gap-2">
              {userDetail && userDetail.accounttype && (
                <>
                  <LocalActivityIcon sx={{ color:"#eab308"}} />
                  <p className="flex justify-center font-bold text-xl -mt-1">
                    {userDetail &&
                      userDetail.accounttype &&
                      userDetail.accounttype.split("_").join(" ").toUpperCase()}
                  </p>
                </>
              )}
            </div>
            <p>
              {userDetail && userDetail.kyc === true ? (
                <>
                  <em>
                    {planDescription(
                      userDetail &&
                        userDetail.accounttype &&
                        userDetail.accounttype
                    )}                   
                  </em>
                </>
              ):
              (
                <>
                  <em>
                    {planDescriptionKyc(
                      userDetail &&
                        userDetail.accounttype &&
                        userDetail.accounttype
                    )}
                  </em>
                </>
              )}
            </p>
            <div className="flex p-2 w-full">
              <button className="w-full  uppercase font-bold bg-yellow-500  p-2 rounded-md">
                <Link to={"/upgrade"}>Upgrade</Link>
              </button>
            </div>
          </div>
        </div>

        <div className=" border shadow-sm  shadow-yellow-500 w-full lg:w-2/3 p-2">
          <Form
            className="w-full flex justify-center space-y-3"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              email: email,
              name: userDetail.name || "",
              mobile: userDetail.mobile || "",
              country: userDetail.country || "",
              currency: userDetail.currency || "",
              wallet: userDetail.wallet || "",
            }}
            // validateMessages={validateMessages}
          >
            <div>
              <Form.Item
                label="Upload Profile"
                name="file"
                onChange={normFile}
                 value={files}
                // onChange={}
              >
                <Input
                  type="file"
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  // value={files}
                />
              </Form.Item>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
                
              >
                <Input placeholder="Name" disabled={userDetail.kyc}/>
              </Form.Item>
              <Form.Item
                name="mobile"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please select your Country!",
                  },
                ]}
              >
                <Select placeholder="Country" onChange={handleChangeCountry}>
                  <Option value={userDetail.country} disabled>
                    India
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Please select your Currency!",
                  },
                ]}
              >
                <Select placeholder="Currency" onChange={handleChangeCurrency}>
                  <Option value={userDetail.currency} disabled>
                    Rupees
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Email"
                defaultValue={userDetail && userDetail.email}
                value={email}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
                validateStatus={
                  email_verified === `${email}`
                    ? "success"
                    : componentDisabled === true
                    ? "success"
                    : "validating"
                }
                hasFeedback
              >
                <Input
                  // value={email}
                  // disabled={componentDisabled === true ? true : false}
                  onChange={(e) => setEmail(e.target.value)}
                  addonAfter={
                    <button
                      // className="m-0 p-0 bg-green-500 text-white font-bold"
                      onClick={() => sendVerificationEmail(email)}
                    >
                      Verify
                    </button>
                  }
                />
              </Form.Item>

              <Modal
                title="Validate Email"
                open={isModalOpen}
                onOk={() => handleSubmitOtp(otp, email)}
                onCancel={handleCancel}
              >
                <form onSubmit={() => handleSubmitOtp(otp, email)}>
                  <div className="flex flex-row gap-3">
                    <div className=" flex flex-col space-y-2">
                      <Input
                        type="text"
                        placeholder="Enter Your OTP"
                        name="otp"
                        value={otp}
                        onChange={handleOtpChange}
                      />
                    </div>
                    <div className="w-1/6 self-center">
                      <button
                        type="submit"
                        className="rounded-full bg-yellow-500  py-1 px-3 text-white font-bold"
                      >
                        Validate
                      </button>
                    </div>
                  </div>
                </form>
              </Modal>

              <Form.Item label="Wallet" name="wallet">
                <Input disabled />
              </Form.Item>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
              >
                <Checkbox>
                  I Agree to the Terms and Conditions and Privacy Policy
                  <a href="">agreement</a>
                </Checkbox>
              </Form.Item>
              <div className="w-full flex flex-col lg:gap-1 lg:space-y-0 space-y-3 lg:flex-row mt-5">
                {email_verified === `${email}` ? (
                  <button
                    type="primary"
                    htmlType="submit"
                    className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center"
                  >
                    Update
                  </button>
                ) : componentDisabled === true ? (
                  <button
                    type="primary"
                    htmlType="submit"
                    className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center"
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="primary"
                    onClick={() => sendVerificationEmail(email)}
                    className="bg-orange-500 w-full p-2 text-white rounded-full text-lg  self-center"
                  >
                    Verify your Email
                  </button>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.userDetails,
  fetchingUserDetails: auth.fetchingUserDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ registerUser, userDetails }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
const planDescription = (plan) => {
  switch (plan) {
    case "early_bird":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              100 $ (USDT) Daily
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Can't ad post
            </li>
          </ul>
        </>
      );
    case "trader":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Buy upto 1000$
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Ad post upto 1000 $
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              25000 graphite stacking
            </li>
          </ul>
        </>
      );
    case "merchant":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Post ad up to 10000$ monthly
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Post ad up to 10000$ monthly
            </li>
          </ul>
        </>
      );
    case "diamond":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              Unlimited buy sell
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              ad post
            </li>
            <li className="mb-2 flex gap-2">
              <GppGoodIcon sx={{ color: "#eab308" }} />
              *100000 graphite stacking premium support
            </li>
          </ul>
        </>
      );
    default:
      return "No Plan found";
  }
};
const planDescriptionKyc = (plan) => {
  switch (plan) {
    case "early_bird":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              100 $ (USDT) Daily
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Can't ad post
            </li>
          </ul>
        </>
      );
    case "trader":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Buy upto 1000$
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Ad post upto 1000 $
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              25000 graphite stacking
            </li>
          </ul>
        </>
      );
    case "merchant":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Post ad up to 10000$ monthly
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Advanced KYC
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Post ad up to 10000$ monthly
            </li>
          </ul>
        </>
      );
    case "diamond":
      return (
        <>
          <ul className="list-unstyled">
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              KYC mandatory
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              Unlimited buy sell
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              ad post
            </li>
            <li className="mb-2 flex gap-2">
              <CancelIcon color="disabled" />
              *100000 graphite stacking premium support
            </li>
          </ul>
        </>
      );
    default:
      return "No Plan found";
  }
};
