import styled from "styled-components";

const TextInput = styled.input.attrs({
  type: "text",
  size: (props) => (props.small ? 4 : undefined),
})`
    border-radius: 5px;
    border:0.0625em solid #d9d9d9;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.color};
    // box-shadow:${(props) => (props.isShadow ? "" : "0 0.25em 0.62em #aaa")} ; 
    display: block;
    margin: 0 0 0.25rem 0;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    font-size:0.75rem;
    width:${(props) => (props ? props.width : "100%")};
    height:${(props) => (false ? props.height : "1.8125rem")};
    Left:${(props) => props.Left || "auto"}
    border-right: ${(props) => (props.isRequired ? "3px solid #ed260b" : "")};
  
    &:hover{
    //  box-shadow: 0px 4px 10px -2px  #777;
    border: 1px solid #1890ff;
      }
    ::placeholder {
      color: #bfbebb;
    }

   `;
export default TextInput;
