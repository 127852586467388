import axios from 'axios';
import { API_URL } from '../api/const';

const axiosClient = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});



axiosClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('dp2p-token').replace('"',"").replace('"',"")} `
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosClient;
