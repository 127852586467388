import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./Store/store";
import SocketProvider from "./Screen/socketProvider";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, mainnet, polygon } from "wagmi/chains";
const chains = [bsc, mainnet, polygon];
const projectId = "331afab9d25e6a69aedae6619a61faf1";
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketProvider>
        <BrowserRouter>
          <WagmiConfig config={wagmiConfig}>
            <App />
          </WagmiConfig>

          <Web3Modal
          // themeMode="dark"
            themeVariables={{
              '--w3m-font-family': 'Roboto, sans-serif',
              '--w3m-accent-color': '#eab308',
            }}
            explorerRecommendedWalletIds={[
              "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
              "1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369",
              "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
            ]}
            projectId={projectId}
            ethereumClient={ethereumClient}
          />
        </BrowserRouter>
      </SocketProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
