export const GET_ALL_TICKET_REQUEST = "GET_ALL_TICKET_REQUEST";
export const GET_ALL_TICKET_SUCCESS = "GET_ALL_TICKET_SUCCESS";
export const GET_ALL_TICKET_FAILURE = "GET_ALL_TICKET_FAILURE";

export const ADD_TICKET_REQUEST = "ADD_TICKET_REQUEST";
export const ADD_TICKET_SUCCESS = "ADD_TICKET_SUCCESS";
export const ADD_TICKET_FAILURE = "ADD_TICKET_FAILURE";

export const GET_ALL_PAYMENT_METHOD_REQUEST = "GET_ALL_PAYMENT_METHOD_REQUEST";
export const GET_ALL_PAYMENT_METHOD_SUCCESS = "GET_ALL_PAYMENT_METHOD_SUCCESS";
export const GET_ALL_PAYMENT_METHOD_FAILURE = "GET_ALL_PAYMENT_METHOD_FAILURE";

export const GET_PAYMENT_METHOD_BY_ID_REQUEST = "GET_PAYMENT_METHOD_BY_ID_REQUEST";
export const GET_PAYMENT_METHOD_BY_ID_SUCCESS = "GET_PAYMENT_METHOD_BY_ID_SUCCESS";
export const GET_PAYMENT_METHOD_BY_ID_FAILURE = "GET_PAYMENT_METHOD_BY_ID_FAILURE";

export const DELETE_PAYMENT_METHOD_BY_ID_REQUEST = "DELETE_PAYMENT_METHOD_BY_ID_REQUEST";
export const DELETE_PAYMENT_METHOD_BY_ID_SUCCESS = "DELETE_PAYMENT_METHOD_BY_ID_SUCCESS";
export const DELETE_PAYMENT_METHOD_BY_ID_FAILURE = "DELETE_PAYMENT_METHOD_BY_ID_FAILURE";

export const ADD_PAYMENT_METHOD_REQUEST = "ADD_PAYMENT_METHOD_REQUEST";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAILURE = "ADD_PAYMENT_METHOD_FAILURE";

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE = "GET_FEEDBACK_FAILURE";