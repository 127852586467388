import React from "react";
import dottedline from "../../assets/dottedline.png";
import icon7 from "../../assets/icons/icon-07.png";
import icon8 from "../../assets/icons/icon-08.png";
import icon9 from "../../assets/icons/icon-09.png";
import icon10 from "../../assets/icons/icon-10.png";

const steps = [
  {
    number: 1,
    title: "Register",
    description:
      "Create an account on DP2P with instant sign-up and get your free Bitcoin wallet.",
    icon: `${icon8}`,
  },
  {
    number: 2,
    title: "Search Offers",
    description:
      "Use the search bar to find the best offers. Use the filters to narrow down the perfect offer.",
    icon: `${icon7}`,
  },
  {
    number: 3,
    title: "Start a Trade",
    description:
      "Once you find the right offer, check the terms. Then start the trade and begin chatting with the seller.",
    icon: `${icon9}`,
  },
  // {
  //   number: 4,
  //   title: "Sell Crypto",
  //   description:
  //     "Stacks Is A Production-Ready Library Of Stackable Content Blocks Built In React Native.",
  //   icon: `${icon10}`,
  // },
];

const Work = () => {
  return (
    <div className="grid gap-4 pb-20">
      <h2 className="text-[32px] md:text-[48px] font-bold tracking-tight text-white text-center leading-[1.2]">
        How It Work
      </h2>
      <p className=" text-[#b1b5c3] mx-auto text-xl text-center  capitalize">
        Trade with confidence on the first P2P exchange market.
      </p>
      <div className="flex flex-col md:flex-row mt-8 gap-10 justify-center w-full ">
        {steps.map((step, index) => {
          return (
            <div
              key={step.number}
              className="group relative flex flex-col md:w-fit w-full  gap-2 items-center text-white"
            >
              <img
                src={dottedline}
                width={160}
                height={12}
                alt="dotted line"
                aria-hidden={true}
                className={`  hidden absolute -right-24 top-16 ${
                  index === steps.length - 1 ? "hidden" : " md:block"
                }`}
              />
              <img
                src={step.icon}
                className=" group-hover:animate-spin"
                width={96}
                height={96}
                alt={step.title}
              />
              <p className=" uppercase font-bold flex tracking-widest text-xs text-[#b1b5c3] ">
                Step {step.number}
              </p>
              <h2 className="font-bold pb-2 flex text-xl">{step.title}</h2>
              <p className="text-[#b1b5c3] md:max-w-xs text-center">
                {step.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Work;
