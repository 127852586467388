import * as React from "react";
import {
  Button,
  Form,
  Alert,
  Spin,
  Input,
  Popconfirm,
  Switch,
  Table,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import { useState } from "react";
import { getAllTicket } from "../SettingAction";
import { useAccount } from "wagmi";
import Loader from "../../../Components/Loader";

function HelpTable(props) {
  const { isConnected, address, isDisconnected } = useAccount();

  useEffect(() => {
    props.getAllTicket(address);
  }, []);
  const ticket = props.ticket && props.ticket.data;
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
    },
    {
      title: "Message",
      dataIndex: "message",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
  ];

  if (props.fetchingAllTicket) {
    return <Loader />;
  }
  return (
    <div className="p-2">
      <Table
        columns={columns}
        loading={props.fetchingAllTicket}
        dataSource={ticket}
        //onChange={onChange}
        scroll={{ y: 300 }}
      />
    </div>
  );
}

const mapStateToProps = ({ setting }) => ({
  ticket: setting.ticket,
  fetchingAllTicket: setting.fetchingAllTicket,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllTicket }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HelpTable);
