import React, { Suspense, lazy } from "react";
import {
  useNavigate,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Profile from "../../Container/Profile/Profile";
import SignUp from "../../Container/Auth/SignUp";
import NotFound from "../../Container/Auth/NotFound";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FixedBottomNavigation from "./FixedBottomNavigation";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ProfileEdit from "../Profile/ProfileEdit";
import logo from "../../logo.png";
import EditableTable from "../Order/EditableTable";
import Dashboard from "../Dashboard/Dashboard";
import { Layout, Alert, Spin, Menu, Button, theme } from "antd";
import SignIn from "../Auth/SignIn";
import PrivateRoutes from "../../Components/utils/PrivateRoutes";
import { useAccount } from "wagmi";
import OrderDetailsById from "../Order/OrderDetailsById";
import Refer from "../Settings/Refer";
import HelpSupport from "../Settings/Help/HelpSupport";
import CreateNewTicket from "../Settings/Help/CreateNewTicket";
import Payment from "../Settings/Payment/Payment";
import Upgrade from "../Settings/upgrade/Upgrade";
import UpCommingIcoList from "../MyAds/UpCommingIcoList";
import Referal from "../Landing/referral-program/Referal";
import WalletConnect from "../../Components/Comman/WalletConnect";
import OrderChat from "../Order/OrderChat";
import { Settings } from "@mui/icons-material";
import Privacy from "../Landing/Privacy";
import Terms from "../Landing/Terms";
import Footers from "../Landing/Footers";
import ProjectList from "../Landing/ProjectList";
import Loader from "../../Components/Loader";
import ContactUs from "../Landing/ContactUs";
import AppErrorBoundary from "./AppErrorBoundary";

const AddPaymentMethod = lazy(() =>
  import("../Settings/Payment/AddPaymentMethod")
);
const CreateNewAd = lazy(() => import("../MyAds/CreateNewAd"));
const Order = lazy(() => import("../Order/Order"));
const ProfileDetailsById = lazy(() => import("../Profile/ProfileDetailsById"));
const PublicProfile = lazy(() => import("../Profile/PublicProfile"));
const Feedback = lazy(() => import("../Settings/feedback/Feedback"));
const Market = lazy(() => import("../Market/Market"));
const UpdateYourCoin = lazy(() => import("../MyAds/UpdateYourCoin"));

const { Header, Sider, Content, Footer } = Layout;
function MainApp(props) {
  const location = useLocation();
  const currentPath = location.pathname;
  const { isConnected, address, isDisconnected, isConnecting } = useAccount();
  const [collapsed, setCollapsed] = useState(false);
  // useEffect(() => {
  //   if(isConnected){
  //   props.userDetails(address);}
  // }, []);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  useEffect(()=>{
    if (isDisconnected) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
      // window.location.reload();
    }
  },[isDisconnected]);
  
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const menuItems = [
    { key: "/", label: "Market", path: "/" },
    { key: "/myads", label: "Myads", path: "/myads" },
    { key: "/orders", label: "Orders", path: "/orders" },
    { key: "/profile", label: "Settings", path: "/profile" },
    // Add more menu items as needed
  ];

  const defaultSelectedKey = menuItems.find(
    (item) => item.path === currentPath
  )?.key;
  const userDetail = sessionStorage.getItem("userDetails");
  const fetchingUserDetails = props.fetchingUserDetails;
  if (fetchingUserDetails && !userDetail) {
    return <Loader />;
  }
  return (
    <>  
    <AppErrorBoundary>  
      <Layout>
        {isDisconnected || isConnecting ? (
          <Header
            style={{
              zIndex: 9999,
              padding: 0,
              height: 80,
              top: "0",
              position: "sticky",
              width: "100%",
              background: colorBgContainer,
              boxShadow: "0px 2px 4px rgba(245, 202, 69, 1)",
            }}
          >
            <div className="flex px-2 h-20 fixed top-0 left-0 right-0 z-50 bg-slate-800 flex-row justify-between ">
              <div className="-mt-10">
                <Link to={"/"}>
                  <img src={logo} alt="Logo" height={150} width={150} />
                </Link>
              </div>
              <div className="mt-4 py-1">
                <WalletConnect label="Get Started" />
              </div>
            </div>
          </Header>
        ) : isConnected && userDetail ? (
          <Header
            style={{
              zIndex: 9999,
              padding: 0,
              height: 80,
              top: "0",
              position: "sticky",
              width: "100%",
              background: colorBgContainer,
              boxShadow: "0px 2px 4px rgba(245, 202, 69, 1)",
            }}
          >
            <div className="flex px-2  flex-row justify-between max-sm:hidden max-lg:hidden max-md:hidden">
              <div className="flex gap-2">
                <div className="-mt-10">
                  <Link to={"/"}>
                    <img src={logo} alt="Logo" height={150} width={150} />
                  </Link>
                </div>
                <div>
                  <Button
                    type="text"
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                    }}
                  />
                </div>
              </div>
              <div className="mt-5 max-sm:hidden max-lg:hidden max-md:hidden py-1">
                <WalletConnect />
              </div>
            </div>
            <div className="lg:hidden xl:hidden 2xl:hidden w-full flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="-mt-4">
                  <Link to={"/"}>
                    <img src={logo} alt="Logo" height={100} width={100} />
                  </Link>
                </div>
                <div className="mt-3 mr-2 lg:hidden xl:hidden 2xl:hidden">
                  <WalletConnect />
                </div>
              </div>              
              {/* <div className="-mt-12 ml-5 lg:hidden text-yellow-500 xl:hidden 2xl:hidden min-lg:hidden" onClick={() => goBack()}>
                  <ArrowCircleLeftIcon  fontSize="40px"/>
                </div> */}
            </div>
          </Header>
        ) : isConnected && !userDetail ? (
          <Header
            style={{
              zIndex: 9999,
              padding: 0,
              height: 80,
              top: "0",
              position: "sticky",
              width: "100%",
              background: colorBgContainer,
              boxShadow: "0px 2px 4px rgba(245, 202, 69, 1)",
            }}
          >
            <div className="flex px-2  flex-row justify-between ">
              <div className="-mt-10">
                <Link to={"/"}>
                  <img src={logo} alt="Logo" height={150} width={150} />
                </Link>
              </div>
              <div className="mt-5 py-1">
                <WalletConnect />
              </div>
            </div>
          </Header>
        ) : null}
        <Layout hasSider>
          {isConnected && userDetail ? (
            <div className="max-sm:hidden max-lg:hidden max-md:hidden ">
              <Sider
                style={{ height: "100%", marginTop: 5 }}
                trigger={null}
                theme="light"
                collapsible
                collapsed={collapsed}
              >
                {/* <div className="demo-logo-vertical">
                <Link to={"/"}>
                  <img src={logo} alt="Logo" height={100} width={100} />
                </Link>
              </div> */}
                <Menu
                  theme="light"
                  style={{ height: "78vh", marginTop: 5 }}
                  mode="inline"
                  defaultSelectedKeys={[defaultSelectedKey]}
                  defaultOpenKeys={[defaultSelectedKey]}
                >
                  {/* <Menu.Item key="1" icon={<DashboardIcon />}>
                    <Link to="/"> Dashboard</Link>
                  </Menu.Item> */}
                  <Menu.Item key="/" icon={<CurrencyBitcoinOutlinedIcon />}>
                    <Link to="/">Market</Link>
                  </Menu.Item>
                  <Menu.Item key="/myads" icon={<ContainerOutlined />}>
                    <Link to="/myads">MyAds</Link>
                  </Menu.Item>
                  <Menu.Item key="/orders" icon={<DashboardIcon />}>
                    <Link to="/orders">Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="/profile" icon={<SettingOutlined />}>
                    <Link to="/profile">Settings</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="6" icon={<ContainerOutlined />}>
                <Link to="/support">Help & Support</Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<ContainerOutlined />}>
                <Link to="/refer">Refer</Link>
              </Menu.Item> */}
                </Menu>
                <div className="p-2 flex justify-center">
                  <p className=" text-sm font-mono italic">
                    @Handcrafted By DP2p
                  </p>
                </div>
              </Sider>
            </div>
          ) : null}
          <Content
            style={{
              // margin: "24px 16px",
              // padding: 4,
              // minHeight: 280,
              // height: "85vh",
              //  marginTop: 80,
              height: `${isConnected && userDetail ? "85vh" : "100%"}`,
              background: colorBgContainer,
              boxShadow: "0px 2px 4px rgba(245, 202, 69, 1)",
            }}
            className={`shadow-md  shadow-yellow-500 overflow-y-auto scrollbar-none mb-0  ${
              isConnected && userDetail ? "lg:m-1 lg:p-2" : "m-0 p-0"
            } `}
          >
            {" "}
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route element={<Market />} path="/" exact />

                  <Route path="/profile" element={<Profile />} />
                  {/* <Route path="/market" element={<Market />} /> */}
                  <Route path="/profileEdit" element={<ProfileEdit />} />
                  <Route path="/orders" element={<Order />} />
                  <Route path="/order/:id" element={<OrderDetailsById />} />
                  <Route path="/refer" element={<Refer />} />
                  <Route path="/help" element={<HelpSupport />} />
                  <Route path="/newTicket" element={<CreateNewTicket />} />
                  <Route path="/payment" element={<Payment />} />
                  <Route path="/addPayment" element={<AddPaymentMethod />} />
                  <Route path="/upgrade" element={<Upgrade />} />
                  <Route path="/myads" element={<UpCommingIcoList />} />
                  <Route path="/myadsCreate" element={<CreateNewAd />} />
                  <Route path="/orderchat/:id" element={<OrderChat />} />
                  <Route path="/updateAds/:id" element={<UpdateYourCoin />} />
                  <Route path="/publicProfile" element={<PublicProfile />} />
                  <Route path="/feedback/:id" element={<Feedback />} />
                
                  <Route path="/details/:id" element={<ProfileDetailsById />} />
                </Route>
                <Route element={<SignIn />} path="/signin" />
                <Route element={<ContactUs />} path="/contact" />
                <Route element={<Privacy />} path="/privacy" />
                <Route element={<Terms />} path="/terms" />
                <Route
                  element={<ProjectList />}
                  path="/list-your-project-token"
                />
                <Route path="/referral-program" element={<Referal />} />
              </Routes>
            </Suspense>
          </Content>
        </Layout>
        {isConnected && userDetail ? (
          <Footer
            className="lg:hidden xl:hidden 2xl:hidden "
            theme="light"
            style={{ position: "sticky", bottom: "0", zIndex: "9999"}}
          >
            <FixedBottomNavigation />
            {/* <div className="w-full  bottom-0 left-0 right-0">
              <Menu
                theme="light"
                className="flex justify-center"
                mode="horizontal"
                defaultSelectedKeys={[defaultSelectedKey]}
                defaultOpenKeys={[defaultSelectedKey]}
              >                
                <Menu.Item key="/" icon={<CurrencyBitcoinOutlinedIcon />}>
                  <Link to="/">P2P</Link>
                </Menu.Item>
                <Menu.Item key="/myads" icon={<ContainerOutlined />}>
                  <Link to="/myads">Ads</Link>
                </Menu.Item>
                <Menu.Item key="/orders" icon={<DashboardIcon />}>
                  <Link to="/orders">Order</Link>
                </Menu.Item>
                <Menu.Item key="/profile" icon={<Settings />}>
                  <Link to="/profile">Profile</Link>
                </Menu.Item>                
              </Menu>
            </div> */}
          </Footer>
        ) : isDisconnected || isConnecting ? (
          <Footer>
            <Footers />
          </Footer>
        ) : null}
      </Layout>
      </AppErrorBoundary>  
    </>
  );
}

export default MainApp;
