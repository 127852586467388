import React, { useEffect, useState } from "react";
import { getOrderDetailsById } from "./OrderAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useParams } from "react-router-dom";
import OrderSteps from "./OrderSteps";
import { Card, Typography } from "@mui/material";
import { useAccount, useNetwork } from "wagmi";
import {
  TbArrowBigRightLinesFilled,
  TbArrowBigLeftLinesFilled,
} from "react-icons/tb";
import { PulseLoader } from "react-spinners";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheckCircle,
  faArrowUpRightFromSquare,
  faPaperPlane,
  faPaperclip,
  faRefresh,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import MessageIcon from "@mui/icons-material/Message";
import {
  CopyFilled,
  CheckCircleOutlined,
  CheckOutlined,
  MessageFilled,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ShoppingOutlined,
  TransactionOutlined,
} from "@ant-design/icons";
import { blue, green, orange, purple, red, yellow } from "@mui/material/colors";
import { Link } from "react-router-dom";
import moment from "moment";
import { Form, Input, Alert, Spin, Modal, Tabs, message, Button } from "antd";
import OrderChat from "./OrderChat";
import TabPane from "antd/es/tabs/TabPane";
import axios from "axios";
import { API_URL } from "../../Config/Auth";
import { useSocket } from "../../Screen/socketProvider";
import p2pContract from "./Contracts/contracts.json";
import p2pContract2 from "./Contracts/contract2.json";
import tokencontract from "./Contracts/tokenContract.json";
import { toast } from "react-hot-toast";
import Loader from "../../Components/Loader";
import axiosClient from "../../service/axios";

const OrderDetailsById = (props) => {
  const params = useParams();
  const account = useAccount();
  const [activeTab, setActiveTab] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState("");
  let socket = useSocket();
  const [showTC, setShowTC] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const network = useNetwork();
  const [refreshed, setRefreshed] = useState(true);
  const [review, setReview] = useState("");
  const [contractAddr, setContractAddr] = useState("");
  const [contractAddr2, setContractAddr2] = useState("");
  const [rating, setRating] = useState(0);
  const [disputeLoading, setDisputeLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    props.getOrderDetailsById(params.id);
  }, []);

  const details = props.orderDetails && props.orderDetails.data;
  
  useEffect(() => {
    if (details) {
      setSelectedPaymentMethod(details.buyerPaymentMethod[0]);
      setReview(details?.buyer.wallet === account.address ? details.review?.seller_review : details.review?.buyer_review);
    }

  }, [details]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const handleFormSubmit = (e) => {
    // e.preventDefault();
    setShowDialog(false);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setTransactionId("");
    setFile("");
  };

  const handleRating = (rate) => {
    setRating(rate);
  };


  function successMessage(explorer, transactionHash) {
    const element = <FontAwesomeIcon icon={faArrowUpRightFromSquare} />;
    const explorerLink = `https://${explorer}.com/tx/${transactionHash}`;
    console.log(explorerLink);
    toast.success(
      <div>
        Transaction success!
        <br />
        <a href={explorerLink} target="_blank" rel="noopener noreferrer">
          View on {explorer} {element}
        </a>
      </div>,
      {
        duration: 10000,
        position: "top-right",
        reverseOrder: false,
      }
    );
  }
  const handleFeedback = async () => {
    try {
      setLoading(true);
      let payload = {
        orderId: details._id,
      };
      if (account.address === details.buyer.wallet) {
        payload.sellerId = details.seller._id;
        payload.buyerId = details.buyer._id;
        payload.seller_rating = rating;
        payload.seller_review = review;
      }

      if (account.address === details.seller?.wallet) {
        payload.sellerId = details.seller._id;
        payload.buyerId = details.buyer._id;
        payload.buyer_rating = rating;
        payload.buyer_review = review;
      }

      const res = await axiosClient.post(`${API_URL}/review`, payload);
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        window.location.reload();
        setRefreshed(!refreshed);
      } else {
        message.error(res.data.message);
        setLoading(false);
        setRefreshed(!refreshed);
      }
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };
  const releaseCrypto = async (adId, recipientAddr, amount, data) => {
    try {
      if (!account.isConnected) {
        message.error("Please connect your wallet!");
        setLoading(false);
        return;
      }
      setLoading(true);
      const Web3 = require("web3");
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      }
      const web3 = new Web3(window.ethereum);

      const chain = network?.chain?.id.toString();
      let explorer;
      if (chain === "1") {
        explorer = "etherscan";
      } else if (chain === "56") {
        explorer = "bscscan";
      } else if (chain === "137") {
        explorer = "polygonscan";
      }

      const contract = new web3.eth.Contract(p2pContract.ABI, contractAddr);
      const adInfo = await contract.methods.ads(adId).call()
      const tokenAddr = adInfo?.token;
      const tokenContract = new web3.eth.Contract(
        tokencontract.ABI,
        tokenAddr
      );
      const decimals = await tokenContract.methods.decimals().call();
      let transferAmount = 0;
      if (decimals < 18) {
        const multiplier = 10 ** (18 - decimals);
        const amountInWei = await web3.utils.toWei(
          amount.toString(),
          "ether"
        );
        transferAmount = amountInWei / multiplier;
      } else {
        transferAmount = web3.utils.toWei(amount.toString(), "ether");
      }
      if (adInfo?.seller === account.address) {
        const block = await web3.eth.getBlock("latest");
        const gasPrice = await web3.eth.getGasPrice();
        const gasPriceAdjusted = gasPrice * 2;
        const gasLimit = await contract.methods
          .transferFunds(transferAmount, adId, recipientAddr)
          .estimateGas({ from: account.address, gasPrice: gasPriceAdjusted });
        let size = 0;
        size =
          block.size < 400000
            ? block.size
            : Math.ceil(block.size / parseInt(block.difficulty));
        const gasLimitAdjusted = Math.max(size, gasLimit);
        const tx = await contract.methods
          .transferFunds(transferAmount, adId, recipientAddr)
          .send({
            from: account.address,
            gasPrice: gasPriceAdjusted,
            gas: gasLimitAdjusted,
          });
        message.success("Transaction successful");
        data.url = tx.transactionHash;
        setTimeout(() => {
          // message(
          //   <CustomToastWithLink
          //     explorer={explorer}
          //     transactionHash={tx.transactionHash}
          //     element={element}
          //     message="Transaction successful"
          //   />,
          //   "success"
          // );
          successMessage(explorer, tx.transactionHash);
        }, 3000); // 3000 milliseconds = 5 seconds
        updateOrderStatus(data);
        props.getOrderDetailsById(params.id);
        return;
      } else {
        message.error("You are not the owner of this AD!");
        setLoading(false);
        return;
      }
    } catch (error) {
      message.error("Transaction failed!" + error.message);
      setLoading(false);
      return;
    }
  };


  const reviewButton = (details) => {
    if (details.review) {
      if (details.buyer.wallet === account.address) {
        if (details.review.seller_review === null || details.review.seller_review === "" || details.review.seller_rating === null || details.review.seller_rating === 0) {
          return true;
        }
        else {
          return false;
        }
      }
      if (details.seller.wallet === account.address) {
        if (details.review.buyer_review === null || details.review.buyer_review === "" || details.review.buyer_rating === null || details.review.buyer_rating === 0) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }



    }
    else {
      return false;
    }
  }

  const releaseCryptoToBuyer = async (
    details,
    adId,
    recipientAddr,
    amount,
    data
  ) => {
    console.log(details);
    try {
      if (!account.isConnected) {
        message.error("Please connect your wallet!");
        setLoading(false);
        return;
      }
      setLoading(true);
      const Web3 = require("web3");
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
      }
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(p2pContract2.ABI, contractAddr2);
      const adInfo = await contract.methods.ads(adId).call();
      console.log(adInfo, "adInfo");

      const chain = network?.chain?.id.toString();
      let explorer;
      if (chain === "1") {
        explorer = "etherscan";
      } else if (chain === "56") {
        explorer = "bscscan";
      } else if (chain === "137") {
        explorer = "polygonscan";
      }

      if (adInfo?.seller === account.address) {
        const block = await web3.eth.getBlock("latest");
        const gasPrice = await web3.eth.getGasPrice();
        const gasPriceAdjusted = gasPrice * 2; // Double the gas price

        // Adjust gasLimit dynamically based on network congestion
        const gasLimit = await contract.methods
          .releaseCrypto(adId)
          .estimateGas({ from: account.address, gasPrice: gasPriceAdjusted });
        let size = 0;
        size =
          block.size < 400000
            ? block.size
            : Math.ceil(block.size / parseInt(block.difficulty));
        const gasLimitAdjusted = Math.max(size, gasLimit);
        const tx = await contract.methods.releaseCrypto(adId).send({
          from: account.address,
          gasPrice: gasPriceAdjusted,
          gas: gasLimitAdjusted,
        });
        data.url = tx.transactionHash;

        message.success("Transaction successful");
        setTimeout(() => {
          // message(
          //   <CustomToastWithLink
          //     explorer={explorer}
          //     transactionHash={tx.transactionHash}
          //     element={element}
          //     message="Transaction successful"
          //   />,
          //   "success"
          // );
          successMessage(explorer, tx.transactionHash);
        }, 3000); // 3000 milliseconds = 5 seconds
        updateOrderStatus(data);
        props.getOrderDetailsById(params.id);
        return;
      } else {
        message.error("You are not the owner of this AD!");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log(error);
      message.error("Transaction failed!");
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    const chain = network?.chain?.id.toString();
    console.log(chain, "chain");
    console.log(chain === "1", chain === "56", chain === "137");
    if (chain === "1") {
      setContractAddr(p2pContract.EthereumMainnetAddress);
      setContractAddr2(p2pContract2.EthereumMainnetAddress);
    } else if (chain === "56") {
      setContractAddr(p2pContract.BSCMainnetAddress);
      setContractAddr2(p2pContract2.BSCMainnetAddress);
    } else if (chain === "137") {
      setContractAddr(p2pContract.PolygonMainnetAddress);
      setContractAddr2(p2pContract2.PolygonMainnetAddress);
    }
    console.log("Contrac-1:", contractAddr, "contract-2:", contractAddr2);
  }, [network]);
  const raiseDispute = async (data) => {
    try {
      setDisputeLoading(true);
      let res = await axiosClient.put(`${API_URL}/orders/${params.id}`, data);
      if (res.data.success) {
        message.success("Order Updated Successfully");
        emitOrderStatusUpdated();
        setDisputeLoading(false);
        setRefreshed(false);
        props.getOrderDetailsById(params.id);
      } else {
        message.error(res.data.messages);
        setDisputeLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
      setDisputeLoading(false);
    }
  };
  const handleFileChange = async (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    let res = await axiosClient.post(`${API_URL}/upload/file`, formData);
    if (res.data.success) {
      setFile(res.data.file.filename);
      message.success("File uploaded successfully");
    }
  };

  const markAsPaid = async (data) => {
    try {
      setLoading(true);
      if (transactionId === "" || transactionId === null) {
        message.error(
          "Please upload payment screenshot or enter transactionId",
          5
        );
        setLoading(false);
        return;
      }
      let message = {
        message: `I have paid for the order via ${selectedPaymentMethod !== null
            ? selectedPaymentMethod.paymentMethod.name
            : details.buyerPaymentMethod[0].paymentMethod.name
          } and attached the transactionId is ${transactionId}. Please verify and release the crypto.`,
        sender_id: account.address,
        buyer_id: details.buyer._id,
        seller_id: details.seller._id,
        order_id: details._id,
        room_id: details.orderId,
        file: file,
      };
      socket && socket.emit("sendMessage", message);
      let res = await axiosClient.put(`${API_URL}/orders/${params.id}`, data);
      if (res.data.success) {
        message.success("Order Updated Successfully");
        window.location.reload();
        emitOrderStatusUpdated();
        setFile(null);
        setLoading(false);
        setShowTC(false);
        setShowDialog(false);
        props.getOrderDetailsById(params.id);
      } else {
        message.error(res.data.messages);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
      window.location.reload();
      setLoading(false);
      setShowTC(false);
    }
  };

  const emitOrderStatusUpdated = () => {
    socket && socket.emit("refresh", { roomId: details && details.orderId });
  };

  const updateOrderStatus = async (data) => {
    try {
      if (data.paymentStatus === "paid") {
        setShowDialog(true);
        return;
      }

      let res = await axiosClient.put(`${API_URL}/orders/${params.id}`, data);
      if (res.data.success) {
        message.success("Order Updated Successfully");
        emitOrderStatusUpdated();
        props.getOrderDetailsById(params.id);
      } else {
        message.error(res.data.messages);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (props.fetchingOrderDetails || !details) {
    return <Loader />;
  }
  return (
    <>
      <div className="flex flex-col space-y-2">
        {/* <div className="flex lg:hidden">
            <TbArrowBigLeftLinesFilled size={35} onClick={() => goBack()} />
          </div> */}
        <div className="flex bg-gradient-to-l from-emerald-600 px-2 flex-row justify-between max-sm:flex-col max-md:flex-col">
          <div className="flex flex-row justify-start my-2 gap-2">
            <TbArrowBigLeftLinesFilled size={35} onClick={() => goBack()} />

            {details && details.buyer.wallet === account.address ? (
              <button className=" max-sm:w-10 max-sm:h-7 max-md:h-7 max-md:w-10 bg-yellow-500   p-1 text-sm font-bold text-white rounded-lg">
                Buy
              </button>
            ) : (
              <button className=" max-sm:w-10 max-sm:h-7 max-md:h-7 max-md:w-10 bg-orange-600 p-1 text-sm font-bold text-white rounded-lg">
                Sell
              </button>
            )}

            {/* <p className="text-lg font-bold text-black ">
              {details &&
              details.buyer &&
              details.buyer.wallet === account.address
                ? "Buy"
                : "Sell"}
            </p> */}
            <p className="text-lg font-bold text-black ">
              {details &&
                details.ad &&
                details.ad.currency &&
                details.ad.currency.name}
            </p>
            <p className=" text-lg font-bold text-yellow-500 ">
              {details && details.buyer.wallet === account.address
                ? "from"
                : "to"}
            </p>
            <p className=" text-lg font-bold text-black ">
              {details && details.buyer.wallet === account.address
                ? details && details.seller.name
                : details && details.buyer.name}
            </p>
            {details && details.orderStatus !== "cancelled" && (
              <button
                className="max-sm:w-10 max-sm:h-7 max-md:h-7 max-md:w-10 bg-yellow-500   p-1 text-sm font-bold text-white rounded-lg"
                onClick={(e) => window.location.reload()}
              >
                <FontAwesomeIcon icon={faRefresh}></FontAwesomeIcon>
              </button>
            )}
          </div>
          <div className="flex flex-col justify-start gap-2">
            <p className="mb-0 font-bold">
              Order Number :&nbsp;
              <span>{details && details.orderId.toUpperCase()}</span>
            </p>
            <p className="mb-0 font-bold">
              Time created :&nbsp;
              <span>
                {moment(details && details.createdAt).format(
                  "HH:mm, DD MMM YYYY"
                )}
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-between gap-1 w-full p-2 max-sm:flex-col max-md:flex-col">
          <div className="lg:w-2/3 flex flex-col space-y-3">
            <div className=" border shadow-sm shadow-yellow-500 p-2">
              <OrderSteps details={details} />
            </div>
            <div className=" border shadow-sm shadow-yellow-500 p-2">
              <Typography
                gutterBottom
                variant="h5"
                component="div"
              // className="bg-gradient-to-r from-blue-950 to-orange-600 text-white font-bold"
              >
                Order Info.
              </Typography>
              <div className="flex flex-row w-full mx-1">
                <div>
                  <p className=" text-lg font-bold">
                    {details && details.seller.wallet === account.address
                      ? "Buyer"
                      : "Seller"}
                    :
                  </p>
                </div>
                <div className="w-5/6 flex flex-row justify-start ml-1 mt-1">
                  <p className="truncate w-100">
                    {details && details.buyer.wallet === account.address
                      ? details && details.seller.wallet
                      : details && details.buyer.wallet}
                  </p>
                  <div
                    className="-mt-1 ml-1 cursor-pointer"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${details && details.buyer.wallet === account.address
                          ? details.seller.wallet
                          : details.buyer.wallet
                        }`
                      )
                    }
                  >
                    <CopyFilled />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 my-2 mx-1 max-sm:grid-cols-2 max-md:grid-cols-2">
                <div className="flex flex-col">
                  <p className=" text-sm ">Price</p>
                  <p className=" text-lg font-bold text-blue-600">
                    {details && details.ad.price.toFixed(3)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className=" text-sm">Total Amount</p>
                  <p className="text-lg font-bold ">
                    {details && details.amount}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className=" text-sm ">Crypto Amount</p>
                  <p className="text-lg font-bold ">
                    {details && details.amount / details.ad.price}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="text-sm ">Payment Status</p>
                  {details && details.paymentStatus === "paid" ? (
                    <p className=" text-green-400 text-lg font-bold">Paid</p>
                  ) : (
                    <p className="text-red-400 text-lg font-bold">Unpaid</p>
                  )}
                </div>
                <div className="flex flex-col">
                  <p className=" text-sm ">Order Status</p>
                  <div className="flex flex-row">
                    <div className="">
                      {details && details.orderStatus === "completed" ? (
                        <CheckCircleOutlined className="completed-icon" />
                      ) : details && details.orderStatus === "approved" ? (
                        <CheckOutlined className="approved-icon" />
                      ) : details && details.orderStatus === "dispute" ? (
                        <ExclamationCircleOutlined className="dispute-icon" />
                      ) : details && details.orderStatus === "cancelled" ? (
                        <CloseCircleOutlined className="cancel-icon" />
                      ) : null}
                    </div>
                    <p className="text-lg font-bold">
                      {details && details.orderStatus}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {details &&
              details.orderStatus === "completed" &&
              details.paymentStatus === "paid" && (
                <div className="w-full border shadow-sm shadow-yellow-500 rounded-md bg-transparent px-3 py-2">
                  <p className=" text-lg font-bold">Leave your feedback</p>
                  <div className="flex flex-col items-start justify-between">
                    <span className="pt-3">Rate your experience</span>
                    <div>
                      <Rating
                        readonly={!reviewButton(details)}
                        initialValue={
                          details && details.buyer.wallet === account.address ? details && details.review?.seller_rating : details && details.review?.buyer_rating
                        }
                        SVGstyle={{ display: "inline" }}
                        className="pt-1"
                        onClick={(e) => handleRating(e)}
                        tooltipArray={[
                          "Bad",
                          "Poor",
                          "Average",
                          "Good",
                          "Excellent",
                        ]}
                      />
                    </div>
                  </div>
                  <div className=" flex flex-col">
                    <span className="pt-3">Write your feedback here</span>
                    <textarea
                      rows={5}
                      required
                      className="border mx-0 my-2"
                      placeholder="Write your message here"
                      disabled={!reviewButton(details)}
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                  </div>
                  {reviewButton(details)&& (
                      <div className="flex  m-2">
                        <button
                          className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
                          onClick={(e) => handleFeedback(e)}
                        >
                          {loading ? (
                            <PulseLoader color="#fff" className="mt-1" />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    )}
                </div>
              )}

            <div className="flex mb-2 mt-5 px-3 w-full  justify-between gap-2">
              {details &&
                details.buyer.wallet === account.address &&
                details.orderStatus === "approved" &&
                details.paymentStatus === "unpaid" && (
                  <button
                    className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
                    onClick={(e) =>
                      updateOrderStatus({
                        paymentStatus: "paid",
                      })
                    }
                  >
                    {loading ? (
                      <PulseLoader color="#fff" className="mt-1" />
                    ) : (
                      "Mark as paid"
                    )}
                  </button>
                )}
              {details &&
                details.buyer.wallet === account.address &&
                details.orderStatus === "approved" &&
                details.paymentStatus === "paid" && (
                  <button
                    disabled={disputeLoading}
                    className="bg-orange-500 w-full p-2 text-white rounded-full text-lg border border-orange-600 self-center hover:border-orange-800 hover:text-orange-800"
                    onClick={(e) =>
                      raiseDispute({
                        orderStatus: "dispute",
                      })
                    }
                  >
                    {disputeLoading ? (
                      <PulseLoader color="#fff" className="mt-1" />
                    ) : (
                      "Raise Dispute"
                    )}
                  </button>
                )}
              {/* <div className="flex gap-3"> */}
              {details &&
                details.buyer.wallet !== account.address &&
                details.orderStatus === "approved" &&
                details.paymentStatus === "paid" && (
                  <button
                    disabled={disputeLoading}
                    className="bg-orange-500 w-1/2 p-2 text-white rounded-full text-lg border border-orange-600 self-center hover:border-orange-800 hover:text-orange-800"
                    onClick={(e) =>
                      raiseDispute({
                        orderStatus: "dispute",
                      })
                    }
                  >
                    {disputeLoading ? (
                      <PulseLoader color="#fff" className="mt-1" />
                    ) : (
                      "Raise Dispute"
                    )}
                  </button>
                )}
              {details &&
                details.buyer.wallet !== account.address &&
                details.orderStatus === "approved" &&
                details.paymentStatus === "paid" && (
                  <button
                    className="bg-green-500 w-1/2 p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
                    disabled={loading}
                    onClick={(e) => {
                      if (
                        details.buyer.wallet !== account.address &&
                        details.ad.user !== account.address
                      ) {
                        releaseCryptoToBuyer(
                          details,
                          details.ad.adId,
                          details.buyer.wallet === account.address
                            ? details.seller.wallet
                            : details.buyer.wallet,
                          details.amount / details.ad.price,
                          {
                            orderStatus: "completed",
                          }
                        );
                      }
                      if (
                        details.seller.wallet === account.address &&
                        details.ad.user === account.address
                      ) {
                        releaseCrypto(
                          details.ad.adId,
                          details.buyer.wallet === account.address
                            ? details.seller.wallet
                            : details.buyer.wallet,
                          details.amount / details.ad.price,
                          {
                            orderStatus: "completed",
                          }
                        );
                      }
                    }}
                  >
                    {loading ? (
                      <PulseLoader color="#fff" className="mt-1" />
                    ) : (
                      "Release Cryptos"
                    )}
                  </button>
                )}
              {/* </div> */}
            </div>
            {details && details.orderStatus !== "waiting" && (
              <>
                <Modal
                  title="Submit Payment Screenshot"
                  open={showDialog}
                  // onOk={handleFormSubmit}
                  onCancel={handleCloseDialog}
                  footer={null}
                >
                  <Form layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item
                      label="Screenshot"
                      // name="screenshot"
                      onChange={handleFileChange}
                      rules={[
                        {
                          required: true,
                          message: "Upload Your Screenshot!",
                        },
                      ]}
                    >
                      <Input placeholder="Upload" type="file" />
                    </Form.Item>
                    <Form.Item
                      label="TransactionId"
                      //  name="transactionId"
                      onChange={(e) => setTransactionId(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: "Enter Your TransactionId!",
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                    <div className="flex w-full gap-3">
                      {transactionId && transactionId !== "" && (
                        <button
                          // variant="primary"
                          //  type="primary"
                          htmlType="submit"
                          onClick={(e) => {
                            setShowTC(true);
                          }}
                          className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
                        >
                          Submit
                        </button>
                      )}
                      <button
                        className="bg-orange-500 w-full p-2 text-white rounded-full text-lg border border-orange-600 self-center hover:border-orange-800 hover:text-orange-800"
                        onClick={handleCloseDialog}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </Modal>

                <Modal
                  title="Aggrement"
                  onCancel={(e) => {
                    setShowTC(false);
                  }}
                  open={showTC}
                  footer={null}
                >
                  <h5 className="mb-3">
                    Please read the terms and conditions carefully
                  </h5>
                  <Form onFinish={handleFormSubmit}>
                    <div>
                      <p>
                        Dated on :
                        {moment(new Date()).format("DD/MM/YYYY")}
                        {/* {new Date().toLocaleString("en-us", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })} */}
                      </p>
                    </div>
                    <p>
                      I have paid for the order via
                      {selectedPaymentMethod && selectedPaymentMethod !== null
                        ? selectedPaymentMethod.paymentMethod.name
                        : details.buyerPaymentMethod[0].paymentMethod.name}
                      and transactionId is
                      <span className="text-black" style={{ fontSize: "1rem" }}>
                        {transactionId}
                      </span>
                      . Please verify and release the crypto.
                    </p>
                    <p className="font-weight-normal">
                      Each Named Lender acknowledges that transfer and payment
                      of Consideration in the form of UPI's is only complete
                      once the Cryptocurrency has been successfully delivered to
                      the buyer's Upi and the Seller has accepted the
                      Cryptocurrency amount as Consideration as Closing. All the
                      trasanction will be done in Upi id's. We dont have any
                      payment gateway for trasanction. After buying the
                      cryptocurrency, buyer will be sole & legal ownership of
                      cryptocurrency. Buyer donot claim any legal notice to
                      seller.
                    </p>
                    <div className="flex flex-col space-y-3 mt-3">
                      <button
                        // type="primary"
                        htmlType="submit"
                        onClick={(e) =>
                          markAsPaid({
                            paymentStatus: "paid",
                          })
                        }
                        className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
                      >
                        {" "}
                        {loading ? (
                          <PulseLoader color="#fff" size={10} />
                        ) : (
                          "Agree & Submit"
                        )}
                      </button>
                      <button
                        type="primary"
                        htmlType="submit"
                        onClick={(e) => {
                          setShowTC(false);
                        }}
                        className="bg-yellow-500 w-full p-2 text-white rounded-full text-lg border border-yellow-600 self-center hover:border-yellow-800 hover:text-yellow-800"
                      >
                        <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                        {loading ? (
                          <PulseLoader color="#fff" size={10} />
                        ) : (
                          "Download as PDF"
                        )}
                      </button>
                      <button
                        // type="submit"
                        onClick={(e) => {
                          setShowTC(false);
                          setTransactionId("");
                          setFile("");
                        }}
                        className="bg-orange-500 w-full p-2 text-white rounded-full text-lg border border-orange-600 self-center hover:border-orange-800 hover:text-orange-800"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </Modal>
              </>
            )}
          </div>

          {details && details.orderStatus !== "waiting" && (
            <div className="lg:w-1/3 p-0 rounded-lg flex border shadow-sm  shadow-yellow-500 max-md:hidden max-sm:hidden">
              <OrderChat details={details} />
            </div>
          )}
        </div>
        {details && details.orderStatus !== "waiting" && (
          <div className=" sticky flex justify-end p-2 right-0 bottom-0 lg:hidden md:hidden">
            <Link to={`/orderchat/${params.id}`}>
              <button className="flex gap-1 p-2  rounded-full bg-zinc-300 -ml-12">
                <MessageIcon fontSize="large" sx={{ color: purple[500] }} />
                {/* <p className="text-sm font-bold text-teal-700 ">
                    {details && details.buyer.wallet === account.address
                      ? details && details.seller.name
                      : details && details.buyer.name}
                  </p> */}
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ order }) => ({
  orderDetails: order.orderDetails,
  fetchingOrderDetails: order.fetchingOrderDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getOrderDetailsById }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsById);
