import React from "react";
import load from "../../src/assets/FinallogoD1.png";
import { Spin } from "antd";

function Loader() {
  return (
    <div className="grid place-content-center h-screen top-10 bottom-10 mx-5 border rounded-md p-5 shadow-sm shadow-yellow-500">
      {/* <Spin tip="Loading..." size="large"> */}
      <img src={load} height={100} width={100} className="animate-bounce" />
      {/* <p className=" text-yellow-500 text-lg">Please refresh your browser</p> */}
      {/* </Spin> */}
    </div>
  );
}

export default Loader;
