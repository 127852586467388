import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { API_URL } from '../Config/Auth';

const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
// Format date to HH:mm, DD MMM YYYY
export const formatDate = (date) => {
    return moment(date).format('HH:mm, DD MMM YYYY');
}


export const fetchcurrencyList = async () => {
    try {
        let response=await axios .get(`${API_URL}/crypto`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
        return response.data.crypto;
    }
    catch (error) {
        throw error;
    }
};


export const getPaymentMethodList = async () => {
    try {
        let res=await axios .get(`${API_URL}/paymentmethods`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
        return res.data.payment;
    } catch (error) {
        throw error;
    }
}


export const showToast = (message, type) => {
    switch (type) {
        case 'success':
            toast.dismiss();
            return toast.success(message, {
                position: 'top-right'
            });
        case 'error':
            
            return toast.error(message, {
                position: 'top-right',
                timeout: 100000000000,
                className:'z-index-1000000'
            });
        case 'loading':
            toast.dismiss();
            return toast.loading(message, {
                position: 'top-right'
            });
        case 'info':
            toast.dismiss();
           return toast.error(message, {
                position: 'top-right'
            });    
        default:
            toast.dismiss();
            return toast(message, {
                position: 'top-right'
            })
    }
}
