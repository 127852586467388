import styled from "styled-components";

const ValidationError = styled.div`
  color: tomato;
  font-weight: 600;
  padding: 0.1rem 0;
  /* margin: 0.5rem 0.2rem; */
  /* margin-left: "40px";
  margin-bottom: "20px"; */
  display: "flex";
  border-radius: 0.1rem;
`;
export default ValidationError;
