import { combineReducers } from "redux";
//import { LOGOUT } from "../Containers/Auth/AuthTypes";
/**
 *  All of application reducers import goes here...
 */
import {authReducer} from '../Container/Auth/AuthReducer'
import { marketReducer } from "../Container/Market/MarketReducer";
import { orderReducer } from "../Container/Order/OrderReducer";
import { settingReducer } from "../Container/Settings/SettingReducer";
const appReducer = combineReducers({
  auth: authReducer,
  market:marketReducer,
  order : orderReducer,
  setting: settingReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    sessionStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
