import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Paper from "@mui/material/Paper";
import { Link, useLocation } from "react-router-dom";

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <div>
      <BottomNavigation
        showLabels
        value={currentPath}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className=" bg-slate-300"
      //  style={{ backgroundColor: "silver", color: "#eab308" }}
      >
        <BottomNavigationAction
          label="P2P"
          key={2}
          icon={<DashboardIcon />}
          component={Link}
          to="/"
        />
        <BottomNavigationAction
          label="Ads"
          key={3}
          icon={<ArchiveIcon />}
          component={Link}
          to="/myads"
        />
        <BottomNavigationAction
          label="Order"
          key={4}
          icon={<FavoriteIcon />}
          component={Link}
          to="/orders"
        />
        <BottomNavigationAction
          label="Profile"
          key={5}
          icon={<AccountCircleIcon />}
          component={Link}
          to="/profile"
        />
      </BottomNavigation>
    </div>
  );
}
