import * as types from "./MarketActionType";
const initialState = {
  fetchingMarketListSell: false,
  fetchingMarketListSellError: false,
  marketListSell: {},

  fetchingMarketListBuy: false,
  fetchingMarketListBuyError: false,
  marketListBuy: {},

  fetchingAdsDetails: false,
  fetchingAdsDetailsError: false,
  adsDetails: {},
  
};
export const marketReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MARKET_LIST_SELL_REQUEST:
      return { ...state, fetchingMarketListSell: true };
    case types.MARKET_LIST_SELL_SUCCESS:
      return {
        ...state,
        fetchingMarketListSell: false,
        marketListSell: action.payload,
      };

    case types.MARKET_LIST_SELL_FAILURE:
      return {
        ...state,
        fetchingMarketListSell: false,
        fetchingMarketListSellError: true,
      };
      case types.MARKET_LIST_BUY_REQUEST:
        return { ...state, fetchingMarketListBuy: true };
      case types.MARKET_LIST_BUY_SUCCESS:
        return {
          ...state,
          fetchingMarketListBuy: false,
          marketListBuy: action.payload,
        };
  
      case types.MARKET_LIST_BUY_FAILURE:
        return {
          ...state,
          fetchingMarketListBuy: false,
          fetchingMarketListBuyError: true,
        };

        case types.GET_ADS_DETAILS_BY_ID_REQUEST:
      return { ...state, fetchingAdsDetails: true };
    case types.GET_ADS_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingAdsDetails: false,
        adsDetails: action.payload,
      };

    case types.GET_ADS_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        fetchingAdsDetails: false,
        fetchingAdsDetailsError: true,
      };

    default:
      return state;
  }
  return state;
};
