// import "@rainbow-me/rainbowkit/styles.css";
// import { lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
// import { configureChains, createClient, WagmiConfig } from "wagmi";
// import {
//   mainnet,
//   polygon,
//   // goerli,
//   bsc,
//   // bscTestnet,
//   // polygonMumbai,
// } from "wagmi/chains";
// import { publicProvider } from "wagmi/providers/public";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
// import { connectorsForWallets } from "@rainbow-me/rainbowkit";
// import {
//   injectedWallet,
//   rainbowWallet,
//   metaMaskWallet,
//   coinbaseWallet,
//   walletConnectWallet,
//   trustWallet,
//   omniWallet,
//   ledgerWallet,
// } from "@rainbow-me/rainbowkit/wallets";

// const { chains, provider } = configureChains(
//   [
//     mainnet,
//     // goerli,
//     polygon,
//     // polygonMumbai,
//     bsc,
//     // bscTestnet,
//   ],
//   [publicProvider()]
// );
// const projectId = '331afab9d25e6a69aedae6619a61faf1';
// const connectors = connectorsForWallets([
//   {
//     groupName: "Recommended",
//     wallets: [
//       injectedWallet({ projectId,chains }),
//       rainbowWallet({ projectId,chains }),
//       metaMaskWallet({ projectId,chains }),
//       coinbaseWallet({ projectId,chains }),
//       walletConnectWallet({ projectId,chains }),
//     ],
//   },
//   {
//     groupName: "Others",
//     wallets: [
//       trustWallet({ projectId,chains }),
//       omniWallet({ projectId,chains }),
//       ledgerWallet({ projectId,chains }),
//     ],
//   },
// ]);

// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors,
//   provider,
// });

// function WalletConnect(props) {
//   return (
//     <WagmiConfig client={wagmiClient}>
//       <RainbowKitProvider
//         theme={lightTheme({
//           accentColor: "#EAB308",
//           accentColorForeground: "white",
//           borderRadius: "medium",
//         })}
//         chains={chains}
//       >
//         <ConnectButton  label={props.label}/>
//       </RainbowKitProvider>
//     </WagmiConfig>
//   );
// }

// export default WalletConnect;

import { Web3Button } from '@web3modal/react'

function WalletConnect(props) {
  return <Web3Button balance='show' label={props.label}/>
}
export default WalletConnect;