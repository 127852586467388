"use client";
import React from "react";
import { motion } from "framer-motion";
const AnimatedDiv = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 200,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        type: "spring",
        duration: 1,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedDiv;