export const GET_ALL_ORDER_REQUEST = "GET_ALL_ORDER_REQUEST";
export const GET_ALL_ORDER_SUCCESS = "GET_ALL_ORDER_SUCCESS";
export const GET_ALL_ORDER_FAILURE = "GET_ALL_ORDER_FAILURE";

export const GET_ORDER_DETAILS_BY_ID_REQUEST = "GET_ORDER_DETAILS_BY_ID_REQUEST";
export const GET_ORDER_DETAILS_BY_ID_SUCCESS = "GET_ORDER_DETAILS_BY_ID_SUCCESS";
export const GET_ORDER_DETAILS_BY_ID_FAILURE = "GET_ORDER_DETAILS_BY_ID_FAILURE";

export const DELETE_ORDER_BY_ID_REQUEST = "DELETE_ORDER_BY_ID_REQUEST";
export const DELETE_ORDER_BY_ID_SUCCESS = "DELETE_ORDER_BY_ID_SUCCESS";
export const DELETE_ORDER_BY_ID_FAILURE = "DELETE_ORDER_BY_ID_FAILURE";

export const UPDATE_ORDER_STATUS_BY_ID_REQUEST = "UPDATE_ORDER_STATUS_BY_ID_REQUEST";
export const UPDATE_ORDER_STATUS_BY_ID_SUCCESS = "UPDATE_ORDER_STATUS_BY_ID_SUCCESS";
export const UPDATE_ORDER_STATUS_BY_ID_FAILURE = "UPDATE_ORDER_STATUS_BY_ID_FAILURE";