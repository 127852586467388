import React, { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { useAccount, useNetwork } from "wagmi";
import axios from "axios";
import { API_URL } from "../../Config/Auth";
import { showToast } from "../Main/common";
import SwipeImages from "./SwipeImages";
import axiosClient from "../../service/axios";

const Refer = () => {
  const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const network = useNetwork();
  const { address } = useAccount();
  const [referral, setReferral] = React.useState({});
  const [loading1, setLoading1] = React.useState(true);
  useEffect(() => {
  //  convertBalance();
    getMyReferral();
  }, [referral]);
  const profileData = referral && referral.user;
  const getMyReferral = async () => {
    try {
      let res = await axiosClient.get(`${API_URL}/myreferral/${address}`);
      if (res && res.data && res.data.success === true) {
        setReferral(res.data.data);
        setLoading1(false);
      } else {
        showToast(res.data.message, "error");
        setLoading1(false);
      }
    } catch (error) {
      showToast(error.message, "error");
      setLoading1(false);
    }
  };
  const getExchangeRate = async () => {
    try {
      let usdtpayload = {};
      let mattpayload = {};
      let tokenpayload = {};
      console.log(network?.chain?.id.toString());
      const chainId = network?.chain?.id.toString();
      if (chainId === "137") {
        usdtpayload["137"] = [
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
          "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
          //   "0xbfB61567c3b9829AF995755E33be30B6e5532553",
        ];
      }
      if (chainId === "56") {
        usdtpayload["56"] = [
          "0x55d398326f99059fF775485246999027B3197955",
          "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
          "0xbfB61567c3b9829AF995755E33be30B6e5532553",
        ];
      }
      if (chainId === "1") {
        usdtpayload["1"] = [
          "0xdac17f958d2ee523a2206206994597c13d831ec7",
          "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          //   tokenAddress,
        ];
      }
      console.log(usdtpayload, mattpayload, tokenpayload);
      const response = await axios.post(
        `https://api.coinbrain.com/public/coin-info`,
        usdtpayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = response.data;

      let priceUSDT = data.find((item) => {
        let itemAddress = item.address.toString().toLowerCase();
        let usdtAddress = usdtpayload[chainId][0].toString().toLowerCase();
        return itemAddress === usdtAddress;
      }).priceUsd;
      let priceNativeToken = data.find((item) => {
        let itemAddress = item.address.toString().toLowerCase();
        let maticAddress = usdtpayload[chainId][1].toString().toLowerCase();
        return itemAddress === maticAddress;
      }).priceUsd;
      let priceGraToken = data.find((item) => {
        let itemAddress = item.address.toString().toLowerCase();
        let tokenAddress = usdtpayload[chainId][2].toString().toLowerCase();
        return itemAddress === tokenAddress;
      }).priceUsd;
      return { priceUSDT, priceNativeToken, priceGraToken };
    } catch (error) {
      console.log(error);
    }
  };

  const convertBalance = async () => {
    try {
      const priceData = await getExchangeRate();
      const balanceInNativeToken =
        priceData.priceNativeToken * referral.balance;
      const balanceInUSDT = priceData.priceUSDT * balanceInNativeToken;
      setBalance(balanceInUSDT.toFixed(2));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    convertBalance();
  //  getMyReferral();
  }, []);

  const createWithdrawaRequest = async (e) => {
    try {
      if (+referral?.balance === 0) {
        showToast("Insufficient Balance to withdraw", "error");
        return;
      }
      const priceData = await getExchangeRate();
      const ReferRewardInNativeToken =
        priceData.priceNativeToken * referral.balance;
      const ReferRewardInGraToken =
        ReferRewardInNativeToken / priceData.priceGraToken;
      e.preventDefault();
      setLoading(true);
      let res = await axiosClient.post(
        `${API_URL}/balance`,
        {
          amount: ReferRewardInGraToken.toFixed(5),
        });
      if (res.data.success) {
        showToast(res.data.message, "success");
        setLoading(false);
        window.location.reload();
      } else {
        showToast(res.data.message, "error");
        setLoading(false);
      }
    } catch (error) {
      showToast(error.message, "error");
      setLoading(false);
    }
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row justify-start border shadow-md  shadow-yellow-500 m-1 px-2">
          <p className=" text-2xl font-bold text-black">Refer Friends</p>
        </div>
        <div className="m-1">
          <SwipeImages />
        </div>
        <div className="flex flex-row  gap-4 max-sm:flex-col  max-md:flex-col border shadow-md  shadow-yellow-500 m-1 p-2">
          <div className="flex flex-col w-1/2 gap-y-6 max-sm:gap-y-2 max-md:gap-y-2 max-sm:w-full max-md:w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col w-1/2">
                <p>Account balance:</p>
                <p className="text-xl font-bold uppercase">{balance} GRA</p>
              </div>
              <div className="mt-5">
                <button
                  onClick={(e) => createWithdrawaRequest(e)}
                  className="p-2 rounded-lg bg-yellow-500  text-white font-bold text-lg"
                >
                  {loading && <PulseLoader color="#fff" size={15} />}
                  {!loading && "Withdraw"}
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 uppercase ">friends</p>
              <div className="flex flex-row gap-5">
                <p className="text-xl font-bold uppercase">
                  
                  {referral.totalUsers}
                </p>
                <button className=" uppercase text-yellow-500 font-bold bg-transparent">
                  View
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-slate-500 uppercase">
                FRIENDS WHO STARTED TRADING
              </p>
              <div className="flex flex-row gap-5">
                <p className="text-xl font-bold uppercase">{referral.traderFriends}</p>
                <button className=" uppercase text-yellow-500 font-bold bg-transparent">
                  View
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-1/2 gap-y-6 max-sm:gap-y-2 max-md:gap-y-2 max-sm:w-full max-md:w-full">
            <div className="flex justify-start">
              <p className=" text-xl font-bold text-black">Default Referral</p>
            </div>
            <div className="flex justify-center bg-slate-100">
              <p className="">You will Receive 20%</p>
            </div>
            <div className="flex flex-col">
              <p>Referral ID</p>
              <div className="flex  rounded-md bg-slate-200 py-2 px-1">
                <p>{profileData?.referId}</p>
              </div>
            </div>
            <div className="flex flex-col">
              <p>Referral Link</p>
              <div className="flex  rounded-md bg-slate-200 py-2 px-1">
                <p>{`https://app.dp2p.cc/profile-page?code=${profileData?.referId}`}</p>
              </div>
            </div>
            <div className="flex justify-start">
              <button
                type="button"
                className="rounded-lg bg-yellow-500  p-2 text-yellow-900"
                // onClick={handleShow}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refer;
