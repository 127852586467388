import styled from "styled-components";
import { StyledSelect } from "../Antd";
const SelectInput = styled(StyledSelect)`
  .ant-input{
      border-radius: 3px;
      // border: 1px solid ${props => props.theme.borderColor};
      border: 1px solid gainsboro;
      background-color: ${props => props.theme.backgroundColor};
      color: ${props => props.theme.color};
      display: block;
      margin: 0 0 0.2rem 0;
      width:350px;
      border-radius: 0.1rem;
      outline: none;
      box-shadow:${props => (props.isShadow ? "" : "0px 4px 10px -4px #aaa")} ; 
  // height:33px;
      border-right: ${props =>
    props.isRequired ? "3px solid #ed260b" : ""} !important;
      padding: 0.3rem 1rem;
    &:hover{
      // box-shadow: 0px 4px 10px -2px  #777;
      }
      ::placeholder {
        color: #888;
      }
      ::placeholder {
        color: #bfbebb;
      }
  }

  
   `;
export default SelectInput;
