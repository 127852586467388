"use client";
import React, { Fragment, useEffect, useState } from "react";
import AnimatedDiv from "./AnimatedDiv";
import axios from "axios";
import eth from "../../assets/coins/eth.png";
import red from "../../assets/red-chart.png";
import bnb from "../../assets/coins/bnb.png";
import green from "../../assets/green-chart.png";
import polygon from "../../assets/icons/polygon2.png";
import gra from "../../assets/icons/gra.png";
import { Card } from "antd";
const id = [1, 56, 137, 56];
const tokens = [
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  "0xbfB61567c3b9829AF995755E33be30B6e5532553",
];
const Crypto = () => {
  const [coinValue, setCoinValue] = useState([]);

  useEffect(() => {
    fetchDataCoinValue();
    console.log(coinValue);
  }, []);
  const fetchDataCoinValue = async () => {
    const url = "https://api.coinbrain.com/public/coin-info";
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const allResponses = [];

    for (let i = 0; i < id.length; i++) {
      const data = {
        [id[i]]: [tokens[i]],
      };
      try {
        const response = await axios.post(url, data, config);
        allResponses.push(response.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    console.log(allResponses);
    // Once all requests are completed, update the state with the accumulated responses.
    setCoinValue(allResponses);
  };

  //const ethereumValue = cionValue.filter((item:any) => item.id === 1027);
  // console.log("ethr", cionValue);
  return (
    <div className="-translate-y-32 -mb-32">
      <AnimatedDiv>
        <div
          style={{
            boxShadow: "0 64px 64px -48px hsla(0,0%,6%,.1)",
          }}
          className="lg:border gap-3  grid md:grid-cols-2 xl:grid-cols-4 lg:p-6 border-[#23262f] text-white rounded-3xl"
        >
          {coinValue &&
            coinValue.map((coin, index) => (
              <>
                {coin.chainId === 1 ? (
                  <>
                    <Card className="bg-slate-800 border-slate-800 border  p-6 grid gap-4">
                      <div className=" flex justify-between">
                        <div className=" grid gap-3">
                          <img src={eth} width={44} height={44} alt="icon" />
                          <h6 className=" text-sm text-white font-bold  capitalize">
                            Ethereum
                          </h6>
                        </div>

                        <div className="grid">
                          <img
                            src={red}
                            width={120}
                            height={40}
                            alt="chart"
                            className=" object-contain"
                          />
                          <p
                            className={`${
                              -5.45 > 0 ? "bg-[#58bd7d] " : "bg-[#d33535]"
                            } rounded-full inline-flex h-fit my-auto items-center gap-1 px-2 py-1 w-fit  text-sm font-bold ml-auto`}
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.393244 5.90568C0.477403 6.06168 0.637433 6.15893 0.811488 6.15893H3.52179V11.5105C3.52179 11.7807 3.73601 12 3.99996 12C4.26392 12 4.47814 11.7807 4.47814 11.5105V6.15893H7.18844C7.36313 6.15893 7.52316 6.06168 7.60668 5.90568C7.69148 5.74969 7.68574 5.5591 7.59329 5.40832L4.40482 0.228447C4.31683 0.0861572 4.16445 0 3.99996 0C3.83547 0 3.68309 0.0861572 3.59511 0.228447L0.406633 5.40832C0.358178 5.48795 0.333313 5.57868 0.333313 5.6694C0.333313 5.75034 0.353715 5.83193 0.393244 5.90568Z"
                                fill="white"
                              ></path>
                            </svg>
                            -5.45
                          </p>
                        </div>
                      </div>
                      <div className=" flex justify-between items-end">
                        <h6 className=" text-2xl text-white font-bold  capitalize">
                          $ {coin.priceUsd.toFixed(2)}
                        </h6>
                        <h6 className=" text-lg text-[#b1b5c3] font-bold  capitalize">
                          Ethereum
                        </h6>
                      </div>
                      <div className=" flex justify-between mt-2 gap-2 w-full">
                        <button className="w-1/2 p-2 rounded-md text-white font-bold bg-green-600">
                           Buy
                        </button>
                         <button className="w-1/2 p-2 rounded-md text-white font-bold bg-red-600">
                          Sell
                        </button>
                      </div>
                    </Card>
                  </>
                ) : coin.chainId === 56 &&
                  coin.address ===
                    "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c" ? (
                  <>
                    <Card className="bg-slate-800 border-slate-800 border  p-6 grid gap-4">
                      <div className=" flex justify-between">
                        <div className=" grid gap-3">
                          <img src={bnb} width={44} height={44} alt="icon" />
                          <h6 className=" text-sm text-white font-bold  capitalize">
                            BNB Chain
                          </h6>
                        </div>

                        <div className="grid">
                          <img
                            src={green}
                            width={120}
                            height={40}
                            alt="chart"
                            className=" object-contain"
                          />
                          <p
                            className={`${
                              7.45 > 0 ? "bg-[#58bd7d] " : "bg-[#d33535]"
                            } rounded-full inline-flex h-fit my-auto items-center gap-1 px-2 py-1 w-fit  text-sm font-bold ml-auto`}
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.393244 5.90568C0.477403 6.06168 0.637433 6.15893 0.811488 6.15893H3.52179V11.5105C3.52179 11.7807 3.73601 12 3.99996 12C4.26392 12 4.47814 11.7807 4.47814 11.5105V6.15893H7.18844C7.36313 6.15893 7.52316 6.06168 7.60668 5.90568C7.69148 5.74969 7.68574 5.5591 7.59329 5.40832L4.40482 0.228447C4.31683 0.0861572 4.16445 0 3.99996 0C3.83547 0 3.68309 0.0861572 3.59511 0.228447L0.406633 5.40832C0.358178 5.48795 0.333313 5.57868 0.333313 5.6694C0.333313 5.75034 0.353715 5.83193 0.393244 5.90568Z"
                                fill="white"
                              ></path>
                            </svg>
                            7.45
                          </p>
                        </div>
                      </div>
                      <div className=" flex justify-between items-end">
                        <h6 className=" text-2xl text-white font-bold  capitalize">
                          $ {coin.priceUsd.toFixed(2)}
                          {/* {coin.priceUsd.toLocaleString("en-US", {
                            currency: "USD",
                          })} */}
                        </h6>
                        <h6 className=" text-lg text-[#b1b5c3] font-bold  capitalize">
                          BNB Chain
                        </h6>
                      </div>
                      <div className=" flex justify-between mt-2 gap-2 w-full">
                        <button className="w-1/2 p-2 rounded-md text-white font-bold bg-green-600">
                           Buy
                        </button>
                        <button className="w-1/2 p-2 rounded-md text-white font-bold bg-red-600">
                          Sell
                        </button>
                      </div>
                    </Card>
                  </>
                ) : coin.chainId === 137 ? (
                  <>
                    <Card className="bg-slate-800 border-slate-800 border  p-6 grid gap-4">
                      <div className=" flex justify-between">
                        <div className=" grid gap-3">
                          <img
                            src={polygon}
                            width={44}
                            height={44}
                            alt="icon"
                          />
                          <h6 className=" text-sm text-white font-bold  capitalize">
                            Matic
                          </h6>
                        </div>

                        <div className="grid">
                          <img
                            src={red}
                            width={120}
                            height={40}
                            alt="chart"
                            className=" object-contain"
                          />
                          <p
                            className={`${
                              -5.45 > 0 ? "bg-[#58bd7d] " : "bg-[#d33535]"
                            } rounded-full inline-flex h-fit my-auto items-center gap-1 px-2 py-1 w-fit  text-sm font-bold ml-auto`}
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.393244 5.90568C0.477403 6.06168 0.637433 6.15893 0.811488 6.15893H3.52179V11.5105C3.52179 11.7807 3.73601 12 3.99996 12C4.26392 12 4.47814 11.7807 4.47814 11.5105V6.15893H7.18844C7.36313 6.15893 7.52316 6.06168 7.60668 5.90568C7.69148 5.74969 7.68574 5.5591 7.59329 5.40832L4.40482 0.228447C4.31683 0.0861572 4.16445 0 3.99996 0C3.83547 0 3.68309 0.0861572 3.59511 0.228447L0.406633 5.40832C0.358178 5.48795 0.333313 5.57868 0.333313 5.6694C0.333313 5.75034 0.353715 5.83193 0.393244 5.90568Z"
                                fill="white"
                              ></path>
                            </svg>
                            -5.45
                          </p>
                        </div>
                      </div>
                      <div className=" flex justify-between items-end">
                        <h6 className=" text-2xl text-white font-bold  capitalize">
                          $ {coin.priceUsd.toFixed(4)}
                          {/* {coin.priceUsd.toLocaleString("en-US", {
                            currency: "USD",
                          })} */}
                        </h6>
                        <h6 className=" text-lg text-[#b1b5c3] font-bold  capitalize">
                          Polygon
                        </h6>
                      </div>
                      <div className=" flex justify-between mt-2 gap-2 w-full">
                        <button className="w-1/2 p-2 rounded-md text-white font-bold bg-green-600">
                           Buy
                        </button>
                         <button className="w-1/2 p-2 rounded-md text-white font-bold bg-red-600">
                          Sell
                        </button>
                      </div>
                    </Card>
                  </>
                ) : coin.chainId === 56 &&
                  coin.address ===
                    "0xbfb61567c3b9829af995755e33be30b6e5532553" ? (
                  <>
                    <Card className="bg-slate-800 border-slate-800 border  p-6 grid gap-4">
                      <div className=" flex justify-between">
                        <div className=" grid gap-3">
                          <img src={gra} width={44} height={44} alt="icon" />
                          <h6 className=" text-sm text-white font-bold  capitalize">
                            GRA Token
                          </h6>
                        </div>

                        <div className="grid">
                          <img
                            src={green}
                            width={120}
                            height={40}
                            alt="chart"
                            className=" object-contain"
                          />
                          <p
                            className={`${
                              7.45 > 0 ? "bg-[#58bd7d] " : "bg-[#d33535]"
                            } rounded-full inline-flex h-fit my-auto items-center gap-1 px-2 py-1 w-fit  text-sm font-bold ml-auto`}
                          >
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.393244 5.90568C0.477403 6.06168 0.637433 6.15893 0.811488 6.15893H3.52179V11.5105C3.52179 11.7807 3.73601 12 3.99996 12C4.26392 12 4.47814 11.7807 4.47814 11.5105V6.15893H7.18844C7.36313 6.15893 7.52316 6.06168 7.60668 5.90568C7.69148 5.74969 7.68574 5.5591 7.59329 5.40832L4.40482 0.228447C4.31683 0.0861572 4.16445 0 3.99996 0C3.83547 0 3.68309 0.0861572 3.59511 0.228447L0.406633 5.40832C0.358178 5.48795 0.333313 5.57868 0.333313 5.6694C0.333313 5.75034 0.353715 5.83193 0.393244 5.90568Z"
                                fill="white"
                              ></path>
                            </svg>
                            7.45
                          </p>
                        </div>
                      </div>
                      <div className=" flex justify-between items-end">
                        <h6 className=" text-2xl text-white font-bold  capitalize">
                          
                          {/* {coin.priceUsd.toLocaleString("en-US", {
                            currency: "USD",
                          })} */}
                        $ {coin.priceUsd.toFixed(4)}
                        </h6>
                        <h6 className=" text-lg text-[#b1b5c3] font-bold  capitalize">
                          GRA Token
                        </h6>
                      </div>
                      <div className=" flex justify-between mt-2 gap-2 w-full">
                        <button className="w-1/2 p-2 rounded-md text-white font-bold bg-green-600">
                           Buy
                        </button>
                         <button className="w-1/2 p-2 rounded-md text-white font-bold bg-red-600">
                          Sell
                        </button>
                      </div>
                    </Card>
                  </>
                ) : null}
              </>
            ))}
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default Crypto;
