import * as types from "./OrderActionTypes";
const initialState = {
  fetchingAllOrder: false,
  fetchingAllOrderError: false,
  allOrders: {},

  fetchingOrderDetails: false,
  fetchingOrderDetailsError: false,
  orderDetails: {},
};
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_ORDER_REQUEST:
      return { ...state, fetchingAllOrder: true };
    case types.GET_ALL_ORDER_SUCCESS:
      return {
        ...state,
        fetchingAllOrder: false,
        allOrders: action.payload,
      };

    case types.GET_ALL_ORDER_FAILURE:
      return {
        ...state,
        fetchingAllOrder: false,
        fetchingAllOrderError: true,
      };     
      case types.GET_ORDER_DETAILS_BY_ID_REQUEST:
        return { ...state, fetchingOrderDetails: true };
      case types.GET_ORDER_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          fetchingOrderDetails: false,
          orderDetails: action.payload,
        };
  
      case types.GET_ORDER_DETAILS_BY_ID_FAILURE:
        return {
          ...state,
          fetchingOrderDetails: false,
          fetchingOrderDetailsError: true,
        }; 
    default:
      return state;
  }
  return state;
};
