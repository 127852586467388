import React from "react";
import { Image } from "antd";
import AnimatedDiv from "./AnimatedDiv";
import usdt from "../../assets/coins/usdt.png";
import gra from "../../assets/icons/gra.png";
import binance from "../../assets/icons/binance.png";
import busd from "../../assets/icons/busd.png";
import polygon2 from "../../assets/icons/polygon2.png";
import line from "../../assets/line.png";
import ethereuem from "../../assets/icons/ethereuem.png";
import WalletConnect from "../../Components/Comman/WalletConnect";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const feturesData = [ 
  {
    label: "Easy to follow trading processes that enable you to trade safely with escrow protection so you can convert crypto coin to cash or trade cryptocurrency with hundreds of other payment methods.",
    id: "1",
  },
  {
    label: "Feel confident by trading on-chain with non-custodial exchange.",
    id: "2",
  },
  {
    label:
      "Exchange Ethereum, and other popular cryptocurrencies using secure smart contracts.",
    id: "3",
  },
  {
    label:
      " Empower yourself by exploring how to buy and sell cryptocurrency without having to hand over control of your funds.",
    id: "4",
  },
  {
    label:
      "Explore an EVM compatible blockchain reducing barriers to cryptocurrency adoption.",
    id: "5",
  },
];
const Features = () => {
  return (
    <div className="grid text-white justify-center lg:grid-cols-[55%_auto] py-20">
      <div className="grid md:grid-cols-[repeat(2,minmax(auto,320px))] h-fit place-content-center gap-6">
        <div className=" bg-slate-800 rounded-lg  p-8 items-center flex text-center gap-4 flex-col justify-center">
          <div className="flex items-center w-fit justify-center p-6 rounded-full bg-red-500/10">
            <img src={ethereuem} width={75} height={75} alt="icon 1" />
          </div>
          <h2 className="font-bold text-2xl text-white">Ethereum network</h2>
          <p className=" text-[#b1b5c3] text-base  capitalize">
            Trade Ethereum and other ERC20 tokens with full non-custodial
            trading.
          </p>
          <div className="flex items-center w-fit justify-center gap-1">
            <img src={ethereuem} width={31} height={36} alt="icon 2" />
            <img src={usdt} width={31} height={36} alt="icon 2" />
            <img src={gra} width={31} height={36} alt="icon 2" />
          </div>
        </div>
        <div className=" bg-slate-800 rounded-lg row-span-2 my-auto h-fit p-8 items-center flex text-center gap-4 flex-col justify-center">
          <div className="flex items-center w-fit justify-center p-6 rounded-full bg-green-500/10">
            <img src={binance} width={75} height={75} alt="icon 2" />
          </div>
          <h2 className="font-bold text-2xl text-white">Binance smart chain</h2>
          <p className=" text-[#b1b5c3] text-base  capitalize">
            Trade BNB And Other BEP20 Tokens With Full Non-Custodial Trading.
          </p>
          <div className="flex items-center w-fit justify-center gap-1">
            <img src={binance} width={31} height={36} alt="icon 2" />
            <img src={usdt} width={31} height={36} alt="icon 2" />
            <img src={busd} width={31} height={36} alt="icon 2" />
            <img src={gra} width={31} height={36} alt="icon 2" />
          </div>
        </div>
        <div className=" bg-slate-800  rounded-lg  p-8 items-center flex text-center gap-4 flex-col justify-center">
          <div className="flex items-center w-fit justify-center p-6 rounded-full bg-blue-500/10">
            <img src={polygon2} width={75} height={75} alt="icon 3" />
          </div>
          <h2 className="font-bold text-2xl">Polygon network</h2>
          <p className=" text-[#b1b5c3] text-base  capitalize">
            Trade MATIC And Other POLYGON Tokens With Full Non-Custodial
            Trading.
          </p>
          <div className="flex items-center w-fit justify-center gap-1">
            <img src={polygon2} width={31} height={36} alt="icon 2" />
            <img src={usdt} width={31} height={36} alt="icon 2" />
            <img src={gra} width={31} height={36} alt="icon 2" />
          </div>
        </div>
      </div>
      <AnimatedDiv>
        <div className="background-image-container">
          <div className=" grid relative  place-content-center gap-3">
            <h3 className=" text-[32px] z-10 md:text-[48px] font-bold leading-[1.2]">
            Secure Trade with Transparency
            </h3>
            <p className=" text-2xl font-normal text-slate-300 leading-[1.4]"> 
            It shouldn't be challenging to stay safe trading cryptocurrency.
            </p>
            <div className="flex flex-col space-y-2 mt-2">
              {feturesData.map((item) => (
                <div className="flex gap-1" key={item.id}>
                  <FiberManualRecordIcon
                    sx={{ marginTop: 0.5 }}
                    fontSize="small"
                  />
                  <p className=" text-[#b1b5c3]  z-10 text-lg  ">
                    {item.label}
                  </p>
                </div>
              ))}
            </div>

            <WalletConnect label="Let's Trade Now" />
            {/* <a
              href="https://app.dp2p.cc"
              target="_blank"
              className=" text-white  z-10 bg-yellow-300 rounded-full text-sm px-10 py-3 font-bold w-fit"
            >
              Let&apos;s Trade Now
            </a> */}
            {/* <button className=" text-white  z-10 bg-yellow-300 rounded-full text-sm px-10 py-3 font-bold w-fit">
            Let&apos;s Trade Now
          </button> */}
            {/* <div className="-ml-20">
          <img
            src={line}
            width={625}
            height={285}
            alt="line"
            className=" select-none absolute  md:-right-60 -right-28  -bottom-10 md:bottom-14 my-auto z-[0] "
          />
          </div>
           */}
          </div>
        </div>
      </AnimatedDiv>
    </div>
  );
};

export default Features;
