import React, { useState } from "react";
import { Button, Form, Select, Input, InputNumber } from "antd";
import { useAccount } from "wagmi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AddNewTicket } from "../SettingAction";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const CreateNewTicket = (props) => {
  const [category, setCategory] = useState("");
  const { isConnected, address, isDisconnected } = useAccount();
  const [form] = Form.useForm();
  let navigation = useNavigate();
  const handleCategoryChange = (selectedItems) => {
    setCategory(selectedItems);
  };
  const onFinish = (values) => {
    props.AddNewTicket(values);
    form.resetFields();
  };
  const goBack = () => {
    navigation(-1);
  };
  const initialValues = {
    subject: "",
    message: "",
    category: category,
    status: "open",
    wallet: `${address}`,
  };
  return (
    <>
      <div className="flex justify-start border shadow-md  shadow-yellow-500 m-1 p-5">
        <p className=" text-2xl font-bold text-black">Help and Support</p>
      </div>
      <div className="flex border shadow-md  shadow-yellow-500 m-1 p-5">
        <Form
          form={form}
          className="w-full flex flex-col justify-center  max-sm:w-full  max-[sm,md]:m-1"
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
          //  validateMessages={validateMessages}
        >
          <Form.Item
            name="category"
            label="Category"
            rules={[
              {
                required: true,
                message: "Please select category!",
              },
            ]}
          >
            <Select
              placeholder="Select a category"
              onChange={handleCategoryChange}
              value={category}
            >
              <Option value="account">Account</Option>
              <Option value="billing">Billing</Option>
              <Option value="technical">Technical</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="subject"
            label="Subject"
            rules={[
              {
                required: true,
                message: "Please input your subject!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="message" label="Message">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="wallet">{/* <Input disabled /> */}</Form.Item>
          <Form.Item name="status"></Form.Item>
          <div className="flex gap-3 justify-center mt-2 ">
            <button
              // type="button"
              htmlType="submit"
              className="bg-green-500 w-full p-2 text-white rounded-full text-lg border border-green-600 self-center hover:border-green-800 hover:text-green-800"
            >
              Submit
            </button>

            <button
              // type="button"
              onClick={() => goBack()}
              className="bg-orange-500 w-full p-2 text-white rounded-full text-lg border border-orange-600 self-center hover:border-orange-800 hover:text-orange-800"
            >
              Back
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AddNewTicket,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTicket);
