import CustomDisclosure from "./CustomDisclosure";
import step1 from "../../../assets/referral-program/step-1.webp";
import step2 from "../../../assets/referral-program/step-2.webp";
import step3 from "../../../assets/referral-program/step-3.webp";
import step4 from "../../../assets/referral-program/benefit-1.webp";
import step5 from "../../../assets/referral-program/benefit-2.webp";
import step6 from "../../../assets/referral-program/benefit-3.webp";
import step7 from "../../../assets/referral-program/hero.webp";
import step8 from "../../../assets/referral-program/big-coin.webp";
import { Link } from "react-router-dom";
import WalletConnect from "../../../Components/Comman/WalletConnect";

const howTo = [
  {
    title: "Claim referral code",
    subtitle:
      "Register your account and get a referral code instantly to start earning right away.",
    image: `${step1}`,
  },
  {
    title: "Invite friends",
    subtitle:
      "If you have a friend who wants to buy cryptocurrency, invite them with your referral code!",
    image: `${step2}`,
  },
  {
    title: "Earn Graphite commission",
    subtitle:
      "Get a referral commission of up to 20% of the trade fees when your referees complete trades.",
    image: `${step3}`,
  },
];

const benefits = [
  {
    title: "Automatic Payouts",
    subtitle:
      "It's easy to get this month's payouts of the commission you earned!",
    image: `${step4}`,
  },
  {
    title: "Passive Income",
    subtitle:
      "One of the best parts about earning rewards is that you don't have to trade.",
    image: `${step5}`,
  },
  {
    title: "No Hard Caps",
    subtitle: "Earn more and maximize your rewards by referring more users.",
    image: `${step6}`,
  },
];

const Referal = () => {
  return (
    <main className=" bg-[#020d18] h-full text-white px-6">
      <div className=" grid  place-items-center md:grid-cols-2 gap-6 h-[100dvh]">
        <div>
          <h2 className="text-[40px] max-w-lg  font-bold text-white leading-[1.11]">
            Graphite rewards for every trade your referrals complete
          </h2>
          <button className=" text-white mt-8 bg-primary   hover:opacity-90 duration-300 ease-in-out rounded-full px-6 py-3 font-bold w-fit">
          <WalletConnect label="Get referral Code" />  
          </button>
        </div>
        <div>
          <img
            src={`${step7}`}
            alt="Hero img"
            width={700}
            height={700}
            className=" w-[80%] aspect-square m-auto"
          />
        </div>
      </div>
      <div>
        <div className="flex flex-col">
          <h2 className=" text-3xl flex justify-center font-bold">How to get started </h2>
          <p className=" flex justify-center text-lg">
            The DP2P referral program makes it simple to earn free graphite
            without even having to complete a trade yourself.
          </p>
        </div>
        <div className=" grid gap-10 md:gap-36 mt-20">
          {howTo.map((step, index) => {
            return (
              <div
                key={index}
                className=" grid place-items-center gap-6 md:grid-cols-2 bg-[#031629] rounded-lg px-4 md:px-10 py-10 md:py-28"
              >
                <div className="grid order-2 gap-2">
                  <h2 className=" text-3xl font-bold">{step.title}</h2>
                  <p className=" text-lg ">{step.subtitle}</p>
                </div>
                <div className="flex  order-1 md:order-2 relative w-full items-center justify-center">
                  <img
                    src={step.image}
                    alt={step.title}
                    width={400}
                    height={400}
                    className=" relative md:absolute w-96 aspect-square  "
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className=" mt-10 md:mt-20 bg-[#031629] py-5 px-2 md:py-40  relative">
        <svg
          className=" w-full absolute rotate-180 top-0 left-0  bg-[#020d18] "
          viewBox="0 0 4655 329"
          xmlns="http://www.w3.org/2000/svg"
          fill="#031629"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 274.991L194 252.021C388 229.378 776 183.437 1164 206.408C1552 229.378 1940 320.933 2328 328.48C2715 336.028 3103 259.896 3491 198.532C3879 137.496 4267 91.5544 4461 68.9119L4655 45.9413V0H4461C4267 0 3879 0 3491 0C3103 0 2715 0 2328 0C1940 0 1552 0 1164 0C776 0 388 0 194 0H0V274.991Z"
          ></path>
        </svg>
        <div className=" grid  md:grid-cols-3 gap-6 py-10 md:py-40">
          {benefits.map((benefit) => {
            return (
              <div
                key={benefit.title}
                className=" grid gap-2  text-center place-items-center"
              >
                <img
                  src={benefit.image}
                  alt={benefit.title}
                  width={100}
                  height={100}
                  className="  aspect-square  "
                />
                <h3 className=" font-bold text-lg">{benefit.title}</h3>
                <p className=" max-w-xs text-sm"> {benefit.subtitle}</p>
              </div>
            );
          })}
        </div>
        <div className=" grid py-10 md:py-40 ">
          

          <div className=" max-w-6xl w-full mx-auto grid gap-4 mt-20">
          <div className="my-5 flex flex-col">
            <p className="flex text-3xl font-bold px-2">
              Common Frequently Asked Questions
            </p>
            <p className="flex text-lg px-2">
              Find out how you could be on your way to earning graphite online fast.
            </p>
          </div>
            <CustomDisclosure title="What is the DP2P referral policy?">
              You will be rewarded for all trades you referees successfully
              complete. The only condition is that it cannot use illegal
              methods, copyrighted material, or spam to attract them! If
              you&apos;re looking for more information about the referral
              program, just log in and click on your dashboard. You&apos;ll find
              all sorts of helpful resources there!
            </CustomDisclosure>

            <CustomDisclosure title="Will I get paid in Graphite?">
              Yes, all referral commissions are calculated and paid in graphite.
              If your referee trade other cryptos such as Dash, USDT LCS, or
              ETH, your commission will be calculated and paid in graphite to
              help you make the most of your referral payments while enabling
              you to earn from every trade your referrals make.
            </CustomDisclosure>

            <CustomDisclosure title="How does the DP2P referral program work?">
              You can make money by referring new users that go on to trade. For
              every successful trade that results from one of your referrals,
              you will receive 10% off their trading fees and 20% if both the
              buyer and seller were referrals of yours! <br />
              <br /> All commissions are calculated and paid in graphite. You
              can elect to have your graphite balance sent to your wallet once a
              month or build it up over several months to reduce the blockchain
              transaction fees and maximize your profit.
              <br />
              <br /> Graphite payouts are sent to your non-custodial wallet to
              ensure you are able to send them to external wallets with the
              least transaction costs possible! <br /> <br /> For extra income,
              you can even consider selling your referral commissions on DP2P.
              It&apos;s a great way to make some extra money and help others in
              need of cryptocurrency at the same time!
            </CustomDisclosure>
          </div>
          <div className=" flex justify-center">
          <Link to="https://learn.dp2p.cc/">
            <button className="mt-20 border-[#63a5ed] border w-fit mx-auto px-6 hover:bg-[#63a5ed] hover:text-white duration-300 ease-in-out py-2 text-lg font-bold rounded-full text-[#63a5ed]">
              Learn More
            </button>
          </Link>
          </div>
         
        </div>

        <div>
          <div className=" grid md:grid-cols-2 py-10 gap-6 items-center bg-[#06284c] rounded-lg">
            <div className=" flex items-center justify-center ">
              <img src={`${step8}`} alt="img" width={500} height={500} />
            </div>
            <div>
              <h2 className="m-2 text-[40px] max-w-lg  font-bold text-white leading-[1.11]">
                Get your referral link today and start earning rewards!
              </h2>
              <button className=" text-white mt-8 w-full bg-primary hover:opacity-90 duration-300 ease-in-out rounded-full px-6 py-3 font-bold md:w-fit">
              <WalletConnect label="Get referral Code" />  
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Referal;
