import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RingProgress } from '@ant-design/plots';

const DemoRingProgress = (props) => {
 let  value = props.value;
  const config = {
    height: 100,
    width: 100,
    autoFit: false,
    percent: value,
    color: ['green', '#eab308'],    
  };
  return <RingProgress {...config} />;
};

export default DemoRingProgress;