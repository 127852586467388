"use client";
import { Disclosure } from "@headlessui/react";
import { RiArrowDownSLine } from "react-icons/ri";
const CustomDisclosure = ({ title, children }) => {
  return (
    <Disclosure as={"div"} className={" border-b "}>
      {({ open }) => (
        <>
          <Disclosure.Button className="text-white flex justify-between text-start px-4 py-2 font-bold w-full">
            <span>{title}</span>
            <RiArrowDownSLine
              className={`${
                open ? "rotate-180 transform" : " -rotate-90"
              } h-8 w-8 text-white duration-300 ease-in-out`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 py-4 text-sm text-[#d6dde6] font-medium">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CustomDisclosure;
