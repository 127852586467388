import React from "react";
import HelpTable from "./HelpTable";
import { Link } from "react-router-dom";
import HelpCard from "./HelpCard";

const HelpSupport = () => {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between border shadow-md  shadow-yellow-500 m-1 p-5">
          <p className=" text-2xl font-bold text-black">Help and Support</p>
          <Link to="/newTicket">
            <button className="p-2 rounded-lg bg-yellow-500  text-white font-bold">
              New Ticket
            </button>
          </Link>
        </div>
        <div className="flex  border shadow-md  shadow-yellow-500 m-1 max-sm:hidden max-lg:hidden max-md:hidden">
          <HelpTable />
        </div>
        <div className="lg:hidden w-full">
          <HelpCard />
          </div>
      </div>
    </>
  );
};

export default HelpSupport;
