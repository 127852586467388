import React, { Suspense, useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { InputComponent } from "../../Components/Forms/Formik/InputComponent";
import { SelectComponent } from "../../Components/Forms/Formik/SelectComponent";
import { PulseLoader } from "react-spinners";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Switch,
  Alert,
  Spin,
  TreeSelect,
  Upload,
  Modal,
  message,
} from "antd";
import { registerUser } from "./AuthAction";
import { useAccount } from "wagmi";
import { API_URL } from "../../Config/Auth";
import { userDetails } from "./AuthAction";
import axios from "axios";
import { Navigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import landgif from "../../assets/ModernMinimalist.png";
const { Option } = Select;
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const SignUp = (props) => {
  const { isConnected, address, isDisconnected } = useAccount();
  const [componentDisabled, setComponentDisabled] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [verifyEmail, setVerifyEmail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const sendVerificationEmail = async (email) => {
    let data = { email };
    try {
      let res = await axios.post(`${API_URL}/verify-email`, data);
      if (res.data.success) {
        setIsModalOpen(true);
        message.success("Verification email sent successfully");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  const handleSubmitOtp = async (otp, email) => {
    let dataO = { otp, email };
    try {
      let response = await axios.post(`${API_URL}/verify-otp`, dataO);
      let data = response.data;
      if (data.success) {
        message.success(data.message);
        setComponentDisabled(true);
        setIsModalOpen(false);
      } else {
        message.error(data.message);
        setComponentDisabled(false);
        setIsModalOpen(true);
      }
    } catch (error) {
      message.error(error.message);
      setIsModalOpen(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setEmail("");
    setComponentDisabled(false);
    setOtp("");
  };

  const handleChangeCountry = (selectedItems) => {
    setSelectedCountry(selectedItems);
  };
  const handleChangeCurrency = (selectedItems) => {
    setSelectedCurrency(selectedItems);
  };

  const fetchcurrencyList = async () => {
    try {
      let response = await axios.get(`${API_URL}/currency`);
      let data = response.data;
      setCurrencyList(data.currencies);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCountryList = async () => {
    try {
      let response = await axios.get(`${API_URL}/country`);
      let data = response.data;
      setCountryList(data.countries);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchcurrencyList();
    fetchCountryList();
  }, []);

  const initialValues = {
    email: email,
    name: "",
    mobile: "",
    country: "",
    currency: "",
    otp: otp,
    wallet: `${address}`,
  };
  const prefixSelector = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue={"+91"}
      >
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  const handleSubmit = (values) => {
    console.log(values);
    props.registerUser(values, address);
    //  window.location.reload();
  };

  useEffect(() => {
    if (isConnected) {
      props.userDetails(address);
    }
  }, []);
  const userDetail = sessionStorage.getItem("userDetails");
  const fetchingUserDetails = props.fetchingUserDetails;
  if (fetchingUserDetails) {
    return <Loader />;
  }
  return (
    <>
      {/* {isConnected && !userDetail && fetchingUserDetails ? ( */}
      <Suspense fallback={<Loader />}>
        <div className="flex p-2 m-2 flex-row max-[sm,md,xl,2xl]:flex  max-[sm,md]:flex-col">
          <div className="w-1/2 max-sm:hidden max-md:hidden">
            <img src={landgif} className=" w-full h-full" />
          </div>

          <Form
            className="w-1/2 flex justify-center  max-sm:w-full overflow-y-auto scrollbar-none  max-md:w-full max-[sm,md]:m-1"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
            // validateMessages={validateMessages}
          >
            <div className="lg:w-3/5 md:h-3/5 w-full">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                name="mobile"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please select your Country!",
                  },
                ]}
              >
                <Select
                  // mode="multiple" // To enable multiple selection
                  value={selectedCountry} // Set the selected values
                  onChange={handleChangeCountry} // Handle value changes
                  style={{ width: "100%" }}
                  defaultValue="6430fdaaa2fd4c0b27349b8b"
                >
                  {countryList.map((option) => (
                    <Option key={option._id} value={option._id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Please select your Currency!",
                  },
                ]}
              >
                <Select
                  // mode="multiple" // To enable multiple selection
                  value={selectedCurrency} // Set the selected values
                  onChange={handleChangeCurrency} // Handle value changes
                  style={{ width: "100%" }}
                >
                  {currencyList.map((option) => (
                    <Option key={option._id} value={option._id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
                validateStatus={
                  componentDisabled === true ? "success" : "validating"
                }
              //  hasFeedback={componentDisabled ? true : false}
              >
                <Input
                  value={email}
                  disabled={componentDisabled === true ? true : false}
                  onChange={(e) => setEmail(e.target.value)}
                  addonAfter={
                    <p 
                   // onClick={() => sendVerificationEmail(email)}
                    >Verify</p>
                  }
                />
              </Form.Item>

              <Modal
                //  title="Validate Email"
                open={isModalOpen}
                onOk={() => handleSubmitOtp(otp, email)}
                okText={"Validate"}
                onCancel={handleCancel}
              >
                <form onSubmit={() => handleSubmitOtp(otp, email)}>
                  <div className="flex flex-row mt-6 gap-3">
                    <div className="w-full flex flex-col space-y-2">
                      <Input
                        type="text"
                        placeholder="Enter Your OTP"
                        name="otp"
                        value={otp}
                        onChange={handleOtpChange}
                      />
                    </div>
                    {/* <div className="w-1/6 self-center">
                      <button
                        type="submit"
                        className="rounded-full bg-yellow-500  py-1 px-3 text-white font-bold"
                      >
                        Validate
                      </button>
                    </div> */}
                  </div>
                </form>
              </Modal>

              <Form.Item label="Wallet" name="wallet">
                <Input disabled />
              </Form.Item>
              {/* <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item> */}
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("Should accept agreement")),
                  },
                ]}
              >
                <Checkbox>
                  I Agree to the Terms and Conditions and Privacy Policy
                  <a href="">agreement</a>
                </Checkbox>
              </Form.Item>
              <div className="w-full gap-2 flex flex-row">
                <button
                  // type="primary"
                  htmlType="submit"
                  className="bg-green-500 font-bold w-full text-white rounded-full text-lg border  self-center"
                >
                  {props.register ? (
                    <PulseLoader color="#fff" size={10} />
                  ) : (
                    "SignUp"
                  )}
                </button>

                <button
                  type="button"
                  // onClick={() => resetForm()}
                  className="bg-orange-500 font-bold w-full text-white rounded-full text-lg border border-orange-600 self-center"
                >
                  Reset
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Suspense>
      {/* ) : null} */}
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  fetchingUserDetails: auth.fetchingUserDetails,
  userDetails: auth.userDetails,
  register: auth.register,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerUser,
      userDetails,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
