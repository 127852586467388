import { Select } from "antd";
import styled from "styled-components";

const JumpStartWrapper = styled.div`
//   margin: 0.2rem 0.4rem;
//   padding: 1rem;
  // border: 0.0625em solid #ddd;
  border-radius:100%;
  display: flex;
  justify-content: "center";
  align-items: center;
  background: ${(props) => props.bgColor || "#fff"};
  color: #333 !important;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  -webkit-box-shadow: 0 8px 6px -6px rgb(46, 44, 44);
  -moz-box-shadow: 0 8px 6px -6px rgb(46, 44, 44);
  box-shadow: 0 8px 6px -6px rgb(46, 44, 44);
  flex: 1 1 0;
//   height: 13rem;
//   width:13rem;
//   transition: 0.3s all;
  transition: box-shadow .6s;
  &:hover {
    -webkit-box-shadow: 0 0.75em 6px -6px rgb(46, 44, 44);
    -moz-box-shadow: 0 0.75em 6px -6px rgb(46, 44, 44);
    box-shadow: 0 2px 25px rgba(142, 207, 245);
  }
`;
export default JumpStartWrapper;