import React, { useEffect, useRef, useState } from "react";
import {
  faArrowLeft,
  faCheckCircle,
  faArrowUpRightFromSquare,
  faPaperPlane,
  faPaperclip,
  faRefresh,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getOrderDetailsById } from "./OrderAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../Config/Auth";
import { useSocket } from "../../Screen/socketProvider";
import { showToast, formatDate } from "../../api/common";
import { useAccount } from "wagmi";
import { Form, Input, Alert, Spin, Modal, Tabs } from "antd";
import Loader from "../../Components/Loader";
import axiosClient from "../../service/axios";
import { TbArrowBigLeftLinesFilled } from "react-icons/tb";

function OrderChat(props) {
  const details =
    props.details || (props.orderDetails && props.orderDetails.data);
  const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
  let socket = useSocket();
  const params = useParams();
  const account = useAccount();
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState("");
  const chatboxRef = useRef(null);
  const [initLoading, setInitLoading] = useState(true);
  const messageRef = useRef(null);
  const [message, setMessage] = useState("");
  const [refreshed, setRefreshed] = useState(true);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const sendMessages = (e) => {
    let data = {
      message,
      sender_id: account.address,
      buyer_id: details.buyer._id,
      seller_id: details.seller._id,
      order_id: details._id,
      room_id: details.orderId,
      file: file,
    };
    socket && socket.emit("sendMessage", data);
    messageRef.current.value = "";
    scrollToBottom();
  };
  const fetchOrderChat = async () => {
    try {
      let res = await axiosClient.get(`${API_URL}/orders/chat/${params.id}`);
      if (res.data.success) {
        setMessages(res.data.data);
      } else {
        showToast(res.data.messages, "error");
      }
    } catch (error) {
      showToast(error.message, "error");
    }
  };
  const scrollToBottom = () => {
    chatboxRef &&
      chatboxRef.current &&
      chatboxRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    getOrderDetailsById();
    fetchOrderChat();
    scrollToBottom();
    setRefreshed(true);
    if (!refreshed) {
      setInitLoading(true);
      getOrderDetailsById();
      fetchOrderChat();
      setRefreshed(true);
      scrollToBottom();
    }
  }, [refreshed, params.id]);
  const handleFileChange = async (e) => {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    let res = await axiosClient.post(`${API_URL}/upload/file`, formData);
    if (res.data.success) {
      setFile(res.data.file.filename);
      showToast("File uploaded successfully", "success");
    }
  };
  useEffect(() => {
    socket && socket.emit("joinRoom", { roomId: details && details.orderId });
    socket &&
      socket.on("orderStatusUpdated", (data) => {
        setRefreshed(false);
      });
    socket &&
      socket.on("receiveMessage", (data) => {
        console.log(data, "data");
        setMessages((prevMessages) => [...prevMessages, data]);
      });

    return () => {
      socket && socket.off("receiveMessage");
      socket && socket.off("orderStatusUpdated");
    };
  }, [socket, details]);
  if (!details) {
    return (
      <Loader />
    );
  }
  return (
    <>
    <div className="flex my-2 lg:hidden">
            <TbArrowBigLeftLinesFilled size={35} onClick={() => goBack()} />
          </div>
      {details.orderStatus !== "waiting" && (
        <div className=" flex flex-col w-full">
          <div className=" bg-zinc-300 rounded-t-lg p-3 m-0">
            <div className="flex ">
              <div>
                <img
                  className="profile-letter"
                  src={
                    details.buyer.wallet === account.address
                      ? details.seller.profileUrl
                      : details.buyer.profileUrl
                  }
                />
              </div>
              <div className="flex ms-2 w-100 flex-column">
                <div className="flex">
                  <h5 className="profile-name buy-text mt-2">
                    {details.buyer.wallet === account.address
                      ? details.seller.name
                      : details.buyer.name}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col h-80 my-2 max-md:h-4/5 max-sm:h-4/5 overflow-y-auto scrollbar-none mx-2">
            {messages.map((m, index) => (
              <React.Fragment key={index}>
                {m.sender_id === account.address ? (
                  <div className="flex  flex-col justify-start">
                    {m.file !== "" && m.file !== null && (
                      <div className="justify-start   mt-2 bg-slate-200 p-2 border rounded-md">
                        <div className="flex flex-col ">
                          <img
                            src={m.imageUrl}
                            alt="Message File"
                            className=" rounded-md cursor-pointer w-100"
                            height={150}
                            onClick={(e) => {
                              window.open(m.imageUrl, "_blank");
                            }}
                          />
                        </div>
                        <div className="mt-0  flex">
                          <p className="mx-2 my-2">{m.message}</p>
                        </div>
                        <div className="mt-1 flex justify-start ">
                          <span style={{ fontSize: "10px" }}>
                            {formatDate(m.createdAt)}
                          </span>
                        </div>
                      </div>
                    )}
                    {(m.file === "" || m.file === null) && m.message && (
                      <>
                        <div className=" mt-4 flex bg-zinc-200 p-2 border rounded-md">
                          <p className="mx-2 my-2">{m.message}</p>
                        </div>
                      </>
                    )}
                    <div className=" mt-1 flex flex-row  justify-end">
                      <span style={{ fontSize: "10px" }}>
                        {formatDate(m.createdAt)}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-start ">
                    {m.file !== "" &&
                      m.file !== null &&
                      m.message !== "" &&
                      m.message && (
                        <div className="bg-slate-200 ">
                          <div className="">
                            <img
                              src={m.imageUrl}
                              alt="Message File"
                              className=" cursor-pointer w-100"
                              height={100}
                              onClick={(e) => {
                                window.open(m.imageUrl, "_blank");
                              }}
                            />
                            <div className="flex mt-4">
                              <div className="mx-2 bg-slate-200 my-2">
                                {m && m.message}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {m.message && (m.file === "" || m.file === null) && (
                      <div className="bg-slate-200 rounded-md mt-4 ">
                        <div className="flex mt-0 ">
                          <div className="mx-2 my-2">{m && m.message}</div>
                        </div>
                      </div>
                    )}
                    <div className=" mt-1 flex flex-row justify-start">
                      <span style={{ fontSize: "10px" }}>
                        {formatDate(m.createdAt)}
                      </span>
                    </div>
                  </div>
                )}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={chatboxRef}
                ></div>
              </React.Fragment>
            ))}
          </div>
          {details && details.orderStatus === "approved" && (
            <div className="flex w-full ">
              <div className="w-full my-2 mx-4 self-end flex gap-2 ">
                <input
                  class="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm  shadow-yellow-500 focus:outline-none focus:border-yellow-500 focus:ring-yellow-500 focus:ring-1 sm:text-sm"
                  placeholder="Type your message..."
                  type="text"
                  ref={messageRef}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <input
                  type="file"
                  className=""
                  id="msg-file"
                  style={{ display: "none" }}
                  accept="image/*,"
                  onChange={(e) => handleFileChange(e)}
                />
                <div className="w-3/12 flex flex-row gap-1">
                  <button
                    className="bg-yellow-500  rounded-lg px-3 py-2 text-white"
                    onClick={(e) => {
                      document.getElementById("msg-file").click();
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperclip} />
                  </button>
                  <button
                    onClick={(e) => sendMessages(e)}
                    // disabled={loading}
                    className="bg-yellow-500  rounded-lg px-3 py-2 text-white"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ order }) => ({
  orderDetails: order.orderDetails,
  fetchingOrderDetails: order.fetchingOrderDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getOrderDetailsById }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrderChat);
