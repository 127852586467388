import { Card } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export const plans = [
  {
    name: "Early bird",
    price: "25$",
    features: ["100 $ (USDT) Daily", "Can't ad post"],
  },
  {
    name: "Trader",
    price: "1000$",
    features: [
      "KYC mandatory",
      "1000 $  daily ",
      "Ad post upto 1000 $",
      "25000 graphite stacking",
    ],
  },
  {
    name: "Merchant",
    price: "N/A",
    features: [
      "KYC mandatory",
      "3000 $ daily",
      "Ad post upto 3000$",
      "50000 graphite stacking ",
    ],
  },
  {
    name: "Diamond",
    price: "N/A",
    features: [
      "KYC mandatory",
      "Unlimited buy sell",
      "ad post",
      "*100000 graphite stacking premium support",
    ],
  },
];

function Upgrade() {
  return (
    <>
      <div className="flex border shadow-md  shadow-yellow-500 m-1 p-5">
        <p className=" text-2xl font-bold text-black"> Choose Your Plan</p>
      </div>
      <div className="lg:grid lg:grid-cols-3 max-sm:flex max-sm:flex-col max-md:flex max-md:flex-col max-lg:grid max-lg:grid-cols-2   gap-4 lg:border lg:shadow-md  shadow-yellow-500 lg:m-1 lg:p-5 space-y-3">
        {plans.map((item) => (
          <Card className="p-5">
            <div className="grid grid-cols-1 gap-2 ">
              <div className="flex items-start justify-center">
                <p className=" font-bold text-xl">{item.name.toUpperCase()}</p>
              </div>
              <div className="flex flex-col justify-start">
                {item.features.map((d, i) => {
                  return (
                    <p key={i} className="py-1">
                      <span>
                        <CheckCircleIcon sx={{ color: "#eab308" }} />
                        {d}
                      </span>
                    </p>
                  );
                })}
              </div>
              <div className="flex items-end justify-center mb-0">
                <button className=" bg-yellow-500  p-2 rounded-lg text-black font-bold">
                  UPGRADE PLAN
                </button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </>
  );
}

export default Upgrade;
