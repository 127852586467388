import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userDetails } from "../../Container/Auth/AuthAction";
import { API_URL } from "../../Config/Auth";
import axios from "axios";
import { useEffect } from "react";
import SignUp from "../../Container/Auth/SignUp";
import SignIn from "../../Container/Auth/SignIn";

const PrivateRoutes = (props) => {
  const { isConnected, address, isDisconnected } = useAccount();

  useEffect(() => {
    if (isConnected) {
      props.userDetails(address);
    }
  }, []);
  if (isDisconnected) {
    sessionStorage.clear();
    localStorage.clear();
  }
  const userDetail = sessionStorage.getItem("userDetails");
  const fetchingUserDetails = props.fetchingUserDetails;
  // if (fetchingUserDetails) {
  //   return <h1>Loading...</h1>;
  // }
  return isConnected && userDetail ? (
    <Outlet />
  ) : isConnected && !userDetail ? (
    <SignUp />
  ) : isConnected && !userDetail && fetchingUserDetails ? (
    <SignUp />
  ) : (
    <SignIn />
  );
};

const mapStateToProps = ({ auth }) => ({
  fetchingUserDetails: auth.fetchingUserDetails,
  userDetails: auth.userDetails,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ userDetails }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
