import * as types from "./SettingActionTypes";
import { message, notification } from "antd";
import { createBrowserHistory } from "history";
import { API_URL } from "../../Config/Auth";
import axiosClient from "../../service/axios";

const history = createBrowserHistory();
const token = JSON.parse(sessionStorage.getItem("dp2p-token"));
export const getAllTicket = (address) => (dispatch) => {
  dispatch({
    type: types.GET_ALL_TICKET_REQUEST,
  });
  axiosClient
    .get(`${API_URL}/help/${address}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_TICKET_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_TICKET_FAILURE,
        payload: err,
      });
    });
};

export const AddNewTicket = (data) => (dispatch) => {
  dispatch({
    type: types.ADD_TICKET_REQUEST,
  });
  axiosClient
    .post(
      `${API_URL}/help`,
      { ...data })
    .then((res) => {
      console.log(res);
      // dispatch(getAllTicket(address));
      dispatch({
        type: types.ADD_TICKET_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_TICKET_FAILURE,
        payload: err,
      });
    });
};

export const getAllPaymentMethod = () => (dispatch) => {
  dispatch({
    type: types.GET_ALL_PAYMENT_METHOD_REQUEST,
  });
  axiosClient
    .get(`${API_URL}/paymentmethods`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_ALL_PAYMENT_METHOD_SUCCESS,
        payload: res.data.payment,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_ALL_PAYMENT_METHOD_FAILURE,
        payload: err,
      });
    });
};

export const getPaymentMethodById = (address) => (dispatch) => {
  dispatch({
    type: types.GET_PAYMENT_METHOD_BY_ID_REQUEST,
  });
  axiosClient
    .get(`${API_URL}/userpayment-method/${address}`,)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_PAYMENT_METHOD_BY_ID_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_PAYMENT_METHOD_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const deletePaymentMethodById = (id, address) => (dispatch) => {
  dispatch({
    type: types.DELETE_PAYMENT_METHOD_BY_ID_REQUEST,
  });
  axiosClient
    .delete(`${API_URL}/userpayment-method/${id}`)
    .then((res) => {
      dispatch(getPaymentMethodById(address));
      console.log(res);
      dispatch({
        type: types.DELETE_PAYMENT_METHOD_BY_ID_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.DELETE_PAYMENT_METHOD_BY_ID_FAILURE,
        payload: err,
      });
    });
};

export const AddNewPaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: types.ADD_PAYMENT_METHOD_REQUEST,
  });
  axiosClient
    .post(
      `${API_URL}/userpayment-method`,
      { ...data })
    .then((res) => {
      console.log(res);
      // history.push("/payment");
      // dispatch(getAllTicket(address));
      dispatch({
        type: types.ADD_PAYMENT_METHOD_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.ADD_PAYMENT_METHOD_FAILURE,
        payload: err,
      });
    });
};


export const getAllFeedback = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_FEEDBACK_REQUEST,
  });
  axiosClient
    .get(`${API_URL}/review/${userId}`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_FEEDBACK_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_FEEDBACK_FAILURE,
        payload: err,
      });
    });
};