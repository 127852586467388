import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Slider,
  Switch,
  Alert,
  Spin,
  TreeSelect,
  Upload,
  Modal,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaYoutube, FaTwitter } from "react-icons/fa";
const { Option } = Select;

const ContactUs = (props) => {
  const initialValues = {
    email: "",
    name: "",
    mobile: "",
    country: "",
    currency: "",
  };
  const prefixSelector = (
    <Form.Item noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue={"+91"}
      >
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <>
      <div className="flex py-10 px-2 bg-black space-y-4 lg:space-y-0 flex-col lg:flex-row ">
        <div className="lg:w-1/2 flex flex-col space-y-2 self-center justify-center">
          <p className="flex self-center justify-center text-xl lg:text-4xl text-white italic font-mono ">
            Feel free to contact us!
          </p>
          <ul className=" text-[#b1b5c3] flex self-center justify-center flex-col gap-2">
            {/* <li>
                <p>+12 345 678 9101</p>
              </li> */}
            <li>
              <a href="mailto:info@dp2p.cc">
                <p className="flex flex-row gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.55em"
                    style={{ fill: "#337eff" }}
                    viewBox="0 0 512 512"
                  >
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                  </svg>
                  info@dp2p.cc
                </p>
              </a>
            </li>
            <li>
              <a>
                <p className="flex flex-row gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.55em"
                    style={{ fill: "#0587eb" }}
                    viewBox="0 0 496 512"
                  >
                    <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z" />
                  </svg>
                  @timetobeapro
                </p>
              </a>
            </li>
          </ul>
          <div className=" flex self-center justify-center gap-6 mt-6">
            <Link
              href={"/"}
              target="_blank"
              className=" text-white bg-blue-700 p-2 rounded-md text-lg hover:text-primary duration-300 ease-in-out"
            >
              <FaFacebookF size={22} />
            </Link>
            <Link
              href={"/"}
              target="_blank"
              className=" text-white bg-pink-700 p-2 rounded-md text-lg hover:text-primary duration-300 ease-in-out"
            >
              <FaInstagram size={22} />
            </Link>
            <Link
              href={"/"}
              target="_blank"
              className=" text-white bg-red-500 p-2 rounded-md text-lg hover:text-primary duration-300 ease-in-out"
            >
              <FaYoutube size={22} />
            </Link>
            <Link
              href={"/"}
              target="_blank"
              className=" text-white bg-sky-700 p-2 rounded-md text-lg hover:text-primary duration-300 ease-in-out"
            >
              <FaTwitter size={22} />
            </Link>
          </div>
        </div>
        <div className="lg:w-1/2 lg:px-5 lg:my-10">
          <Form
            className="space-y-5"
            layout="vertical"
            // onFinish={handleSubmit}
            // initialValues={initialValues}
            // validateMessages={validateMessages}
          >
            <Form.Item
              //label="Name"
              style={{ color: "red" }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              // label="Email"
              className=" text-white"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="mobile"
              className=" text-white"
              // label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
                placeholder="Phone Number"
              />
            </Form.Item>
            <Form.Item
              className=" text-white"
              //  label="Subject"
              name="Subject"
              rules={[
                {
                  required: true,
                  message: "Please input your Subject!",
                },
              ]}
            >
              <Input placeholder="Subject" />
            </Form.Item>
            <Form.Item
              className=" text-white"
              //  label="Subject"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your Description!",
                },
              ]}
            >
              <Input.TextArea placeholder="Type here" />
            </Form.Item>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
            >
              <Checkbox className=" text-white">
                I Agree to the Terms and Conditions and Privacy Policy
                <a href="">agreement</a>
              </Checkbox>
            </Form.Item>
            <div className="w-full gap-2 flex flex-row">
              <button
                // type="primary"
                htmlType="submit"
                className="bg-yellow-500 font-bold w-full text-white rounded-full text-lg border  self-center"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
