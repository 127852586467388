import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Hero from "../Landing/Hero";
import Crypto from "../Landing/Crypto";
import Features from "../Landing/Features";
import About from "../Landing/About";
import Work from "../Landing/Work";
import logo from "../../logo.png";
import Footers from "../Landing/Footers";
import WalletConnect from "../../Components/Comman/WalletConnect";
import { Link } from "react-router-dom";
//import Blogs from "../Landing/Blogs";

function SignIn(props) {
  const userDetail = sessionStorage.getItem("userDetails");

  return (
    <>
      {/* <header className=" relative">
        <div className="flex px-2 h-20 fixed top-0 left-0 right-0 z-50 bg-slate-800 flex-row justify-between ">
          <div className="-mt-10">
            <Link to={"/"}>
              <img src={logo} alt="Logo" height={150} width={150} />
            </Link>
          </div>
          <div className="mt-4 py-1">
            <WalletConnect label="Get Started" />
          </div>
        </div>
      </header> */}
      <section className="h-full px-4 bg-black overflow-y-auto">
        <Hero />
        <Crypto />
        {/* <Coins /> */}
        <Features />
        <About />
        <Work />
        {/* <Footer />         */}
      </section>
      {/* <footer>
        <Footers />
      </footer> */}
    </>
  );
}

const mapStateToProps = ({ auth }) => ({
  userDetails: auth.userDetails,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
