import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-hot-toast';


// Format date to HH:mm, DD MMM YYYY
export const formatDate = (date) => {
    return moment(date).format('HH:mm, DD MMM YYYY');
}




export const showToast = (message, type) => {
    switch (type) {
        case 'success':
            toast.dismiss();
            return toast.success(message, {
                position: 'top-right'
            });
        case 'error':
            toast.dismiss();
            return toast.error(message, {
                position: 'top-right'
            });
        case 'loading':
            toast.dismiss();
            return toast.loading(message, {
                position: 'top-right'
            });
        case 'info':
            toast.dismiss();
           return toast.error(message, {
                position: 'top-right'
            });    
        default:
            toast.dismiss();
            return toast(message, {
                position: 'top-right'
            })
    }
}
