import styled from 'styled-components';

const MainWrapper = styled.div`
    border-radius: 0.1rem;
    box-shadow: 0px 4px 10px -2px ${props => props.theme.boxShadowColor};
    border: 1px solid ${props => props.theme.borderColor}
    background-color: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.color};
    margin: 0.2rem;
    padding: 0.3rem;
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    overflow: auto;
`
export default MainWrapper;