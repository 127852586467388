import * as types from "./AuthActionTypes";
const initialState = {
  register: false,
  registerError: false,
  user: {},

  fetchingUserDetails: false,
  fetchingUserDetailsError: false,
  userDetails: {},

  fetchingCurrencyDetails: false,
  fetchingCurrencyDetailsError: false,
  currencyList: [],

  fetchingUserDetailsById: false,
  fetchingUserDetailsByIdError: false,
  userDetailsId: {},
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_REQUEST:
      return { ...state, register: true };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        register: false,
      };
    case types.REGISTER_FAILURE:
      return { ...state, register: false, registerError: true };

    case types.USER_DETAILS_REQUEST:
      return { ...state, fetchingUserDetails: true };
    case types.USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingUserDetails: false,
        userDetails: action.payload,
      };

    case types.USER_DETAILS_FAILURE:
      return {
        ...state,
        fetchingUserDetails: false,
        fetchingUserDetailsError: true,
      };

    case types.GET_CURRENCY_LIST_REQUEST:
      return { ...state, fetchingCurrencyDetails: true };
    case types.GET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        fetchingCurrencyDetails: false,
        currencyList: action.payload,
      };

    case types.GET_CURRENCY_LIST_FAILURE:
      return {
        ...state,
        fetchingCurrencyDetails: false,
        fetchingCurrencyDetailsError: true,
      };


      case types.USER_DETAILS_BY_ID_REQUEST:
        return { ...state, fetchingUserDetailsById: true };
      case types.USER_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          fetchingUserDetailsById: false,
          userDetailsId: action.payload,
        };
  
      case types.USER_DETAILS_BY_ID_FAILURE:
        return {
          ...state,
          fetchingUserDetailsById: false,
          fetchingUserDetailsByIdError: true,
        };

    default:
      return state;
  }
  return state;
};
