import React, { useEffect, useRef } from "react";
import Banner from "../../assets/banner-01.png";
import video from "../../assets/landing.mp4";
import WalletConnect from "../../Components/Comman/WalletConnect";

const Hero = () => {
  const vidRef = useRef();
 
  useEffect(() => {
    const videos = vidRef.current;
    var isPlaying = videos.currentTime > 0 && !videos.paused && !videos.ended 
    && videos.readyState > videos.HAVE_CURRENT_DATA;
    if (!isPlaying) {
      videos.play();
    }  
  }, []);
  
  return (
    <div className="bg-charcoal py-24 pb-48 p-2">
      <div className="grid xl:grid-cols-2 gap-10">
        <div className="grid gap-4 h-fit place-content-center">
          <h1 className=" text-[40px] md:text-[64px] font-bold text-white leading-[1.11]">
            Buy and Sell Cryptocurrency
          </h1>
          <p className=" text-xl font-normal text-slate-300 leading-[1.4]">
            Fast, Easy, and Private.
            <p className=" text-xl font-normal text-slate-300 leading-[1.4] mt-5">
              Buy ETH, USDT, BNB, MATIC, GRA and more cryptos worldwide using
              most popular payment methods.
            </p>
          </p>
          {/* <a href="https://app.dp2p.cc" className=" text-white mt-8 bg-yellow-500  rounded-full px-6 py-3 font-bold w-fit">
            Trade Now
          </a> */}
          <WalletConnect label="Trade Now" />
        </div>
        <div>
          <video ref={vidRef} muted autoPlay loop >
            <source src={video} type="video/mp4" />
          </video>
          {/* <img
            src={Banner}
            width={658}
            height={448}
            alt="banner"
            className="mx-auto"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
