import * as types from "./SettingActionTypes";
const initialState = {
  fetchingAllTicket: false,
  fetchingAllTicketError: false,
  ticket: {},

  addingNewTicket: false,
  addingNewTicketError: false,
  newTicket: {},

  fetchingPaymentMethod: false,
  fetchingPaymentMethodError: false,
  paymentMethod: [],

  fetchingPaymentMethodById: false,
  fetchingPaymentMethodByIdError: false,
  paymentMethodById: [],

  deletePaymentMethod: false,
  deletePaymentMethodError: false,

  addingNewPaymentMethod: false,
  addingNewPaymentMethodError: false,
  addNewPaymentMethod: {},


  fetchingFeedbackId: false,
  fetchingFeedbackIdError: false,
  feedback: [],
};
export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_TICKET_REQUEST:
      return { ...state, fetchingAllTicket: true };
    case types.GET_ALL_TICKET_SUCCESS:
      return {
        ...state,
        fetchingAllTicket: false,
        ticket: action.payload,
      };

    case types.GET_ALL_TICKET_FAILURE:
      return {
        ...state,
        fetchingAllTicket: false,
        fetchingAllTicketError: true,
      };

    case types.ADD_TICKET_REQUEST:
      return { ...state, addingNewTicket: true };
    case types.ADD_TICKET_SUCCESS:
      return {
        ...state,
        addingNewTicket: false,
        newTicket: action.payload,
      };

    case types.ADD_TICKET_FAILURE:
      return {
        ...state,
        addingNewTicket: false,
        addingNewTicketError: true,
      };

    case types.GET_ALL_PAYMENT_METHOD_REQUEST:
      return { ...state, fetchingPaymentMethod: true };
    case types.GET_ALL_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        fetchingPaymentMethod: false,
        paymentMethod: action.payload,
      };

    case types.GET_ALL_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        fetchingPaymentMethod: false,
        fetchingPaymentMethodError: true,
      };

    case types.GET_PAYMENT_METHOD_BY_ID_REQUEST:
      return { ...state, fetchingPaymentMethodById: true };
    case types.GET_PAYMENT_METHOD_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingPaymentMethodById: false,
        paymentMethodById: action.payload,
      };

    case types.GET_PAYMENT_METHOD_BY_ID_FAILURE:
      return {
        ...state,
        fetchingPaymentMethodById: false,
        fetchingPaymentMethodByIdError: true,
      };

    case types.DELETE_PAYMENT_METHOD_BY_ID_REQUEST:
      return { ...state, deletePaymentMethod: true };
    case types.DELETE_PAYMENT_METHOD_BY_ID_SUCCESS:
      return {
        ...state,
        deletePaymentMethod: false,
      //  paymentMethodById: action.payload,
      };

    case types.DELETE_PAYMENT_METHOD_BY_ID_FAILURE:
      return {
        ...state,
        deletePaymentMethod: false,
        deletePaymentMethodError: true,
      };


      case types.ADD_PAYMENT_METHOD_REQUEST:
        return { ...state, addingNewPaymentMethod: true };
      case types.ADD_PAYMENT_METHOD_SUCCESS:
        return {
          ...state,
          addingNewPaymentMethod: false,
          addNewPaymentMethod: action.payload,
        };
  
      case types.ADD_PAYMENT_METHOD_FAILURE:
        return {
          ...state,
          addingNewPaymentMethod: false,
          addingNewPaymentMethodError: true,
        };


        case types.GET_FEEDBACK_REQUEST:
      return { ...state, fetchingFeedbackId: true };
    case types.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        fetchingFeedbackId: false,
        feedback: action.payload,
      };

    case types.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        fetchingFeedbackId: false,
        fetchingFeedbackIdError: true,
      };


    default:
      return state;
  }
  return state;
};
