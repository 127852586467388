import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { API_URL, SOCKET_URL } from "../api/const";



const SocketContext = React.createContext();

export function useSocket() {
  return useContext(SocketContext);
}

function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    const newSocket = io(SOCKET_URL);
    setSocket(newSocket);
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
}
export default SocketProvider;