import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllTicket } from "../SettingAction";
import { useAccount } from "wagmi";
import { Card } from "@mui/material";
import { Alert, Spin } from 'antd';
import Loader from "../../../Components/Loader";

const HelpCard = (props) => {
  const { isConnected, address, isDisconnected } = useAccount();
  useEffect(() => {
    props.getAllTicket(address);
  }, []);
  const ticket = props.ticket && props.ticket.data;
 
 
  if (props.fetchingAllTicket) {
    return (
      <Loader />
    );
  }
  return (
    <div className="p-2">
      {ticket &&
        ticket.map((item) => (
          <Card className="p-1 shadow-md  shadow-yellow-500 m-1">
            <div className="flex justify-between">
                <p>{item.createdAt}</p>
              <button className="p-2 rounded-lg bg-yellow-500  text-white font-bold">
                {item.status}
              </button>
            </div>
            <div className="flex flex-row">
              <p className=" text-sm font-bold">Category :</p>
              <p className="ml-1 ">{item.category}</p>
            </div>
            <div className="flex flex-row">
              <p className=" text-sm font-bold">Subject :</p>
              <p className="ml-1 ">{item.subject}</p>
            </div>
            <div className="flex flex-row">
              <p className=" w-1/4 text-sm font-bold">Message :</p>
              <p className="-ml-4 w-3/4">{item.message}</p>
            </div>
            
          </Card>
        ))}
    </div>
  );
};

const mapStateToProps = ({ setting }) => ({
  ticket: setting.ticket,
  fetchingAllTicket: setting.fetchingAllTicket,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllTicket }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HelpCard);
