const Privacy = () => {
  return (
    <main className=" bg-[#020d18] overflow-hidden  h-full p-5">
      <div className="container mx-auto bg-white dark:bg-ocean-800 rounded-lg p-4 md:p-8">
        <div className="w-full">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Owner and Data Controller
          </h2>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            DP2P Limited
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            {/* <b>Owner contact email:</b> */}
            info@dp2p.cc
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Types of Data collected
          </h2>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Among the types of Personal Data that https://app.dp2p.cc/
            collects, by itself or through third parties, there are: email
            address, Cookies, Usage Data, geographic position, username,
            picture, city, various types of Data and phone number.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Complete details on each type of Personal Data collected are
            provided in the dedicated sections of this privacy policy or by
            specific explanation texts displayed prior to the Data collection.
            Personal Data may be freely provided by the User, or, in case of
            Usage Data, collected automatically when using
            https://app.dp2p.cc/. Unless specified otherwise, all Data
            requested by https://app.dp2p.cc/ is mandatory and failure to
            provide this Data may make it impossible for https://app.dp2p.cc/
            to provide its services. In cases where https://app.dp2p.cc/
            specifically states that some Data is not mandatory, Users are free
            not to communicate this Data without consequences to the
            availability or the functioning of the Service. Users who are
            uncertain about which Personal Data is mandatory are welcome to
            contact the Owner. Any use of Cookies – or of other tracking tools –
            by https://app.dp2p.cc/ or by the owners of third-party services
            used by https://app.dp2p.cc/ serves the purpose of providing the
            Service required by the User, in addition to any other purposes
            described in the present document and in the Cookie Policy, if
            available.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Users are responsible for any third-party Personal Data obtained,
            published or shared through https://app.dp2p.cc/ and confirm that
            they have the third party &#x27;s consent to provide the Data to the
            Owner.
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Mode and place of processing the Data
          </h2>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Methods of processing
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data. The Data processing is carried out using
            computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to
            certain types of persons in charge, involved with the operation of
            https://app.dp2p.cc/ (administration, sales, marketing, legal,
            system administration) or external parties (such as third-party
            technical service providers, mail carriers, hosting providers, IT
            companies, communications agencies) appointed, if necessary, as Data
            Processors by the Owner. The updated list of these parties may be
            requested from the Owner at any time.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Legal basis of processing
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Owner may process Personal Data relating to Users if one of the
            following applies:
          </p>
          <ul className="list-disc list-inside p-2 md:p-4 text-bone-800 dark:text-bone-300">
            <li>
              Users have given their consent for one or more specific purposes.
              Note: Under some legislations the Owner may be allowed to process
              Personal Data until the User objects to such processing
              (“opt-out”), without having to rely on consent or any other of the
              following legal bases. This, however, does not apply, whenever the
              processing of Personal Data is subject to European data protection
              law;
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>
          </ul>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Place
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Data is processed at the Owner &#x27;s operating offices and in
            any other places where the parties involved in the processing are
            located.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Depending on the User &#x27;s location, data transfers may involve
            transferring the User &#x27;s Data to a country other than their
            own. To find out more about the place of processing of such
            transferred Data, Users can check the section containing details
            about the processing of Personal Data.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Users are also entitled to learn about the legal basis of Data
            transfers to a country outside the European Union or to any
            international organization governed by public international law or
            set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            If any such transfer takes place, Users can find out more by
            checking the relevant sections of this document or inquire with the
            Owner using the information provided in the contact section.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Retention time
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Personal Data shall be processed and stored for as long as required
            by the purpose they have been collected for.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Therefore:
          </p>
          <ul className="list-disc list-inside p-2 md:p-4 text-bone-800 dark:text-bone-300">
            <li>
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>
          </ul>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to do
            so for the performance of a legal obligation or upon order of an
            authority.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Once the retention period expires, Personal Data shall be deleted.
            Therefore, the right to access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced
            after expiration of the retention period.
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            The purposes of processing
          </h2>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Data concerning the User is collected to allow the Owner to
            provide its Services, as well as for the following purposes:
            Managing support and contact requests, Contacting the User,
            Analytics, Remarketing and behavioral targeting, Location-based
            interactions, Traffic optimization and distribution, SPAM
            protection, Social features, Managing contacts and sending messages
            and Infrastructure monitoring.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Users can find further detailed information about such purposes of
            processing and about the specific Personal Data used for each
            purpose in the respective sections of this document.
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Detailed information on the processing of Personal Data
          </h2>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Personal Data is collected for the following purposes and using the
            following services:
          </p>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Analytics
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              The services contained in this section enable the Owner to monitor
              and analyze web traffic and can be used to keep track of User
              behavior.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Google Analytics (Google Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Google Analytics is a web analysis service provided by Google Inc.
              (“Google”). Google utilizes the Data collected to track and
              examine the use of https://app.dp2p.cc/, to prepare reports on
              its activities and share them with other Google services. Google
              may use the Data collected to contextualize and personalize the
              ads of its own advertising network.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              –
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Opt Out
              </a>
              . Privacy Shield participant.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Facebook Ads conversion tracking (Facebook, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Facebook Ads conversion tracking is an analytics service provided
              by Facebook, Inc. that connects data from the Facebook advertising
              network with actions performed on https://app.dp2p.cc/.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Privacy Shield participant.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Twitter Ads conversion tracking (Twitter, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Twitter Ads conversion tracking is an analytics service provided
              by Twitter, Inc. that connects data from the Twitter advertising
              network with actions performed on https://app.dp2p.cc/.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Privacy Shield participant.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Contacting the User
          </h3>
          <div>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Contact form (https://app.dp2p.cc/)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              By filling in the contact form with their Data, the User
              authorizes https://app.dp2p.cc/ to use these details to reply to
              requests for information, quotes or any other kind of request as
              indicated by the form’s header.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: email address.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Mailing list or newsletter (https://app.dp2p.cc/)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              By registering on the mailing list or for the newsletter, the
              User’s email address will be added to the contact list of those
              who may receive email messages containing information of
              commercial or promotional nature concerning https://app.dp2p.cc/.
              Your email address might also be added to this list as a result of
              signing up to https://app.dp2p.cc/ or after making a purchase.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: email address.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Infrastructure monitoring
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service allows https://app.dp2p.cc/ to monitor the
              use and behavior of its components so its performance, operation,
              maintenance and troubleshooting can be improved. Which Personal
              Data are processed depends on the characteristics and mode of
              implementation of these services, whose function is to filter the
              activities of https://app.dp2p.cc/.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Splunk MINT Express (Splunk Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Splunk MINT Express is a monitoring service provided by Splunk
              Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: various types of Data as specified in the
              privacy policy of the service.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.splunk.com/en_us/legal/privacy/privacy-policy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Location-based interactions
          </h3>
          <div>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Non-continuous geolocation (https://app.dp2p.cc/)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              https://app.dp2p.cc/ may collect, use, and share User location
              Data in order to provide location-based services.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Most browsers and devices provide tools to opt out from this
              feature by default. If explicit authorization has been provided,
              the User’s location data may be tracked by https://app.dp2p.cc/.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              The geographic location of the User is determined in a manner that
              isn &#x27;t continuous, either at the specific request of the User
              or when the User doesn &#x27;t point out its current location in
              the appropriate field and allows the application to detect the
              position automatically.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: geographic position.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Managing contacts and sending messages
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service makes it possible to manage a database of
              email contacts, phone contacts or any other contact information to
              communicate with the User. These services may also collect data
              concerning the date and time when the message was viewed by the
              User, as well as when the User interacted with it, such as by
              clicking on links included in the message.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Sendgrid (Sendgrid)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Sendgrid is an email address management and message sending
              service provided by Sendgrid Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: email address and username.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://sendgrid.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Mailgun (Mailgun Technologies, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Mailgun is an email address management and message sending service
              provided by Mailgun Technologies, Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: email address and various types of Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.mailgun.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Privacy Shield participant.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Twilio (Twilio, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Twilio is a phone numbers management and communication service
              provided by Twilio, Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: phone number.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.twilio.com/legal/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Location-based interactions
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service allows https://app.dp2p.cc/ to manage
              support and contact requests received via email or by other means,
              such as the contact form. The Personal Data processed depend on
              the information provided by the User in the messages and the means
              used for communication (e.g. email address).
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Zendesk (Zendesk, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Zendesk is a support and contact request management service
              provided by Zendesk Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: various types of Data as specified in the
              privacy policy of the service.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.zendesk.com/company/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Privacy Shield participant.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Remarketing and behavioral targeting
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service allows https://app.dp2p.cc/ and its partners
              to inform, optimize and serve advertising based on past use of
              https://app.dp2p.cc/ by the User.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This activity is performed by tracking Usage Data and by using
              Cookies, information that is transferred to the partners that
              manage the remarketing and behavioral targeting activity.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Some services offer a remarketing option based on email address
              lists.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              In addition to any opt-out offered by any of the services below,
              the User may opt out of a third-party service &#x27;s use of
              cookies by visiting the
              <a
                href="http://optout.networkadvertising.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Network Advertising Initiative opt-out page
              </a>
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Facebook Remarketing (Facebook, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Facebook Remarketing is a remarketing and behavioral targeting
              service provided by Facebook, Inc. that connects the activity of
              https://app.dp2p.cc/ with the Facebook advertising network.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              –
              <a
                href="https://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Opt Out
              </a>
              . Privacy Shield participant.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Twitter Remarketing (Twitter, Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Twitter Remarketing is a remarketing and behavioral targeting
              service provided by Twitter, Inc. that connects the activity of
              https://app.dp2p.cc/ with the Twitter advertising network.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://twitter.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              –
              <a
                href="https://support.twitter.com/articles/20170405"
                target="_blank"
                rel="noopener noreferrer"
              >
                Opt Out
              </a>
              . Privacy Shield participant.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Google Ads Remarketing (Google LLC)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Google Ads Remarketing is a remarketing and behavioral targeting
              service provided by Google LLC that connects the activity of
              https://app.dp2p.cc/ with the Google Ads advertising network and
              the DoubleClick Cookie.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="http://www.google.com/intl/en/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              –
              <a
                href="https://www.google.com/settings/ads/onweb/optout"
                target="_blank"
                rel="noopener noreferrer"
              >
                Opt Out
              </a>
              . Privacy Shield participant.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Social features
          </h3>
          <div>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Public profile (https://app.dp2p.cc/)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Users may have public profiles that other Users can display. In
              addition to the Personal Data provided, this profile may contain
              Users &#x27;interactions with https://app.dp2p.cc/.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: city, email address, geographic position,
              picture and username.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            SPAM protection
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service analyzes the traffic of
              https://app.dp2p.cc/, potentially containing Users &#x27;Personal
              Data, with the purpose of filtering it from parts of traffic,
              messages and content that are recognized as SPAM.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Google reCAPTCHA (Google Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Google reCAPTCHA is a SPAM protection service provided by Google
              Inc. The use of reCAPTCHA is subject to the Google
              <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
              and
              <a
                href="https://www.google.com/intl/en/policies/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of use
              </a>
              .
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and Usage Data.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.google.com/intl/policies/privacy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . Privacy Shield participant.
            </p>
          </div>
          <h3 className="text-base md:text-lg font-600 text-bone-800 dark:text-bone-300 leading-tight pt-8">
            Traffic optimization and distribution
          </h3>
          <div>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              This type of service allows https://app.dp2p.cc/ to distribute
              their content using servers located across different countries and
              to optimize their performance. Which Personal Data are processed
              depends on the characteristics and the way these services are
              implemented. Their function is to filter communications between
              https://app.dp2p.cc/ and the User &#x27;s browser.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Considering the widespread distribution of this system, it is
              difficult to determine the locations to which the contents that
              may contain Personal Information User are transferred.
            </p>
            <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
              Cloudflare (Cloudflare Inc.)
            </h4>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Cloudflare is a traffic optimization and distribution service
              provided by Cloudflare Inc.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              The way Cloudflare is integrated means that it filters all the
              traffic through https://app.dp2p.cc/, i.e., communication between
              https://app.dp2p.cc/ and the User &#x27;s browser, while also
              allowing analytical data from https://app.dp2p.cc/ to be
              collected.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Personal Data collected: Cookies and various types of Data as
              specified in the privacy policy of the service.
            </p>
            <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
              Place of processing: United States –
              <a
                href="https://www.cloudflare.com/security-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            The rights of Users
          </h2>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Users may exercise certain rights regarding their Data processed by
            the Owner.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            In particular, Users have the right to do the following:
          </p>
          <ul className="list-disc list-inside p-2 md:p-4 text-bone-800 dark:text-bone-300">
            <li>
              <b>Withdraw their consent at any time.</b>
              Users have the right to withdraw consent where they have
              previously given their consent to the processing of their Personal
              Data.
            </li>
            <li>
              <b>Object to processing of their Data.</b>
              Users have the right to object to the processing of their Data if
              the processing is carried out on a legal basis other than consent.
              Further details are provided in the dedicated section below.
            </li>
            <li>
              <b>Access their Data.</b>
              Users have the right to learn if Data is being processed by the
              Owner, obtain disclosure regarding certain aspects of the
              processing and obtain a copy of the Data undergoing processing.
            </li>
            <li>
              <b>Verify and seek rectification.</b>
              Users have the right to verify the accuracy of their Data and ask
              for it to be updated or corrected.
            </li>
            <li>
              <b>Restrict the processing of their Data.</b>
              Users have the right, under certain circumstances, to restrict the
              processing of their Data. In this case, the Owner will not process
              their Data for any purpose other than storing it.
            </li>
            <li>
              <b>Have their Personal Data deleted or otherwise removed.</b>
              Users have the right, under certain circumstances, to obtain the
              erasure of their Data from the Owner.
            </li>
            <li>
              <b>
                Receive their Data and have it transferred to another
                controller.
              </b>
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance. This provision is applicable provided that the Data is
              processed by automated means and that the processing is based on
              the User &#x27;s consent, on a contract which the User is part of
              or on pre-contractual obligations thereof.
            </li>
            <li>
              <b>Lodge a complaint.</b>
              Users have the right to bring a claim before their competent data
              protection authority.
            </li>
          </ul>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Details about the right to object to processing
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time without providing any justification. To
            learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this
            document.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            How to exercise these rights
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. These
            requests can be exercised free of charge and will be addressed by
            the Owner as early as possible and always within one month.
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Additional information about Data collection and processing
          </h2>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Legal action
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The User &#x27;s Personal Data may be used for legal purposes by the
            Owner in Court or in the stages leading to possible legal action
            arising from improper use of https://app.dp2p.cc/ or the related
            Services. The User declares to be aware that the Owner may be
            required to reveal personal data upon request of public authorities.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Additional information about User &#x27;s Personal Data
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            In addition to the information contained in this privacy policy,
            https://app.dp2p.cc/ may provide the User with additional and
            contextual information concerning particular Services or the
            collection and processing of Personal Data upon request.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            System logs and maintenance
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            For operation and maintenance purposes, https://app.dp2p.cc/ and
            any third-party services may collect files that record interaction
            with https://app.dp2p.cc/ (System logs) use other Personal Data
            (such as the IP Address) for this purpose.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Information not contained in this policy
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time. Please see the
            contact information at the beginning of this document.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            How “Do Not Track” requests are handled
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            https://app.dp2p.cc/ does not support “Do Not Track” requests. To
            determine whether any of the third-party services it uses honor the
            “Do Not Track” requests, please read their privacy policies.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Changes to this privacy policy
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The Owner reserves the right to make changes to this privacy policy
            at any time by giving notice to its Users on this page and possibly
            within https://app.dp2p.cc/ and/or - as far as technically and
            legally feasible - sending a notice to Users via any contact
            information available to the Owner. It is strongly recommended to
            check this page often, referring to the date of the last
            modification listed at the bottom.
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Should the changes affect processing activities performed on the
            basis of the User’s consent, the Owner shall collect new consent
            from the User, where required.
          </p>
        </div>
        <hr className="bg-bone-400 mt-6" />
        <div className="w-full pt-6">
          <h2 className="text-lg md:text-2xl font-600 text-bone-800 dark:text-bone-300 leading-normal">
            Definitions and legal references
          </h2>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Personal Data (or Data)
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Any information that directly, indirectly, or in connection with
            other information — including a personal identification number —
            allows for the identification or identifiability of a natural
            person.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Usage Data
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Information collected automatically through https://app.dp2p.cc/
            (or third-party services employed in https://app.dp2p.cc/), which
            can include: the IP addresses or domain names of the computers
            utilized by the Users who use https://app.dp2p.cc/, the URI
            addresses (Uniform Resource Identifier), the time of the request,
            the method utilized to submit the request to the server, the size of
            the file received in response, the numerical code indicating the
            status of the server &#x27;s answer (successful outcome, error,
            etc.), the country of origin, the features of the browser and the
            operating system utilized by the User, the various time details per
            visit (e.g., the time spent on each page within the Application) and
            the details about the path followed within the Application with
            special reference to the sequence of pages visited, and other
            parameters about the device operating system and/or the User &#x27;s
            IT environment.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            User
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The individual using https://app.dp2p.cc/ who, unless otherwise
            specified, coincides with the Data Subject.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Data Subject
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The natural person to whom the Personal Data refers.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Data Processor (or Data Supervisor)
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The natural or legal person, public authority, agency or other body
            which processes Personal Data on behalf of the Controller, as
            described in this privacy policy.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Data Controller (or Owner)
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The natural or legal person, public authority, agency or other body
            which, alone or jointly with others, determines the purposes and
            means of the processing of Personal Data, including the security
            measures concerning the operation and use of https://app.dp2p.cc/.
            The Data Controller, unless otherwise specified, is the Owner of
            https://app.dp2p.cc/.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            https://app.dp2p.cc/ (or this Application)
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The means by which the Personal Data of the User is collected and
            processed.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Service
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            The service provided by https://app.dp2p.cc/ as described in the
            relative terms (if available) and on this site/application.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            European Union (or EU)
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Unless otherwise specified, all references made within this document
            to the European Union include all current member states to the
            European Union and the European Economic Area.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Cookies
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            Small sets of data stored in the User &#x27;s device.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Legal information
          </h4>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            This privacy statement has been prepared based on provisions of
            multiple legislations, including Art. 13/14 of Regulation (EU)
            2016/679 (General Data Protection Regulation).
          </p>
          <p className="text-md text-bone-800 dark:text-bone-300 mt-4">
            This privacy policy relates solely to https://app.dp2p.cc/, if not
            stated otherwise within this document.
          </p>
          <h4 className="text-base md:text-base font-600 text-bone-800 dark:text-bone-300 leading-tight pt-4">
            Latest update: August 19, 2018
          </h4>
        </div>
      </div>
    </main>
  );
};

export default Privacy;
