// user details request types
export const MARKET_LIST_SELL_REQUEST = "MARKET_LIST_SELL_REQUEST";
export const MARKET_LIST_SELL_SUCCESS = "MARKET_LIST_SELL_SUCCESS";
export const MARKET_LIST_SELL_FAILURE = "MARKET_LIST_SELL_FAILURE";

export const MARKET_LIST_BUY_REQUEST = "MARKET_LIST_BUY_REQUEST";
export const MARKET_LIST_BUY_SUCCESS = "MARKET_LIST_BUY_SUCCESS";
export const MARKET_LIST_BUY_FAILURE = "MARKET_LIST_BUY_FAILURE";

export const GET_ADS_DETAILS_BY_ID_REQUEST = "GET_ADS_DETAILS_BY_ID_REQUEST";
export const GET_ADS_DETAILS_BY_ID_SUCCESS = "GET_ADS_DETAILS_BY_ID_SUCCESS";
export const GET_ADS_DETAILS_BY_ID_FAILURE = "GET_ADS_DETAILS_BY_ID_FAILURE";